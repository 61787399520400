import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import './Column.scss'
// @ts-ignore
const is_Zh: boolean = localStorage.getItem('store_view') !== 'En'

//栏目选择
const Column: React.FC = () => {
	const { t } = useTranslation()
	const obj = [
		{
			id: 1,
			columnName: t('战略咨询'),
			active: '/strategic'
		},
		{
			id: 2,
			columnName: t('店铺运营'),
			active: '/store_operation'
		},
		{
			id: 3,
			columnName: t('全域数据'),
			active: '/global_data'
		},
		{
			id: 4,
			columnName: t('全域营销'),
			active: '/marketing'
		},
		{
			id: 5,
			columnName: t('创意视觉'),
			active: '/creativity'
		},
		{
			id: 6,
			columnName: t('美妆运营'),
			active: '/beauty_operation'
		},
		{
			id: 7,
			columnName: t('内容直播'),
			active: '/live'
		},
		// {
		//   id:8,
		//   columnName:t('品牌孵化'),
		//   active: '/brand_incubation'
		// },
		{
			id: 9,
			columnName: t('IT解决方案'),
			active: '/digital_intelligence'
		},
		{
			id: 10,
			columnName: t('仓储物流'),
			active: '/supply_management'
		}
	]
	const [selectColumn, setSelectColumn] = useState<number>(1)
	useEffect(() => {
		const path = window.location.pathname
		let index = obj.findIndex(item => item.active.includes(path))
		setSelectColumn(index + 1)
	}, [])
	return (
		<section className="ColumnPC">
			<div className="group">
				<ul className="columns">
					{obj.map((item, index) => {
						return (
							<li
								key={item.id}
								className={`columnItem ${
									selectColumn === index + 1 && 'selected'
								}
                    			${is_Zh ? 'zh' : 'cn'}`}
								onClick={() => {
									setSelectColumn(item.id)
								}}>
								<a href={item.active}> {item.columnName}</a>
							</li>
						)
					})}
				</ul>
			</div>
		</section>
	)
}
export default Column
