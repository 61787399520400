import React, { useState, useEffect } from 'react'
import { throttle, isInViewPort } from '@/utils/index'
import style from './ClubLife.module.scss'

// 社团活动

const FC: React.FC = () => {
	const is_Zh: boolean = localStorage.getItem('store_view') !== 'En'

	const [open, setOpen] = useState<boolean>(false)

	useEffect(() => {
		const aboutbqEl = document.getElementsByClassName(`${style.life}`)

		const listener = throttle(() => {
			const isinview = isInViewPort(aboutbqEl, 200) // 是否进入了可视区域
			setOpen(isinview)
		}, 50)
		document.addEventListener('scroll', listener)
		return () => {
			document.removeEventListener('scroll', listener)
		}
	}, [])
	return (
		<section className={style.life}>
			<div className="content">
				<div className="circle circle1"></div>
				<div className="circle circle2"></div>
				<div className="circle circle3"></div>
				<div className="circle circle4"></div>
				{is_Zh ? (
					<div className="text">
						<div className="text1">BQ花样年华</div>
						<div className="text2">在百秋</div>
						<div className="text3">我们快乐工作,认真生活! </div>
					</div>
				) : (
					<div className="text en">
						<div className="text1">BQ Happy Times</div>
						<div className="text2">At Buy Quickly</div>
						<div className="text3">we work happily and live seriously!</div>
					</div>
				)}
			</div>
		</section>
	)
}

export default FC
