import React, { useEffect } from 'react';
import objectFitImages from 'object-fit-images';
import style from './RecommendNews.module.scss'
// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
// 新闻
const Fc: React.FC = () => {
  useEffect(() => {
    // 解决ie不兼容css: objec-fit属性
    const img = document.querySelectorAll('.contLeftMedia')
    objectFitImages(img)
  })
  return (
    <section className={style.news}>
      {/* 头部新闻 */}
      <h2 className="h2">
        {
          is_Zh&&
          <span className="newTitle" >推荐新闻</span>
        }
        <span className="newbq" >Recommended news</span>
      </h2>
      <div className="border" ></div>
      {/* 图片 */}
      <div className="content">
        <div className="blockOne">
          <img className="photoOne" src="https://img.alicdn.com/imgextra/i3/2824260419/O1CN01ilJhNp1Exwfd0BDDn_!!2824260419-2-isvtu.png" alt=""/>
          <div className="words">上海百秋携Christian Louboutin天猫</div>
          <div className="date">2021/02/04</div>
        </div>
        <div className="blockTwo">
          <img className="pictureTwo" src="https://img.alicdn.com/imgextra/i1/2824260419/O1CN01Py9ZBZ1ExwfbSxPWA_!!2824260419-2-isvtu.png" alt=""/>
          <div className="words">上海百秋携Christian Louboutin天猫</div>
          <div className="date">2021/02/04</div>

        </div>
        <div className="blockOne">
          <img className="photoOne" src="https://img.alicdn.com/imgextra/i1/2824260419/O1CN01DF01z81ExwfiuUSk1_!!2824260419-2-isvtu.png" alt=""/>
          <div className="words">上海百秋携Christian Louboutin天猫</div>
          <div className="date">2021/02/04</div>

        </div>
      </div>
      {/* 按钮 */}
      <div className="look" >
        {/* <div className="btn">查看更多</div> */}
        查看更多
      </div>
    </section>
  )
}

export default Fc