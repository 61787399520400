import React,{useEffect,useState} from 'react';
import time from '@/assets/img/time.png'
import eye from '@/assets/img/eye1.png'
import './TopBanner.scss'
import { getQuery } from '@/utils/index'
import {setpPgeViewList} from '@/api/apis'
// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
interface Module_Data {
  img: string
  HTML_title: string
  mob_image_url: string
  service_type: { [key: string]: string }
  Display_time: string
}
interface BannerData {
  module_data: Module_Data[]
}

interface idData{
  id?:string
}
//案例详情头部
const TopBanner: React.FC<BannerData> = (props) => {
  const [nums,setNum]=useState<number>(0)
  useEffect(()=>{
    const id:idData= getQuery(window.location.search)
    setpPgeViewList({
      data:{id:Number(id.id)}
    }).then((res)=>{
      const num=Object.values(res.data)
      if(num){
        setNum(Number(num[0]))
      }
    })
  })
  return (
    <section className='TopBannerMob'>
      <div className='topInfor'>
        {props.module_data[0].img&&
        <img src={props.module_data[0].img} alt="" />
        }
        <div className='slogan' dangerouslySetInnerHTML={{ __html: props.module_data[0].HTML_title }}></div>
      </div>
      <div className='label'>
        {
          Object.values(props.module_data[0].service_type).map((item, index) => {
            return (
              <div className='labelItem' key={item + index} style={{padding:is_Zh?'0 8px':'0 2px'}}>{item}</div>
            )
          })
        }
      </div>
      <div className='dataInfom'>
        <div className='num'>
          <img src={eye} alt="" className='icon' />
          {nums}
        </div>
        {/* <div className='num'>
          <img src={time} alt="" className='icon2' />
          {props.module_data[0].Display_time}
        </div> */}
      </div>
      <div className="CaseBanner">
        {
          props.module_data[0].mob_image_url&&
          <img src={props.module_data[0].mob_image_url} alt="" />
        }
      </div>
    </section>
  )
}

export default TopBanner