import TopBanner from './TopBanner/TopBanner'
import TopPicture from './TopPicture/TopPicture'
import CaseLight from './CaseLight/CaseLight'
import Introduce from './Introduce/Introduce'
import CenterPicture from './CenterPicture/CenterPicture'
import WordContent from './WordContent/WordContent'
import BottomPicture from './BottomPicture/BottomPicture'
import TimeIntroduce from './TimeIntroduce/TimeIntroduce'
import OtherCase from './OtherCase/OtherCase'

const comp={
  Casehead: TopBanner,
  TopPicture,
  CaseLight,
  Introduce,
  CenterPicture,
  WordContent,
  BottomPicture,
  TimeIntroduce,
  OtherCase
}
export default comp