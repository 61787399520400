import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { throttle, isInViewPort } from '@/utils/index'
import style from './ContactUs.module.scss'
import BQ from '@/assets/img/bq.png'
// import Weixin from '@/assets/img/weixin.png'
// import Weibo from '@/assets/img/weibo.png'
// import IN from '@/assets/img/in.png'
// @ts-ignore
const is_Zh: boolean = localStorage.getItem('store_view') !== 'En'
const FC: React.FC = () => {
	const [open, setOpen] = useState<boolean>(false)
	const { t } = useTranslation()

	useEffect(() => {
		const el = document.getElementsByClassName(`${style.ContactUs}`)
		const isinview = isInViewPort(el)
		setOpen(isinview)
		// 监听滚动条
		const listener = throttle(() => {
			const isinview = isInViewPort(el)
			setOpen(isinview)
		}, 50)
		document.addEventListener('scroll', listener)
		return () => {
			document.removeEventListener('scroll', listener)
		}
	}, [])
	return (
		<section className={style.ContactUs}>
			<header className="header">
				{is_Zh && (
					<>
						<span
							className={`${open ? 'open_active' : ''} zh`}
							style={{
								transitionDelay: open ? '.1s' : ''
							}}>
							联系百秋
						</span>
						<span
							className={`${open ? 'open_active' : ''} cn`}
							style={{
								transitionDelay: open ? '.2s' : ''
							}}>
							Contact Buy Quickly
						</span>
					</>
				)}
				{!is_Zh && (
					<>
						<span
							className={`${open ? 'open_active' : ''} en`}
							style={{
								transitionDelay: open ? '.2s' : ''
							}}>
							Contact Buy Quickly
						</span>
					</>
				)}
			</header>
			<ul className="ul">
				<li
					className={`${open ? 'open_active' : ''} li `}
					style={{
						transitionDelay: open ? '.3s' : ''
					}}>
					<label className="label">{t('Tel')}</label>
					<div className="value">
						<span className="phone">+86-21-6013-6999</span>
					</div>
				</li>
				{/* <li
          className={`${open ? 'open_active' : ''} li`}
          style={{
            transitionDelay: open ? '.4s':'',
          }}
        >
          <label className='label'>Contact Email</label>
          <div className='value'>
            <div className='email'>
              <span className='email_label'>{ t('媒体合作邮箱') }</span>
              <span className='email_value'>pr@ibaiqiu.com</span>
            </div>
            <div className='email'>
              <span className='email_label'>{ t('求职招聘邮箱') }</span>
              <span className='email_value'>hr@ibaiqiu.com</span>
            </div>
            <div className='email'>
              <span className='email_label'>{ t('商务项目合作') }</span>
              <span className='email_value'>bd@ibaiqiu.com</span>
            </div>
            <div className='email'>
              <span className='email_label'>{ t('营销业务合作') }</span>
              <span className='email_value'>mkt@ibaiqiu.com</span>
            </div>
          </div>
        </li> */}
				<li
					className={`${open ? 'open_active' : ''} li`}
					style={{
						transitionDelay: open ? '.5s' : ''
					}}>
					<label className="label">Company Address</label>
					<div className="value">
						<span className="address">{t('公司详细地址')}</span>
					</div>
				</li>
				<li
					className={`${open ? 'open_active' : ''} li`}
					style={{
						transitionDelay: open ? '.6s' : ''
					}}>
					<label className="bottomItem">
						<div className="left">
							<div className="share">Company Wechat</div>
							{/* <ul className='share_icon'>
                <img src={Weibo} alt=""  className=''/>
                <img src={Weixin} alt="" className=''/>
                <img src={IN} alt="" className=''/>
              </ul> */}
						</div>
						<img src={BQ} alt="" className="img" />
					</label>
				</li>
			</ul>
		</section>
	)
}

export default FC
