import React, { useEffect,useState }  from 'react'
import Components from '@/components/PcComponents/newsMods';
import { getCms } from '@/api/apis';

interface comTtem {
  title_zh: string
  sub_title: string
  icon: string
  content: string,
}


const FC: React.FC = () => {
  const NewsHeader = Components.NewsHeader
  const NewsHonorList = Components.NewsHonorList
  const [data,setData]=useState<Array<comTtem>>([])
  function getNewsList() {
    getCms({
      params: { terminal: 'Ws_Pc', content_id: 'Performance', param_id: 'Performance' }
    }).then((res: any) => {
      if (res.data?.page_data) {
        setData(res.data?.page_data[2].module_data)
      }
    })
  }
  useEffect(()=>{
    getNewsList()
  },[])
  return (
    <div>
      <NewsHeader />
      <NewsHonorList data={data} />
    </div>
  )
}

export default FC
