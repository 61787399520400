import React, { useState, useEffect } from 'react'
import { throttle, isInViewPort } from '@/utils/index'
import { Collapse } from 'antd'
import objectFitImages from 'object-fit-images'
import SmallBanner from '../SmallBanner/SmallBanner'
import './Goodness.scss'
const { Panel } = Collapse

interface CollapseItem {
	content: string
	title_zh: string
	sub_title: string
	mob_image_url: string
	pc_image_url: string
	id: string
}
interface CollapseData {
	module_data: CollapseItem[]
	module_param?: {
		sub_title: string
		title_zh: string
	}
}
interface PanelHead {
	id: string
	title_zh: string
	isopen: boolean
	sub_title: string
	content: string
}
interface PanelCont {
	mob_image_url: string
	content: string
}
// 自定义折叠面板头部
const PanelHeader: React.FC<PanelHead> = props => {
	return (
		<div
			className="panel_header"
			style={{
				cursor: props.content ? 'pointer' : 'default'
			}}>
			<div className="num">{'0' + props.id}</div>
			<div className="contentWord">
				<div className="panel_zh">{props.title_zh}</div>
				{props.sub_title && <div className="panel_en">{props.sub_title}</div>}
			</div>
		</div>
	)
}
// 自定义折叠面板内容
const PanelContent: React.FC<PanelCont> = props => {
	useEffect(() => {
		// 解决ie不兼容css: objec-fit属性
		const img = document.querySelectorAll('.cont_img')
		objectFitImages(img)
	})
	return (
		<div className="panel_cont">
			{props.mob_image_url && (
				<img src={props.mob_image_url} className="cont_img" alt="" />
			)}
			<div
				className="cont"
				dangerouslySetInnerHTML={{ __html: props.content }}></div>
		</div>
	)
}
//行业案例
const Goodness: React.FC<CollapseData> = prop => {
	const [inView, setInView] = useState<boolean>(false)
	//标题数据
	const title = {
		title_ch: prop?.module_param?.title_zh || '服务优势',
		title_eg: prop?.module_param?.sub_title || 'Service Advantages'
	}

	useEffect(() => {
		const el = document.getElementsByClassName('GoodnessMob')
		// 监听滚动条
		const listener = throttle(() => {
			// 如果没进入过可视区域不加载动画
			if (!inView) {
				// 是否进入了可视区域
				const isinview = isInViewPort(el)
				// 记录已经进入可视区域
				setInView(isinview)
			}
		}, 50)
		document.addEventListener('scroll', listener)
		return () => {
			document.removeEventListener('scroll', listener)
		}
	}, [inView])

	// 打开的折叠面板
	const [openlist, setopenlist] = useState<string[] | string>('')
	// 切换面板的回调
	function callback(key: any) {
		if (key === undefined) {
			key = ''
		}
		setopenlist(key)
	}
	return (
		<section className="GoodnessMob">
			<div className="title">
				<SmallBanner {...title}></SmallBanner>
			</div>
			<div className="borders"></div>
			<Collapse
				defaultActiveKey={[]}
				onChange={callback}
				bordered={false}
				accordion={true}>
				{prop.module_data.map((item, index) => {
					return (
						<Panel
							showArrow={false}
							header={
								<PanelHeader
									title_zh={item.title_zh}
									content={item.content}
									id={item.id}
									isopen={openlist.includes(`${index}`)}
									sub_title={item.sub_title}
								/>
							}
							className="panel_item"
							key={index}
							style={{
								animationName: inView ? 'panelWidth' : '',
								animationDelay: `${(index + 1) * 0.3}s`,
								cursor: item.content ? 'pointer' : 'default'
							}}
							collapsible="disabled">
							<PanelContent
								content={item.content}
								mob_image_url={item.mob_image_url}
							/>
						</Panel>
					)
				})}
			</Collapse>
		</section>
	)
}
// Goodness.defaultProps={
//   title_alive:true,
//   obj:[
//     {
//       id:1,
//       nameLabel:'运营优势',
//       describe:'全渠道专长，覆盖运营全链路，提供全方位的解决方案',
//       content:{
//         img:'https://www.buyquickly.com/sites/default/files/capability/2018-10/about%20us-warehouse.jpg',
//         detaile:'已布局华东全国中央仓、华北、华南、西南区域仓，及上海保税仓，满足不同品牌的多样化布局需求。'
//       }
//     },
//     {
//       id:2,
//       nameLabel:'数据优势',
//       describe:'强大的BI体系、数据赋能',
//     },
//     {
//       id:3,
//       nameLabel:'工具优势',
//       describe:'智能工具、精准挖掘',
//     },
//     {
//       id:4,
//       nameLabel:'团队优势',
//       describe:'国际化团队、奢侈品与时尚行业专家',
//     }
//   ]
// }
export default Goodness
