import React from 'react';
import style from './ContactHead.module.scss';


// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'

const FC:React.FC = () => {
  return (
    <section className={style.ContactHead}>
      {
        is_Zh && <span className='zh'>联系</span>
      }
      <span className='cn'>Contact</span>
    </section>
  )
}

export default FC
