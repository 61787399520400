import React, { useState, useEffect } from 'react'
import style from './Search.module.scss'
import { useTranslation } from 'react-i18next'
import jiantou from '@/assets/img/jiantou.png'
// @ts-ignore
const is_Zh: boolean = localStorage.getItem('store_view') !== 'En'
interface blockData {
	route: string
	title: string
	oldRouter: string
}
interface listData {
	name: string
	actives: string[]
	en: string
	list: blockData[]
	url: string
}
interface searchData {
	list: blockData[]
}
const Search: React.FC<searchData> = prop => {
	const { t } = useTranslation()
	const [open, setOpen] = useState<boolean>(false)
	const [newList, setNewList] = useState<any[]>() //搜索渲染的数据
	//跳转页面
	const jumpPage = (route: string, oldRouter: string) => {
		if (oldRouter) {
			window.location.href = '/' + oldRouter
		} else {
			window.location.href = '/' + route
		}
	}
	// 模块组件
	const Block: React.FC<listData> = props => {
		return (
			<div className="block">
				<div className="oneTitle">
					{is_Zh && <div className="ch">{props.name}</div>}
					<div className="eg">{props.en}</div>
				</div>
				<div className="list">
					{props.list.map((item, index) => {
						return (
							<div
								key={index}
								className="listBlock"
								onClick={() => jumpPage(item.route, item.oldRouter)}>
								<img src={jiantou} alt="" className="right" />
								<div className="word">{item.title}</div>
							</div>
						)
					})}
				</div>
			</div>
		)
	}
	// 展开更多
	const more = () => {
		setOpen(true)
	}
	let navList: listData[] = [
		{
			name: t('首页'),
			url: '/',
			actives: [''],
			en: 'Index',
			list: []
		},
		{
			name: t('服务'),
			url: '/services',
			actives: [
				'services',
				'strategic',
				'supply_management',
				'digital_intelligence',
				'customer',
				'marketing',
				'operations',
				'creativity'
			],
			en: 'Service',
			list: []
		},
		{
			name: t('案例'),
			url: '/cases',
			actives: ['cases', 'case'],
			en: 'Case',
			list: []
		},
		{
			name: t('百秋'),
			url: '/about',
			actives: ['about'],
			en: 'Buy Quickly',
			list: []
		},
		{
			name: t('新闻'),
			url: '/news',
			actives: ['news', 'Honors', 'Performance', 'new'],
			en: 'News',
			list: []
		},
		{
			name: t('加入'),
			url: '/job',
			actives: ['job', 'club'],
			en: 'Join',
			list: []
		},
		{
			name: t('联系'),
			url: '/contact',
			actives: ['contact'],
			en: 'Contact',
			list: []
		}
	]
	//处理数据
	const handleData = (data: searchData) => {
		if (data.list) {
			let obj: listData[] = []
			for (let key in data.list) {
				if (data.list[key].route.indexOf('/') !== -1) {
					data.list[key].oldRouter = data.list[key].route.split('.')[0]
					data.list[key].route = data.list[key].route.split('/')[0]
				} else {
					data.list[key].route = data.list[key].route.split('.')[0]
				}
				navList = navList.map(item => {
					if (item.actives.includes(data.list[key].route)) {
						item.list.push(data.list[key])
					}
					return item
				})
			}
			navList.forEach(item => {
				if (item.list.length !== 0) {
					obj.push(item)
				}
			})
			setNewList(obj)
		}
	}
	useEffect(() => {
		handleData(prop)
	}, [prop])
	return (
		<section className={style.searchList}>
			<h2 className="title">
				<div className="titles">
					{is_Zh && <div className="ch">搜索结果</div>}
					<div className="eg">Search results</div>
				</div>
				<div className="num">{newList?.length || 0}</div>
			</h2>
			<div className="bottom">
				{newList &&
					newList.map((item, index) => {
						return (
							<div className="box" key={index}>
								{!open && index < 10 && item.list.length && <Block {...item} />}
								{open && item.list.length && <Block {...item} />}
							</div>
						)
					})}
				{(!newList || newList?.length === 0) && (
					<div className="noResult">非常抱歉，暂时未找到相关搜索内容</div>
				)}
			</div>
			{!open && newList && newList?.length > 10 && (
				<div className={`more ${is_Zh ? 'zh' : 'cn'}`} onClick={more}>
					{t('展开更多')}
				</div>
			)}
		</section>
	)
}

export default Search
