import React,{useEffect,useState} from 'react'
import Components from '@/components/MobComponents/serverMods/ServiceDetaile';
import { getCms } from '@/api/apis';


const StartegyConsulting: React.FC = () => {
  interface comTtem {
    module_id:string
  }
  const ServiceDetaileData : comTtem[]=[
    {
      module_id:'TopBanner'
    },
    // {
    //   module_id:'Column'
    // },
    {
      module_id:'ContentBlock'
    },
    {
      module_id:'Goodness'
    },
    {
      module_id:'ServiceScope'
    },
    {
      module_id:'ServiceAdvantages'
    },
    {
      module_id:'Honorar'
    },
    {
      module_id:'BusinessCase'
    },
  ]
  return (
    <>
      {
        ServiceDetaileData.map((item,index)=>{
          const ServiceDetaile:any=Components[item['module_id']]
          return ServiceDetaile && <ServiceDetaile key={index}/>
        })
      }
    </>
  )
}

export default StartegyConsulting
