import React, { useState,useEffect } from 'react';
import comp from '../index'
import { setpPgeViewCount } from '@/api/apis'
import './OtherCase.scss'
// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
interface blockInform {
  id:string,
  mob_image_url:string,
  img:string,
  title:string,
  service_type:any,
  creation_time:string,
  ws_url:string
}
interface otherData{
  otherList:blockInform[]
}
//标题内容
const titleInform = {
  light: "其他案例",
  eng: 'Other Case'
}
//品牌块组件
const BlockItem: React.FC<blockInform> = (prop) => {
  // 品牌标签参数
  const [label, setLabel] = useState([])
  // 处理时间
  const [time,setTime]=useState<string>('')
  useEffect(() => {
    // 筛选出label渲染的标签 
    setLabel(Object.values(prop.service_type))
    setTime(prop.creation_time.split(' ')[0])
  }, [prop.creation_time,prop.service_type])
  //案例详情跳转
  const detaile=(id:string,ws_url:string)=>{
    if(id){
      setpPgeViewCount({
        data: { id:Number(id)}
      }).then(() => {
        window.location.href=ws_url+'?id='+id
      })
    }
  }
  return (
    <div className='items'>
      <div className='topContent' onClick={()=>{detaile(prop.id,prop.ws_url)}}>
        {
          prop.mob_image_url&&
          <img
            src={prop.mob_image_url}
            alt=""
            className='pictureItem'
          />
        }
        {
          prop.img&&
          <img src={prop.img} alt="" className='brandName' />
        }
        <div className='brandLogo'>{prop.title}</div>
      </div>
      <div className='bottoms'>
        <div className='labels'>
          {
            label.map((item,index) => {
              return (
                <div className='labelItem' key={item+index} style={{padding:is_Zh?'8px':'2px'}}>{item}</div>
              )
            })
          }
        </div>
        <div className='data'>{time}</div>
      </div>
    </div>
  )
}
//其他案例
const OtherCase: React.FC<otherData> = (props) => {
  return (
    <section className='bottomCase'>
      <comp.CaseLight {...titleInform} />
      <div className='OtherCaseMob'>
        {
          props.otherList.map((item, index) => {
            return(
              <React.Fragment key={item.id}>
                {
                  index<3&&
                  <BlockItem {...item} />
                }
              </React.Fragment>
            )
          })
        }
        <a href="/cases">
          <div className='more'>查看更多</div>
        </a>
      </div>
    </section>
  )
}

export default OtherCase