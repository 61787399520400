
import React, { useEffect, useState } from 'react'
import Components from '@/components/PcComponents/CaseMods/CaseDetaile';
import { getCms } from '@/api/apis';
import { getId } from '@/utils/index'

const CaseDetaile: React.FC = () => {
  interface comTtem {
    module_id: string
    module_data: []
    module_param: []
    module_remark: string
    module_title: string
    parent_id: string
    sort: number
  }
  const [data, setData] = useState<Array<comTtem>>([])
  // 其他案例
  const [otherCase,setOtherCase]=useState<comTtem[]>([])
  useEffect(() => {
    const id= getId(window.location.pathname)
    getCms({
      params: { terminal: 'Ws_Pc', content_id: 'case', param_id: id }
    }).then((res: any) => {
      if (res) {
        setData(res.data.page_data)
      }
    })
    getCms({
      params: { terminal: 'Ws_Pc', content_id: 'cases', param_id: 'cases' }
    }).then((res: any) => {
      if (res) {
        setOtherCase(res.data.page_data[0].module_data)
      }
    })
    return () => {
      setData([])
    }
  }, [])
  return (
    <div>
      {
        data.map((item, index) => {
          const CaseDetaile: any = item.module_id !== 'Collapse' ? Components[item['module_id']] : Components['TimeIntroduce']
          const otherData:any=item.module_id==='OtherCase'?otherCase:[]
          return CaseDetaile && <CaseDetaile key={index} {...item} otherList={otherData}/>
        })
      }
    </div>
  )
}

export default CaseDetaile
