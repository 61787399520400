import { useTranslation } from 'react-i18next';
import SubNavHeader from '@/components/CommonComponents/pc/SubNavHeader/SubNavHeader'
import React from 'react';


const NewsHeader: React.FC = () => {
	// const { t } = useTranslation()
  // const tabs = [
  //   {
  //     name: t('绩效'),
  //     href: '/Performance',
  //     actives: ['/Performance']
  //   },
  //   {
  //     name: t('荣誉'),
  //     href: '/Honors',
  //     actives: ['/Honors']
  //   },
  //   {
  //     name: t('新闻'),
  //     href: '/news',
  //     actives: ['/news', '/new']
  //   }
  // ]
  return (
    <SubNavHeader  title_zh='新闻' title_cn='News' />
  )
}

export default NewsHeader