import React, { useEffect, useState } from 'react'
import { useSpring, animated } from 'react-spring'
import { throttle, isInViewPort } from '@/utils/index'
import background from '@/assets/img/background.png'
import './Present.scss'

// @ts-ignore
const is_Zh: boolean = localStorage.getItem('store_view') !== 'En'
//图片
const Present: React.FC = () => {
	// 数据
	const obj = [
		{
			id: 1,
			num: 1,
			track: '个赛道',
			objective: '专注美的行业',
			company: 'Track',
			describe: 'Beauty IndustryFocused'
		},
		{
			id: 2,
			num: 1,
			track: '体化',
			objective: '未来零售服务',
			company: 'Omni-channel',
			describe: 'Beauty IndustryFocused'
		},
		{
			id: 3,
			num: '120+',
			track: '位',
			objective: '国际品牌客户',
			describe: 'International Brands'
		},
		{
			id: 4,
			num: '2800+',
			track: '位',
			objective: '均龄27岁年轻活力团队',
			describe: 'Young team members aged 27 on average'
		}
	]
	const [show, setShow] = useState<boolean>(false)
	const [opacity, setOpacity] = useSpring(() => ({
		opacity: '0',
		config: { duration: 800 }
	}))
	useEffect(() => {
		const aboutbqEl = document.getElementsByClassName('PresentPC')
		const listener = throttle(() => {
			const isinview = isInViewPort(aboutbqEl) // 是否进入了可视区域
			setShow(isinview)
			setOpacity.start({ opacity: isinview ? '1' : '0' })
		}, 50)
		document.addEventListener('scroll', listener)
		return () => {
			document.removeEventListener('scroll', listener)
		}
	}, [])
	return (
		<section className="PresentPC">
			<img src={background} alt="" className="imgBack" />
			{obj.map((item, index) => {
				return (
					<animated.div
						className={`block block${index}`}
						key={item.id}
						style={opacity}>
						<div
							className="num"
							style={{
								animationName: show ? 'wordOpen' : '',
								animationDelay: show ? `${index * 0.5}s` : '0s'
							}}>
							{item.num}
						</div>
						{is_Zh && (
							<React.Fragment>
								<div className="unit">{item.track}</div>
								<div className="introduce">{item.objective}</div>
							</React.Fragment>
						)}
						{!is_Zh && (
							<React.Fragment>
								{item.company && <div className="unit">{item.company}</div>}
								<div className="describe">{item.describe}</div>
							</React.Fragment>
						)}
					</animated.div>
				)
			})}
		</section>
	)
}

export default Present
