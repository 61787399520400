import React, { useEffect, useRef, useState } from 'react'
import Components from '@/components/MobComponents/newsMods';
import { getCms } from '@/api/apis';
import { NewsModuleData_mob } from '@/ts/api';

const News: React.FC = () => {
  const NewsHeader = Components.NewsHeader
  const NewsList = Components.NewsList
  const Pages = Components.Pages
  const [total, setTotal] = useState<number>(0)
  const newsList = useRef<Array<NewsModuleData_mob>>([]);// 新闻列表页列表总数据
  const [newsShowList, setnewsShowList] = useState<Array<NewsModuleData_mob>>([]); // 展示数据
  const size = useRef<number>(6);// 每页展示数量

  useEffect(() => {
    getNewsList()
    return () => {
      setnewsShowList([])
    }
  }, [])
  function getNewsList() {
    getCms({
      params: { terminal: 'Ws_Mob', content_id: 'news', param_id: 'news' }
    }).then((res: any) => {
      if (res.data?.page_data) {
        newsList.current = res.data.page_data[0].module_data.reverse()
        setTotal(newsList.current.length)
        let list = sliceList<Array<NewsModuleData_mob>>(newsList.current, 0, size.current)
        if (Array.isArray(list)) {
          setnewsShowList(list)
        }
      }
    })
  }
  /**
   * @description: 分页器分页切换事件
   * @param {number} index 当前选择的页数，从1开始 
   */
   function changePage(index: number) {
    let list = sliceList<Array<NewsModuleData_mob>>(newsList.current, (index - 1) * size.current, size.current*index)
    if(Array.isArray(list)) {
      setnewsShowList(list)
    }
  }
  /**
   * @description: 从某个下标开始截取指定长度的数组
   * @param {T} list 数组泛型，传入时要约束数组类型
   * @param {number} index 数组截取的位置
   * @param {number} size 数组截取的长度
   * @return {T} 截取后的数组，为list的子集
   */  
   function sliceList<T>(list: T, index: number, size: number) {
    if (Array.isArray(list)) {
      return list.slice(index, size)
    }
    return list
  }
  return (
    <>
      <NewsHeader />
      <NewsList data={newsShowList} />
      <Pages totle={total} defaultPageSize={size.current} pages={changePage}/>
    </>
  )
}

export default News
