import React,{useState,useEffect} from 'react';
import { Collapse } from 'antd';
import { throttle, isInViewPort } from '@/utils/index';
import objectFitImages from 'object-fit-images';
import './TimeIntroduce.scss'
interface PanelData{
  module_data:{
    title_zh: string
    content: string
    isopen: boolean
    mob_image_url?: string
  }[]
}
interface PanelHead {
  title_zh: string
  content: string
  isopen: boolean
}
interface PanelCont {
  mob_image_url?: string
}

// 自定义折叠面板头部
const PanelHeader:React.FC<PanelHead> = (props) => {
  const [open,setOpen]=useState<boolean>(false)
  useEffect(() => {
    const pictureInform=document.getElementsByClassName('TimeIntroduceMob')
    const listener = throttle(() => {
      const isinview = isInViewPort(pictureInform) // 是否进入了可视区域
      if(isinview) setOpen(isinview)
    }, 50)
    document.addEventListener('scroll', listener)
    return () => {
      document.removeEventListener('scroll', listener)
    }
  }, [])
  return (
    <div className="panel_header">
      <div className={`time ${open?"transTop":""}`} style={{color: props.isopen ? '#F38400' : '#FFFFFF'}}>
        { props.title_zh }
      </div>
      <div className={`word ${open?"transTop":""}`} dangerouslySetInnerHTML={{__html:props.content}}>
      </div>
    </div>
  )
}

// 自定义折叠面板内容
const PanelContent:React.FC<PanelCont> = (props) => {
  useEffect(() => {
    // 解决ie不兼容css: objec-fit属性
    const img = document.querySelectorAll('.cont_img')
    objectFitImages(img)
  })
  return (
    <div className="panel_cont">
      {
        props.mob_image_url&&
        <img src={props.mob_image_url} className="cont_img" alt="" />
      }
    </div>
  )
}
// 我们的优势组件
const { Panel } = Collapse;
//时间轴
const TimeIntroduce: React.FC <PanelData>= (prop) => {
  // 打开的折叠面板
  const [openlist, setopenlist] = useState<string[]>([])
  // 切换面板的回调
  function callback(key:any) {
    setopenlist(key)
  }
  return (
    <section className='TimeIntroduceMob'>
      <Collapse
        defaultActiveKey={[]}
        onChange={callback}
        bordered={false}
      >
        {
          prop.module_data.map((item, index) => {
            return (
              <Panel
                header={
                  <PanelHeader
                    title_zh={item.title_zh}
                    content={item.content}
                    isopen={openlist.includes(`${index}`)}
                  />
                }
                className='panel_item'
                key={index}
                showArrow={false}
                collapsible='disabled'
              >
                {
                  item.content&&<PanelContent
                  mob_image_url={item.mob_image_url} 
                />
                }
              </Panel>
            )
          })
        }
      </Collapse>
    </section>
  )
}

export default TimeIntroduce