/*
 * @Author: yung
 * @Date: 2022-02-17 14:16:22
 * @LastEditTime: 2022-06-20 17:14:13
 * @LastEditors: ten ten.yan@ibaiqiu.com
 * @Description: 从layout字段中筛选第一个图片
 */
import React from 'react';
import { NewsLayoutItem } from '@/ts/api';

interface FCProps {
  // layout: NewsLayoutItem[]
  pc_image_url:string
}
// const filter = (layout: NewsLayoutItem): string => {
//   const imgs = layout.filter(item => item.type === 'image')
//   if(imgs.length>0) {
//     return imgs[0].pc_image_url
//   } else {
//     return ''
//   }
// }

const FilterImage: React.FC<FCProps> = props => {

  // const url = filter(props.pc_image_url)
  /**
   * @description: 筛选出第一张图片
   * @return {string} 图片地址
   */  
  return (
    <React.Fragment>
      {
        props.pc_image_url&&
        <img src={props.pc_image_url} alt="" className='img' />
      }
    </React.Fragment>
  )
}

export default FilterImage
