import Banner from './Banner/Banner'
import About from './About/About'
import FourEcharts from './FourEcharts/FourEcharts'
import Collapse from './Advantage/Advantage'
import Service from './Service/Service'
import Brands from './Brands/Brands'
import Newbrands from './NewBrands/Brands'
import News from './News/News'
import HonorCard from './OurHonor/OurHonor'

const comp = {
	Banner,
	About,
	FourEcharts,
	Collapse,
	Service,
	Brands,
	Newbrands,
	HonorCard,
	NewsModule: News
}

export default comp
