/*
 * @Author: your name
 * @Date: 2022-01-05 15:39:42
 * @LastEditTime: 2022-01-24 14:56:58
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \bq-official-web\src\views\PcViews\Service\Service.tsx
 */
import React, { useState, useEffect } from 'react'
import Components from '@/components/PcComponents/serverMods'
import { getCms } from '@/api/apis'
interface comItem {
	module_id: string
	module_data: []
	module_param: []
	module_remark: string
	module_title: string
	parent_id: string
	sort: number
}
const Service: React.FC = () => {
	const [data, setData] = useState<Array<comItem>>([])
	useEffect(() => {
		getCms({
			params: {
				terminal: 'Ws_Pc',
				content_id: 'services',
				param_id: 'services'
			}
		}).then((res: any) => {
			if (res) {
				setData(res.data.page_data)
			}
		})
		return () => {
			setData([])
		}
	}, [])

	return (
		<div>
			{data.map((item, index) => {
				const Service: any = Components[item['module_id']]
				return Service && <Service key={index} {...item} />
			})}
		</div>
	)
}

export default Service
