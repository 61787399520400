import React, { useEffect, useState } from 'react'
import { throttle, isInViewPort } from '@/utils/index'
import { useSpring, animated } from 'react-spring'
import objectFitImages from 'object-fit-images'
import { Modal } from 'antd'

import './About.scss'
interface BannerData {
	module_data: about[]
	module_param: aboutTitle
}

interface about {
	bg_mob_url: string
	bg_pc_url: string
	parent_id: number
	bg_type: string
	mob_url: string
	pc_url: string
	title: string
	sub_title: string
	describe: string
}
interface aboutTitle {
	sub_title: string
	title_zh: string
}
const is_Zh: boolean = localStorage.getItem('store_view') !== 'En'
const Fc: React.FC<BannerData> = (prop: BannerData) => {
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [title, setTitle] = useSpring(() => ({
		opacity: '0',
		config: { duration: 400 }
	}))
	const [border, setBorder] = useSpring(() => ({
		width: '0',
		config: { duration: 400 }
	}))

	useEffect(() => {
		const aboutbqEl = document.getElementsByClassName('aboutPC')
		const listener = throttle(() => {
			const isinview = isInViewPort(aboutbqEl) // 是否进入了可视区域
			if (isinview) {
				setBorder.start({ width: isinview ? '100%' : '0' })
				setTitle.start({ opacity: isinview ? '1' : '0' })
			}
		}, 10)
		document.addEventListener('scroll', listener)
		return () => {
			document.removeEventListener('scroll', listener)
		}
	}, [setBorder, setTitle])

	useEffect(() => {
		// 解决ie不兼容css: objec-fit属性
		const img = document.querySelectorAll('.contLeftMedia')
		objectFitImages(img)
	})

	const handleCancel = () => {
		setIsModalVisible(false)
	}
	return (
		<section className="aboutPC">
			<h2 className="h2">
				{is_Zh && (
					<animated.span className="aboutTitle" style={title}>
						{prop.module_param.title_zh}
					</animated.span>
				)}
				<animated.span className="aboutbq" style={title}>
					{prop.module_param.sub_title}
				</animated.span>
			</h2>
			<animated.div className="border" style={border}></animated.div>
			<div className="cont">
				<div className="contLeft">
					<video
						className="contLeftVideo"
						src={prop.module_data[0].pc_url}
						autoPlay
						muted
						loop></video>
					{is_Zh && (
						<div
							className={`contLeftPosition zh`}
							dangerouslySetInnerHTML={{
								__html: prop.module_data[0]?.title
							}}></div>
					)}
					{!is_Zh && (
						<div
							className={`contLeftPosition cn`}
							dangerouslySetInnerHTML={{
								__html: prop.module_data[0]?.title
							}}></div>
					)}
				</div>
				<div className="contRight">
					{is_Zh && (
						<div
							className="contRightTop"
							dangerouslySetInnerHTML={{
								__html: prop.module_data[0]?.sub_title
							}}></div>
					)}
					{!is_Zh && (
						<div
							className="contRightTop_eg"
							dangerouslySetInnerHTML={{
								__html: prop.module_data[0]?.sub_title
							}}></div>
					)}

					<div
						className="contRightCenter"
						dangerouslySetInnerHTML={{
							__html: prop.module_data[0]?.describe
						}}></div>

					<Modal
						title={null}
						visible={isModalVisible}
						onCancel={handleCancel}
						footer={null}
						mask={true}
						maskStyle={{ background: '#000' }}
						wrapClassName={'outModal'}>
						<video
							className="openVideo"
							src={prop.module_data[0].bg_pc_url}
							controls
							autoPlay></video>
					</Modal>
				</div>
			</div>
		</section>
	)
}

export default Fc
