import React, { useEffect, useState } from 'react'
import { throttle, isInViewPort } from '@/utils/index'
import { useSpring, animated } from 'react-spring'
import { Modal } from 'antd'
import objectFitImages from 'object-fit-images'
import VideoJS from '@/components/CommonComponents/mob/Video/Video' // point to where the functional component is stored
import './About.scss'
// import { OmitProps } from 'antd/lib/transfer/ListBody';
interface BannerData {
	module_data: about[]
	module_param: aboutTitle
}

interface about {
	bg_mob_url: string
	bg_pc_url: string
	parent_id: number
	bg_type: string
	mob_url: string
	pc_url: string
	title: string
	sub_title: string
	describe: string
}
interface aboutTitle {
	sub_title: string
	title_zh: string
}
// @ts-ignore
const is_Zh: boolean = localStorage.getItem('store_view') !== 'En'

const About: React.FC<BannerData> = (prop: BannerData) => {
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [title, setTitle] = useSpring(() => ({
		opacity: '0',
		config: { duration: 400 }
	}))
	const [border, setBorder] = useSpring(() => ({
		width: '0',
		config: { duration: 400 }
	}))
	useEffect(() => {
		const aboutbqEl = document.getElementsByClassName('aboutbq')
		const isinview = isInViewPort(aboutbqEl) // 是否进入了可视区域
		setBorder.start({ width: isinview ? '100%' : '0' })

		setTitle.start({ opacity: isinview ? '1' : '0' })
		const listener = throttle(() => {
			const isinview = isInViewPort(aboutbqEl) // 是否进入了可视区域
			setBorder.start({ width: isinview ? '100%' : '0' })

			setTitle.start({ opacity: isinview ? '1' : '0' })
		}, 50)
		document.addEventListener('scroll', listener)
		return () => {
			document.removeEventListener('scroll', listener)
		}
	}, [setBorder, setTitle])
	useEffect(() => {
		// 解决ie不兼容css: objec-fit属性
		const img = document.querySelectorAll('.contLeftMedia')
		objectFitImages(img)
	})

	const playerRef = React.useRef(null)
	let options = {
		// lookup the options in the docs for more options
		autoplay: true, // 自动播放
		controls: false, // 隐藏默认控件
		responsive: true,
		fluid: true,
		loop: true,
		muted: true,
		sources: [
			{
				src: prop.module_data[0]?.mob_url,
				type: 'video/mp4'
			}
		],
		chilldren: ['bigPlayButton']
	}
	const handlePlayerReady = (player: any) => {
		playerRef.current = player

		// you can handle player events here
		player.on('waiting', () => {
			// eslint-disable-next-line no-console
			console.log('player is waiting')
		})

		player.on('dispose', () => {
			// eslint-disable-next-line no-console
			console.log('player will dispose')
		})
	}

	const handleCancel = () => {
		setIsModalVisible(false)
	}
	return (
		<section className="aboutMob">
			<h2 className="h2">
				{is_Zh && (
					<animated.span className="aboutTitle" style={title}>
						{prop.module_param.title_zh}
					</animated.span>
				)}
				<animated.span className="aboutbq" style={title}>
					{prop.module_param.sub_title}
				</animated.span>
			</h2>
			<animated.div className="border" style={border}></animated.div>
			<div className="cont">
				<div className="contLeft">
					{is_Zh && (
						<div
							className={`contLeftPosition zh`}
							dangerouslySetInnerHTML={{
								__html: prop.module_data[0]?.title
							}}></div>
					)}
					{!is_Zh && (
						<div
							className={`contLeftPosition cn`}
							dangerouslySetInnerHTML={{
								__html: prop.module_data[0]?.title
							}}></div>
					)}

					<div className="contLeftMedia">
						<VideoJS
							options={options}
							onReady={handlePlayerReady}
							height={true}
						/>
					</div>
				</div>
				<div className="contRight">
					{is_Zh && (
						<div
							className="contRightTop"
							dangerouslySetInnerHTML={{
								__html: prop.module_data[0]?.sub_title
							}}></div>
					)}
					{!is_Zh && (
						<div
							className="contRightTop_eg"
							dangerouslySetInnerHTML={{
								__html: prop.module_data[0]?.sub_title
							}}></div>
					)}
					<div
						className="contRightCenter"
						dangerouslySetInnerHTML={{
							__html: prop.module_data[0]?.describe
						}}></div>

					<Modal
						title={null}
						visible={isModalVisible}
						onCancel={handleCancel}
						footer={null}
						mask={false}
						wrapClassName={'mobModal'}>
						<video
							className="openVideo"
							src={prop.module_data[0].bg_pc_url}
							autoPlay
							muted
							loop
							x5-video-player-fullscreen="true"
							playsInline></video>
					</Modal>
				</div>
			</div>
		</section>
	)
}

export default About
