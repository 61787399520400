import hexagon from './Hexagon';
import square from './Square';
import circular from './Circular';
import squareRotate from './SquareRotate';
import squareBoth from './SquareBoth';
import triangleLeft from './TriangleLeft';
import triangleRight from './TriangleRight';

const comp = {
  // 六边形
  hexagon,
  // 正方形
  square,
  // 圆
  circular,
  // 旋转45度的正方形
  squareRotate,
  // 长方形
  squareBoth,
  // 左边的三角形
  triangleLeft,
  // 右边的三角形
  triangleRight,

}

export default comp
