import React, { CSSProperties,useState,useEffect } from 'react';
import { Pagination } from 'antd';
import { ArrowRightOutlined,ArrowLeftOutlined } from '@ant-design/icons';
import style from './Pages.module.scss'
interface pageData{
  totle:number,
  defaultPageSize:number
  style?: CSSProperties
  pages?: (num: number) => void
  current?: number
}

//分页
const Pages: React.FC<pageData> = (prop) => {
  // 当前页数
  const [showPage, setshowPage] = useState<number>(1)
  // 总共的页数
  const [totlePage, settotlePage] = useState<number>(1)
  useEffect(()=>{
    settotlePage(Math.ceil(prop.totle/prop.defaultPageSize))
  },[prop.totle,prop.defaultPageSize])
  const changePage = (page: number) => {
    setshowPage(page)
    if (prop.pages) {
      prop.pages(page)
    }
    toTopEvent()
  }
  // 返回顶部
const toTopEvent = () => {
    const scrollToptimer = setInterval(function () {
      const top = document.body.scrollTop || document.documentElement.scrollTop;
      const speed = top / 4;
      if (document.body.scrollTop !== 0) {
        document.body.scrollTop -= speed;
      } else {
        document.documentElement.scrollTop -= speed;
      }
      if (top <= 5) {
        document.documentElement.scrollTop = 0;
        clearInterval(scrollToptimer);
      }
    }, 30);
  }
//页码前后选择样式
function itemRender(current:number, type:"page" | "prev" | "next" | "jump-prev" | "jump-next", originalElement:any) {
  if (type === 'prev') {
    return (
    <span>
      {
        showPage!==1&&
        <ArrowLeftOutlined 
          style={{fontSize: '17px',color:'#FFFFFF'}}
        />
      }
    </span>
    );
  }
  if (type === 'next') {
    return (
      <span>
        {
          showPage!==totlePage&&
          <ArrowRightOutlined 
            style={{fontSize: '17px',color:'#FFFFFF'}}
          />
        }
      </span>
    );
  }
  return originalElement;
}
  return (
    <section className={style.PagesPC} >
      {/* 分页 */}
      <div className='bottomPage' style={{ ...prop.style }}>
        <Pagination 
          total={prop.totle||1} 
          showSizeChanger={false}
          itemRender={itemRender}
          defaultPageSize={prop.defaultPageSize}
          onChange={changePage}
        /> 
      </div>
    </section>
  )
}
Pages.defaultProps={
  totle:100,
  defaultPageSize:6
}
export default Pages