import React, { useEffect, useState, useRef } from 'react'
import Components from '@/components/PcComponents/homeMods'
import TotopImg from '@/assets/img/totop.png'
import { getCms } from '@/api/apis'
import { throttle, isInViewPort } from '@/utils/index'
import { NewsModuleData } from '@/ts/api'
import style from './Home.Module.scss'

interface comItem {
	module_id: string
	module_data: []
	module_param: []
	module_remark: string
	module_title: string
	parent_id: string
	sort: number
}

const Home: React.FC = () => {
	const [showTop, setShowTop] = useState<boolean>(false)
	//新闻的数据
	const [newData, setNewData] = useState<NewsModuleData[]>()
	const homeRef = useRef<any>()
	const [data, setData] = useState<Array<comItem>>([])
	useEffect(() => {
		getCms({
			params: { terminal: 'Ws_Pc', content_id: 'index', param_id: 'index' }
		}).then((res: any) => {
			if (res) {
				setData(res.data.page_data)
			}
		})
		getCms({
			params: { terminal: 'Ws_Pc', content_id: 'news', param_id: 'news' }
		}).then((res: any) => {
			if (res.data?.page_data) {
				const newsList = res.data.page_data[0].module_data.reverse()
				const arr = newsList.slice(0, 3)
				setNewData(arr)
			}
		})
		// const el = document.querySelector(`.${style.home}`)
		// 监听滚动条
		const listener = throttle(() => {
			const isinview = isInViewPort(homeRef.current, 3000)
			setShowTop(isinview)
		}, 50)
		document.addEventListener('scroll', listener)
		return () => {
			document.removeEventListener('scroll', listener)
			setData([])
			setNewData([])
		}
	}, [])

	// 返回顶部
	const toTopEvent = () => {
		const scrollToptimer = setInterval(function () {
			const top = document.body.scrollTop || document.documentElement.scrollTop
			const speed = top / 5
			if (document.body.scrollTop !== 0) {
				document.body.scrollTop -= speed
			} else {
				document.documentElement.scrollTop -= speed
			}
			if (top <= 5) {
				document.documentElement.scrollTop = 0
				clearInterval(scrollToptimer)
			}
		}, 30)
	}

	return (
		<div className={style.home} ref={homeRef}>
			{data.map((item, index) => {
				// @ts-ignore
				const Com: any = Components[item['module_id']]
				return Com && item['module_id'] === 'NewsModule' ? (
					<Com key={index} {...item} newData={newData} />
				) : (
					<Com key={index} {...item} />
				)
			})}
			{/* 返回顶部 */}
			{/* <img
        src={TotopImg}
        alt=""
        className='top'
        onClick={() => toTopEvent()}
        style={{
          display: showTop ? 'block' : 'none'
        }}
      /> */}
		</div>
	)
}

export default Home
