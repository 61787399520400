import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Collapse } from 'antd'
import logo from '@/assets/img/logo.png'
import JiaPng from '@/assets/img/jia.png'
import JianPng from '@/assets/img/jian.png'
import close from '@/assets/img/close.png'
import { setLang } from '@/api/apis'
import './MobHeader.scss'
import search from '@/assets/img/search.png'
const { Panel } = Collapse

interface PanelHead {
	id: string
	label: string
	keys: string
	url?: string
	labelItem?: PanelCont[]
}
interface PanelCont {
	id: number
	labelName: string
	url?: string
	child?: ChildPanel[]
}
interface ChildPanel {
	id?: number
	labelName?: string
	url?: string
}
const MobHeader: React.FC = () => {
	const { t } = useTranslation()
	const [lang, setLangs] = useState<string | null>(
		localStorage.getItem('store_view') === 'Zh' ? 'En' : 'Zh'
	)

	// const is_Zh:boolean = (localStorage.getItem('store_view') === 'Zh')
	// 搜索框内容
	let content: HTMLInputElement | null = null
	// 是否搜索
	const [searchOpen, setSearchOpen] = useState<boolean>(false)
	const [urlTarget, setUrlTarget] = useState<string>('')
	const [pathUrl, setPathUrl] = useState<string>('')
	// 数据
	const obj = [
		{
			id: '01',
			label: t('首页'),
			url: '/'
		},
		{
			id: '02',
			label: t('服务'),
			labelItem: [
				{
					id: 1,
					labelName: t('战略咨询'),
					url: '/strategic'
				},
				{
					id: 2,
					labelName: t('店铺运营'),
					url: '/store_operation'
				},
				{
					id: 3,
					labelName: t('全域数据'),
					url: '/global_data'
				},
				{
					id: 4,
					labelName: t('全域营销'),
					url: '/marketing'
				},
				{
					id: 5,
					labelName: t('创意视觉'),
					url: '/creativity'
				},
				{
					id: 6,
					labelName: t('美妆运营'),
					url: '/beauty_operation'
				},
				{
					id: 7,
					labelName: t('内容直播'),
					url: '/live'
				},
				// {
				// 	id:8,
				// 	labelName:t('品牌孵化'),
				// 	url: '/brand_incubation'
				// },
				{
					id: 9,
					labelName: t('IT解决方案'),
					url: '/digital_intelligence'
				},
				{
					id: 10,
					labelName: t('仓储物流'),
					url: '/supply_management'
				}
			],
			url: '/services'
		},
		{
			id: '03',
			label: t('案例'),
			url: '/cases'
		},
		{
			id: '04',
			label: t('百秋'),
			url: '/about'
		},
		{
			id: '05',
			label: t('新闻'),
			// labelItem: [
			// 	{
			// 		id: 1,
			// 		labelName: t('绩效'),
			// 		url:'/Performance'
			// 	},
			// 	{
			// 		id: 2,
			// 		labelName: t('荣誉'),
			// 		url:'/Honors'
			// 	},
			// 	{
			// 		id: 3,
			// 		labelName: t('新闻'),
			// 		url:'/news'
			// 	},
			// ],
			url: '/news'
		},
		{
			id: '06',
			label: t('加入'),
			labelItem: [
				{
					id: 1,
					labelName: t('百秋文化'),
					url: '/club'
				},
				{
					id: 2,
					labelName: t('加入百秋'),
					url: '/job'
					// child:[
					// 	{
					// 		id: 1,
					// 		labelName: t('校园招聘'),
					// 		url:'/job?type=1'
					// 	},
					// 	{
					// 		id: 1,
					// 		labelName: t('社会招聘'),
					// 		url:'/job?type=0',
					// 	}
					// ]
				}
			],
			url: '/job'
		},
		{
			id: '07',
			label: t('联系'),
			url: '/contact'
		}
	]
	const [openlist, setopenlist] = useState<string[] | string>('')
	// 左侧栏目
	const [visible, setVisible] = useState<boolean>(false)
	useEffect(() => {
		lightNav()
		setPathUrl(window.location.pathname)
	}, [])
	const changeLange = () => {
		setLang({
			data: {
				lang: localStorage.getItem('store_view') === 'Zh' ? 'en_US' : 'zh_CHS'
			}
		}).then(res => {
			localStorage.setItem('store_view', lang === 'Zh' ? 'Zh' : 'En')
			setLangs(localStorage.getItem('store_view') === 'Zh' ? 'En' : 'Zh')
			window.location.href = window.location.pathname
		})
	}
	// 切换面板的回调
	function callback(labelItem: any, event: any) {
		let changeKey = event.target.dataset.keys
		if ((changeKey && changeKey === openlist) || !labelItem) {
			changeKey = ''
		}
		setopenlist(changeKey)
	}
	// 根据url将目录高亮
	const lightNav = () => {
		setUrlTarget(window.location.pathname)
	}
	// 自定义折叠面板头部
	const PanelHeader: React.FC<PanelHead> = props => {
		return (
			<div
				className="panel_header"
				onClick={e => {
					callback(props.labelItem, e)
				}}>
				<a href={props.url} className={pathUrl === props.url ? 'lightUrl' : ''}>
					<div className={`num ${openlist === props.keys && 'avtive'}`}>
						{props.id}
					</div>
					<div className={`name ${openlist === props.keys && 'avtive'}`}>
						{props.label}
					</div>
				</a>
				{props.labelItem && (
					<img
						src={openlist === props.keys ? JianPng : JiaPng}
						className="icon"
						alt=""
						data-keys={props.keys}
					/>
				)}
			</div>
		)
	}
	// 自定义折叠面板内容
	const PanelContent: React.FC<PanelCont> = props => {
		return (
			<div className="menu">
				<a href={props.url}>
					<div className={`sonItem ${urlTarget === props.url && 'light'}`}>
						{props.labelName}
					</div>
				</a>
				{/* 三级菜单 */}
				{props.child && (
					<div className="three">
						{props.child.map(item => {
							return (
								<a href={item.url} key={item.id}>
									<div className="threeItem">{item.labelName}</div>
								</a>
							)
						})}
					</div>
				)}
			</div>
		)
	}
	// 抽屉组件
	const Drawer = () => {
		const stopEvent = (e: any) => {
			e.stopPropagation()
		}
		return (
			<div className="drawer" onClick={e => stopEvent(e)}>
				<div className="border"></div>
				<Collapse
					defaultActiveKey={[]}
					bordered={false}
					accordion={true}
					className="colla"
					activeKey={openlist}>
					{obj.map((item, index) => {
						return (
							<Panel
								showArrow={false}
								header={
									<PanelHeader
										id={item.id}
										label={item.label}
										labelItem={item.labelItem}
										keys={`${index}`}
										url={item.url}
									/>
								}
								className="panel_item"
								key={index}>
								{item.labelItem?.map((self, index) => {
									return <PanelContent {...self} key={self.id + index} />
								})}
							</Panel>
						)
					})}
				</Collapse>
			</div>
		)
	}
	// 打开关闭抽屉
	const showDrawer = () => {
		setVisible(true)
	}
	const onClose = () => {
		setVisible(false)
		setopenlist('')
	}
	// 搜索
	const searchWord = () => {
		setSearchOpen(true)
		setVisible(false)
		if (content) {
			window.location.href = '/Search?searchWord=' + content.value
		}
	}
	//关闭搜索
	const closeSearch = () => {
		setSearchOpen(false)
	}
	const keyDownEvent = (e: any) => {
		if (e.keyCode === 13 && content?.value) {
			// 点击搜索
			window.location.href = '/Search?searchWord=' + content.value
		}
	}
	return (
		<div className="bannerHeader">
			<header className="header">
				{!visible && !searchOpen && (
					<div className="leftMenu" onClick={showDrawer}>
						<div className="long"></div>
						<div className="short"></div>
					</div>
				)}
				{visible && (
					<div className="close" onClick={onClose}>
						<img src={close} alt="" className="closeButton" />
					</div>
				)}
				{!searchOpen && (
					<a href="/">
						<img className="logo" src={logo} alt="" />
					</a>
				)}
				{/* 搜索框 */}
				{searchOpen && (
					<input
						type="search"
						className="searchWord"
						placeholder="Search"
						ref={word => {
							content = word
						}}
						onKeyDown={e => keyDownEvent(e)}
					/>
				)}
				<div className="rightMenu">
					<img src={search} alt="" className="searchBtn" onClick={searchWord} />
					{
						<div className="langeBtn" onClick={() => changeLange()}>
							{lang === 'Zh' ? '中文' : 'En'}
						</div>
					}
					{searchOpen && (
						<div className="close" onClick={closeSearch}>
							<img src={close} alt="" className="closeButton" />
						</div>
					)}
				</div>
			</header>
			{(visible || searchOpen) && (
				<div className="selectBanner" onClick={() => onClose()}>
					{visible && <Drawer></Drawer>}
				</div>
			)}
		</div>
	)
}

export default MobHeader
