import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import er from '@/assets/img/ER.png'
import { throttle, isInViewPort } from '@/utils/index'
import style from './Er.module.scss'
// @ts-ignore
const is_Zh: boolean = localStorage.getItem('store_view') !== 'En'
const er_html: string = `
<p>百秋文化的灵魂就是一个“二”字，二，准确的说就是简单深刻。简单，就是心地善良，勇于担当，刀口向内，话不多，事不拖，人不作，希望通过自身努力获得与之匹配的成就；深刻，即是具有透过现象看本质的能力。</p>
<p></p>
<p>简单与深刻是一对矛盾的统一体，体现在一个人的身上其实很不易，体现在一个公司的气质上就更是难得, 简单的人往往不够深刻，深刻了的人往往也就不那么简单了。但简单和深刻本质上又是一致的，真正的简单，不是一时的风格或者某种性格，而是真看透了本质，心有定见，不被一些虚名假利或者表面现象所迷惑。</p>
<p></p>
<p>真做到既简单又深刻，其实非常不容易，是一种难能可贵的品质。但越是难能可贵，我们越是要将它赋予我们的文化，铸成我们的灵魂，刻到我们骨子里。在这个纷繁复杂、激烈竞争的社会里，只有真正具备了简单深刻的品质，才能让公司保持高效，才能让我们总是能够拨开迷雾，放弃捷径，作出难而正确的决定。只是简单，可能让我们一时走得很快，但一定不能让我们走得长远，只有再加上深刻，才能够让我们行稳致远，基业长青。</p>
`
const er_html_en: string = `
  <p>The soul of Buy Quickly Culture is the word “Er”. Er, precisely speaking, is simplicity and depth. Simplicity means a kind heart, courage to take responsibility, self-criticism, reticence, decisiveness, sincerity, and the hope to make achievements through one’s efforts. Depth means the ability to see the essence through phenomena.</p>
  <p></p>
  <p>Simplicity and depth are a pair of contradictory unity. It is not easy to possess both for a person, and it is even more difficult for a company to possess both. Simple people are often not deep enough, while people with deep thinking are often not so simple. But simplicity and depth are essentially the same. Real simplicity is not a temporary style or a certain character, but a true insight into the essence, a firm mind, and immunity from temptation by fame or superficial phenomenon. To be simple and deep is very difficult, and also very valuable. </p>
  <p></p>
  <p>But the more valuable it is, the more necessary it is for us to forge it into our culture, cast it into our soul, and carve it into our bones. In this complex and fiercely competitive society, only by truly possessing simplicity and depth can one company remain efficient forever. By doing so we can always clear the fog, give up shortcuts, and make difficult but correct decisions. Simplicity alone may make us go fast for a while, but we can’t go far. Only with depth and simplicity can we go from strength to strength.</p>
`

const FC: React.FC = () => {
	const [open, setOpen] = useState<boolean>(false)
	const { t } = useTranslation()

	useEffect(() => {
		const aboutbqEl = document.getElementsByClassName(`${style.er}`)
		const isinview = isInViewPort(aboutbqEl) // 是否进入了可视区域
		setOpen(isinview)

		const listener = throttle(() => {
			const isinview = isInViewPort(aboutbqEl) // 是否进入了可视区域
			if (isinview !== open) setOpen(isinview)
		}, 50)
		document.addEventListener('scroll', listener)
		return () => {
			document.removeEventListener('scroll', listener)
		}
	}, [])

	return (
		<section className={style.er}>
			<header className="header">
				<div className="left">
					{is_Zh && (
						<span
							className={`${open ? 'active' : ''} zh`}>{`文化&价值观`}</span>
					)}
					<span
						className={`cn ${open ? 'active' : ''} ${
							is_Zh ? '' : 'en'
						}`}>{`Culture & Values`}</span>
				</div>
			</header>
			<div className="content">
				<div className="left">
					<div className={`${open ? 'active' : ''} er cn`}>{t('二文化')}</div>
					{is_Zh ? (
						<div
							className={`${open ? 'active' : ''} culture`}
							dangerouslySetInnerHTML={{ __html: er_html }}></div>
					) : (
						<div
							className={`${open ? 'active' : ''} culture`}
							dangerouslySetInnerHTML={{ __html: er_html_en }}></div>
					)}
				</div>
				<div className="right">
					<img src={er} alt="" className={`er ${open ? 'activeEr' : ''}`} />
				</div>
			</div>
		</section>
	)
}

export default FC
