import React, { useEffect, useState } from 'react';
import { throttle, isInViewPort } from '@/utils/index';
import { useSpring, animated } from 'react-spring';
import JiaPng from '@/assets/img/jia.png';
import JianPng from '@/assets/img/jian.png';
import objectFitImages from 'object-fit-images';
import { TitlesType } from '@/ts/api'
import { Collapse } from 'antd';

import './Advantage.scss'
interface BannerData {
  module_data: Collapses[]
  module_param: TitlesType
}

interface Collapses {
  content: string
  mob_image_url: string
  title_zh: string
  id: number
  sub_title: string
}
// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
// 我们的优势组件

const { Panel } = Collapse;

interface PanelHead {
  panel_zh: string
  panel_en: string
  isopen: boolean
}
interface PanelCont {
  mob_image_url: string
  content: string
}

// 自定义折叠面板头部
const PanelHeader: React.FC<PanelHead> = (props) => {
  return (
    <div className="panel_header">
      <div className="leftFont">
        <div className="panel_zh" style={{ opacity: props.isopen ? 1 : 0.5 }}>
          <span className={is_Zh ? '' : 'panel_en_title'}>{props.panel_zh}</span>
        </div>
        {
          props.panel_en &&
          <div className="panel_en" style={{ opacity: props.isopen ? 1 : 0.5 }}>
            {props.panel_en}
          </div>
        }
      </div>
      <img src={props.isopen ? JianPng : JiaPng} className="icon" alt="" />
    </div>
  )
}

// 自定义折叠面板内容
const PanelContent: React.FC<PanelCont> = (props) => {
  useEffect(() => {
    // 解决ie不兼容css: objec-fit属性
    const img = document.querySelectorAll('.cont_img')
    objectFitImages(img)
  })
  return (
    <div className="panel_cont">
      {
        props.mob_image_url&&
        <img src={props.mob_image_url} className="cont_img" alt="" />
      }
      <div className="cont" dangerouslySetInnerHTML={{ __html: props.content }}></div>
    </div>
  )
}

// 我们的优势组件
const Fc: React.FC<BannerData> = (prop: BannerData) => {
  // 进入可视区域后，默认只加载一次动画
  const [inView, setInView] = useState<boolean>(false)
  // 打开的折叠面板
  const [openlist, setopenlist] = useState<string[]>([])

  // 头部title的spring动画
  const [title, setTitle] = useSpring(() => ({ opacity: '1', width: '0' }))

  useEffect(() => {
    const el = document.getElementsByClassName('AdvantagePc_mob')
    // console.log(el[0]?.offsetTop, 'el');
    // 监听滚动条
    const listener = throttle(() => {
      // 如果没进入过可视区域不加载动画
      if (!inView) {
        // 是否进入了可视区域
        const isinview = isInViewPort(el)
        // 记录已经进入可视区域
        setInView(isinview)
        // 开启动画
        setTitle.start({
          opacity: isinview ? '1' : '0',
          width: isinview ? '100%' : '0',
          config: {
            duration: isinview ? 1000 : 0 // 动画持续时间ms
          }
        })
      }
    }, 50)
    document.addEventListener('scroll', listener)
    return () => {
      document.removeEventListener('scroll', listener)
    }
  }, [setTitle, inView])

  // 切换面板的回调
  function callback(key: any) {
    setopenlist(key)
  }

  return (
    <section className='AdvantagePc_mob'>
      <animated.h2 className='h2' style={title}>
        {
          is_Zh &&
          <span className='title_zh'>{prop.module_param.title_zh}</span>
        }
        <span className='title_en'>{prop.module_param.sub_title}</span>
      </animated.h2>
      {/* {
        is_Zh &&
        <span className='title_zh'>{prop.module_param.title_zh}</span>
      } */}
      {/* <span className='title_en'>{prop.module_param.sub_title}</span> */}
      <Collapse
        defaultActiveKey={[]}
        onChange={callback}
        bordered={false}
      >
        {
          prop.module_data.map((item, index) => {
            return (
              <Panel
                header={
                  <PanelHeader
                    panel_zh={item.title_zh}
                    panel_en={item.sub_title}
                    isopen={openlist.includes(`${index}`)}
                  />
                }
                className='panel_item'
                key={index}
                showArrow={false}
                style={{
                  animationName: inView ? 'panelWidth' : '',
                  animationDelay: `${(index + 1) * 0.1}s`
                }}
              >
                <PanelContent mob_image_url={item.mob_image_url} content={item.content} />
              </Panel>
            )
          })
        }
      </Collapse>
    </section>
  )
}

export default Fc