import TopBanner from '@/components/MobComponents/serverMods/ServiceDetaile/TopBanner/TopBanner'
import React from 'react';

const NewsHeader: React.FC = () => {
  return (
    <TopBanner titleChinese="新闻" titleEnglish='News'></TopBanner>
    
  )
}

export default NewsHeader