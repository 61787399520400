import TopBanner from './TopBanner/TopBanner'
// import Column from './Column/Column'
import Services from './ContentBlock/ContentBlock'
import Goodness from './Goodness/Goodness'
import ServiceScope from './ServiceScope/ServiceScope'
import ServiceAdvantages from './ServiceAdvantages/ServiceAdvantages'
import HonorCard from './Honorar/Honorar'
import Collapse from './BusinessCase/BusinessCase'
const comp={
  TopBanner,
  // Column,
  Services,
  Goodness,
  ServiceScope,
  ServiceAdvantages,
  HonorCard,
  Collapse
}
export default comp