import React, { useEffect, useState } from 'react';
import { throttle, isInViewPort } from '@/utils/index';
import { useTranslation } from 'react-i18next';
import { useSpring, animated } from 'react-spring';
import objectFitImages from 'object-fit-images';

import { Collapse, Divider } from 'antd';

import './Activity.scss'
// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
// 我们的优势组件

const { Panel } = Collapse;

interface ClubData{
  data:{
    module_data:{
      title_zh: string
      sub_title: string
      mob_image_url: string
      content: string
      layout:{
        pc_image_url?:string
      }[]
    }[]
  }
}
interface PanelHead {
  title_zh: string
  isopen: boolean
}
interface PanelCont {
  mob_image_url: string
  sub_title: string
  content: string
  isopen: boolean
  layout:{
    pc_image_url?:string
  }[]
}

// 自定义折叠面板头部
const PanelHeader:React.FC<PanelHead> = (props) => {
  return (
    <div className="panel_header">
      <div 
        className="panel_zh" 
        style={{
          opacity: props.isopen ? 1 : 0.5,
          color:props.isopen ?'#FFFFFF':'#999999'
        }}
      >
        { props.title_zh }
      </div>
    </div>
  )
}

// 自定义折叠面板内容
const PanelContent:React.FC<PanelCont> = (props) => {
  useEffect(() => {
    // 解决ie不兼容css: objec-fit属性
    const img = document.querySelectorAll('.cont_img')
    objectFitImages(img)
  })
  return (
    <div className='panel_cont' style={{animationName: props.isopen ? 'panel_cont_open' : 'panel_cont_close'}} id='activipanel'>
      <div className='data'>{props.sub_title}</div>
      <div className="cont" dangerouslySetInnerHTML={{__html:props.content}}></div>
      <div className='imgs'>
        {
          props.layout.map((item,index)=>{
            return(
              <React.Fragment key={"cont_imgTwo"+index}>
                {
                  item.pc_image_url&&
                  <img src={item.pc_image_url} className="cont_imgTwo" alt="" />
                }
              </React.Fragment>
            )
          })
        }
      </div>
    </div>
  )
}

// 折叠面板
const Fc: React.FC <ClubData>= (props) => {
  // 进入可视区域后，默认只加载一次动画
  const [inView, setInView] = useState<boolean>(false)
  // 打开的折叠面板
  const [openlist, setopenlist] = useState<string[]>([])
	const { t } = useTranslation()

  // 头部title的spring动画
  const [title, setTitle] = useSpring(() => ({opacity: '1', width: '0'}))

  useEffect(() => {
    const el = document.getElementsByClassName('ActivityMob')
    // 监听滚动条
    const listener = throttle(() => {
      // 如果没进入过可视区域不加载动画
      if(!inView) {
        // 是否进入了可视区域
        const isinview = isInViewPort(el)
        // 记录已经进入可视区域
        setInView(isinview)
        // 开启动画
        setTitle.start({
          opacity: isinview ? '1':'0',
          width: isinview ? '100%' : '0', 
          config: {
            duration: isinview ? 1000 : 0 // 动画持续时间ms
          }
        })
      }
    }, 50)
    document.addEventListener('scroll', listener)
    return () => {
      document.removeEventListener('scroll', listener)
    }
  }, [setTitle, inView])

  // 切换面板的回调
  function callback(key:any) {
    if(key) {
      setopenlist([key])
      const el = document.getElementById(`activiMob${key}`)
      const top=el?.offsetTop
      const el2:any = document.querySelectorAll(`#activipanel`)
      let initHeight
      let height
      if(top){
        if(el2.length!==0){
          height=window.getComputedStyle(el2[el2.length-1]).height
          height=height.slice(0,-2)
        }
        if(openlist[0]&& key > Number(openlist[0])){
          initHeight=top-(Number(height)||0)
        }else{
          initHeight=top-54
        }
      }
      if (el) {
        window.scrollTo({
          top: initHeight,
          behavior: "smooth"
        });
      }
    } else {
      setopenlist([])
    }
  }

  return (
    <section className='ActivityMob'>
      <animated.h2 className='h2' style={title}>
        <span className={`title_zh ${is_Zh?'zh':'cn'}`}>{t('员工活动')}</span>
      </animated.h2>
      <Collapse
        defaultActiveKey={[]}
        onChange={callback}
        bordered={false}
        accordion
      >
        {
          props.data.module_data.map((item, index) => {
            return (
              <Panel
                header={
                  <PanelHeader
                    title_zh={item.title_zh}
                    isopen={openlist.includes(`${index}`)}
                  />
                }
                className='panel_item'
                key={index}
                showArrow={false}
                id={`activiMob${index}`}
                style={{
                  animationName: inView ? 'panelWidth' : '',
                  animationDelay: `${(index+1)*0.1}s`
                }}
              >
                <PanelContent mob_image_url={item.mob_image_url} content={item.content} isopen={openlist.includes(`${index}`)} layout={item.layout} sub_title={item.sub_title}/>
              </Panel>
            )
          })
        }
      </Collapse>

    </section>
  )
}

export default Fc