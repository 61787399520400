import React, { useEffect, useState } from 'react'
import style from './NewsHonorList.module.scss';
import CardFilp from '@/components/CommonComponents/pc/CardFilp/CardFilp';
import CardOpen from '@/components/CommonComponents/pc/CardOpen/CardOpen';
// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
interface Item {
  title_zh: string
  sub_title: string
  icon: string
  content: string,
}

interface DataItem {
  data: Item[]
}

interface FrontBackProps extends Item {
  index: number // 第几列
  indx: number // 第几个，序号=(index+1)+3*indx
}



// 正面标题
const Front: React.FC<FrontBackProps> = (props) => {
  const num = 3*props.indx + props.index + 1
  let strNum = num<10 ? `0${num}.` : `${num}.`
  return (
    <div className={style.front}>
      <div className='frontTop'>
        <span className='num'>{ strNum }</span>
        {
          props.icon&&
          <img src={props.icon} alt="" className='icon'/>
        }

      </div>
      <div className='frontBtm'>
        {
          is_Zh&&
          <span className='zh'>{ props.title_zh }</span>
        }
        <span className={`cn ${is_Zh?'cn1':'zh1'}`}>{ props.sub_title }</span>
      </div>
    </div>
  )
}

// 反面内容
const Back: React.FC<FrontBackProps> = (props)=> {
  const num = 3*props.indx + props.index + 1
  let strNum = num<10 ? `0${num}.` : `${num}.`
  return (
    <div className={style.back}>
      <div className='backTop'>
        <span className='num'>{ strNum }</span>
      </div>
      <div dangerouslySetInnerHTML={{__html: props.content}}></div>
    </div>
  )
}

const Index: React.FC<DataItem> = (props) => {
  const [filterData, setfilterData] = useState<Item[][]>([])

  useEffect(() => {
    if(props.data.length>0) {
      let arr1 :Item[] = []
      let arr2 :Item[] = []
      let arr3 :Item[] = []
      props.data.forEach((item, index) => {
        if(index%3 === 0) {
          arr1.push(item)
        } else if(index%3 === 1) {
          arr2.push(item)
        } else {
          arr3.push(item)
        }
      })
      setfilterData([arr1, arr2, arr3])
    } else {
      setfilterData([[], [], []])
    }
  }, [props.data])

  return (
    <section className={style.newsList}>
      {
        filterData.map((item, index) => {
          return (
            <div
              className='colList'
              key={'colList'+index}
            >
              {
                item.map((itm, indx) => {
                  return (
                    <CardOpen
                      key={indx}
                      cardClassName={`card-${index}-${indx}`}
                      width='100%'
                      defalutOpen={indx<1}
                      inviewTime={1}
                      bodyStyle={{padding: 0}}
                    >
                      <CardFilp
                        frontChildren={<Front index={index} indx={indx} {...itm} />}
                        backChildren={<Back index={index} indx={indx} {...itm} />}
                      ></CardFilp>
                    </CardOpen>
                  )
                })
              }
            </div>
          )
        })
      }
    </section>
  )
}

export default Index
