import React, { CSSProperties } from 'react';
import { Pagination } from 'antd';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import './Pages.scss'
// import { getInitialProps } from 'react-i18next';
interface pageData {
  totle: number
  defaultPageSize: number
  style?: CSSProperties
  pages?: (num: number) => void
  current?: number
}
//页码前后选择样式
function itemRender(current: number, type: "page" | "prev" | "next" | "jump-prev" | "jump-next", originalElement: any) {
  if (type === 'prev') {
    return (
      <span>
        <ArrowLeftOutlined
          style={{ fontSize: '17px', color: '#FFFFFF' }}
        />
      </span>
    );
  }
  if (type === 'next') {
    return (
      <span>
        <ArrowRightOutlined
          style={{ fontSize: '17px', color: '#FFFFFF' }}
        />
      </span>
    );
  }
  return originalElement;
}
// 返回顶部
const toTopEvent = () => {
  const scrollToptimer = setInterval(function () {
    const top = document.body.scrollTop || document.documentElement.scrollTop;
    const speed = top / 4;
    if (document.body.scrollTop !== 0) {
      document.body.scrollTop -= speed;
    } else {
      document.documentElement.scrollTop -= speed;
    }
    if (top <= 5) {
      document.documentElement.scrollTop = 0;
      clearInterval(scrollToptimer);
    }
  }, 30);
}
//分页
const Pages: React.FC<pageData> = (prop) => {
  const changePage = (page: number) => {
    if (prop.pages) {
      prop.pages(page)
    }
    toTopEvent()
  }
  return (
    <section className='PagesPC'>
      {/* 分页 */}
      <div className='bottomPage' style={{ ...prop.style }}>
        <Pagination
          total={prop.totle||1}
          showSizeChanger={false}
          itemRender={itemRender}
          defaultPageSize={prop.defaultPageSize}
          onChange={changePage}
        ></Pagination>
      </div>
    </section>
  )
}
Pages.defaultProps = {
  totle: 100,
  defaultPageSize: 2
}
export default Pages