import React from 'react';
import style from './index.module.scss';
interface  letter{
  letter:string,
  letter2:string
}
// 六边形
const FC:React.FC <letter>= (props) => {
  return (
    // 盒子通过边框做的白色三角形
    <div className={style.Hexagon}>
      {/* 六边形 */}
      <div className='one'></div>
      <div className='two'></div>
      <div className='three'></div>
      {/* 第一个字母 */}
      <div className={`${style.text} first_text`}>
        <div className='letter_box'>
          <span className='letter'>{props.letter}</span>
        </div>
      </div>
      {/* 第二个字母 */}
      <div className={`${style.text} last_text`}>
        <div className='letter_box'>
          <span className='letter'>{props.letter2}</span>
        </div>
      </div>
    </div>
  )
}

export default FC
