import React from 'react';
import Ttitle from '../Title/Title'
import './SocialRespons.scss'

interface SocialData{
  SocialRespons1:string,
  SocialRespons2:string
}
//标题
const SocialRespons: React.FC<SocialData> = (prop) => {
  const title={
    title_ch:'社会责任',
    title_eg:'Social responsibility',
    other_title:'Social Responsibility'
  }
  return (
    <section className='SocialResponsPC'>
      <Ttitle {...title}/>
      <div className='parentBox'>
        <div dangerouslySetInnerHTML={{__html: prop.SocialRespons1}} className='one'></div>
        <div dangerouslySetInnerHTML={{__html: prop.SocialRespons2}} className='two'></div>
      </div>
    </section>
  )
}
SocialRespons.defaultProps={
  SocialRespons1:`
    <p style=text-align:center>2020年1月，上海百秋企业社会责任公益项目——“千秋公益行动”起航，<br/><p>
    <p style=text-align:center>聚焦少年、女性群体的性别、美学文化教育与保护公益领域。<br/><p>
  `,
  SocialRespons2:`
    <p style=text-align:center>于2020年3月疫情期间开展湖北十堰疫区113位山区适学儿童教育助学行动;<br/><p>
    <p style=text-align:center>11月携手麦田教育基金会 “健康课堂”项目，深度、定向关注湖北十堰地区“乡村儿童的身心安全教育”议题，以1年6次系统化的卫生、心理、性教育等课堂，共助益3所乡村小学、500位乡村儿童健康快乐成长<p>
  `
}
export default SocialRespons