import React,{useEffect,useState} from 'react';
import { useSpring, animated } from 'react-spring';
import { throttle, isInViewPort } from '@/utils/index';
import './BqTarget.scss'
// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
interface BqTargetData{
  wordOne:string,
  wordTwo:string,
  wordThree:string,
  wordFour:string,
  wordFive:string
  wordSix:string
  wordSeven:string
  wordEight:string
  word_eg:string
}
//百秋简介内容
const BqTarget: React.FC<BqTargetData> = (prop) => {
  // 进入可视区域后，默认只加载一次动画
  const [inView, setInView] = useState<boolean>(false)
  // 进入可视区域
  const [start,setStart]=useState<boolean>(false)
  const [word, setWord] = useSpring(() => ({opacity: '0', config: { duration: 400 }}))
  const [height1, setHeight1] = useSpring(() => ({height: '0', config: { duration: 300 }}))
  const [height2, setHeight2] = useSpring(() => ({height: '0', config: { duration: 300 }}))
  const [height3, setHeight3] = useSpring(() => ({height: '0', config: { duration: 500 }}))
  useEffect(() => {
    const aboutbqEl = document.getElementsByClassName('introduce')
    const detaileIntro = document.getElementsByClassName('introOne')
    const detaileIntro2 = document.getElementsByClassName('introTwo')
    const listener = throttle(() => {
      if(!inView){
        const isinview = isInViewPort(aboutbqEl) // 是否进入了可视区域
        const isinview1 = isInViewPort(detaileIntro) // 是否进入了可视区域
        const isinview2 = isInViewPort(detaileIntro2) // 是否进入了可视区域
        setStart(isinview)
        setWord.start({opacity: isinview ? '1':'0'})
        setHeight1.start({height: isinview1 ? '160px':'0'})
        setHeight2.start({height: isinview2 ? '110px':'0'})
        setHeight3.start({height: isinview2 ? '60px':'0'})
        // 记录已经进入可视区域
        setInView(isinview2)
      }
    }, 50)
    document.addEventListener('scroll', listener)
    return () => {
      document.removeEventListener('scroll', listener)
    }
  }, [setWord,setHeight1,setHeight2,setHeight3,inView])
  return (
    <section className='BqTargetMob'>
      <animated.div className='introduce' style={word}>
        {
          is_Zh&&
          <div className='position'>
            <div className='lineOne'>
              <div className='whiteWord'>{prop.wordOne}</div>
              {/* 进入可视区域放大 */}
              <div 
                className='yellowWord' 
                style={{animationName:start?'open':""}}
              >{prop.wordTwo}</div>
            </div>
            <div className='lineOne'>
              <div className='whiteWord'>{prop.wordThree}</div>
              <div 
                className='yellowWord'
                style={{
                  animationName:start?'open':"",
                  animationDelay:start?'0.3s':'0'
                }}
              >{prop.wordFour}</div>
              <div className='whiteWord'>{prop.wordFive}</div>
            </div>
          </div>
        }
        {
          !is_Zh&&
          <div className='eg_word'>
              {prop.word_eg}
          </div>
        }
        {/* 右侧文字 */}
        <div className='detaileIntro' >
          <animated.div className='introOne' style={height1}>{prop.wordSix}</animated.div>
          <animated.div className='introTwo' style={height2}>{prop.wordSeven}</animated.div>
          <animated.div className='introThree' style={height3}>{prop.wordEight}</animated.div>
        </div>
      </animated.div>
    </section>
  )
}
BqTarget.defaultProps={
  wordOne:'专注',
  wordTwo:'国际时尚轻奢',
  wordThree:'及',
  wordFour:'奢品品牌领军',
  wordFive:'服务商',
  wordSix:"上海百秋电子商务有限公司（简称“百秋”，英文名“BUY QUICKY”)专注于时尚垂直领域、面向未来零售的新中间服务商；为80+国际高端时尚轻奢及奢侈品牌提供从战略咨询、全域营销、视觉创意、全渠道运营、数智解决方案、供应链管理到客户服务的全链路、全渠道、一站式服务；现已成为轻奢及奢侈品领域领军服务商。",
  wordSeven:"百秋自2011年成立，总部位于上海，并拥有8家海外分公司；现员工超2500人，累计GMV突破300亿；同时拥有超3000平米的视觉创意工作室和12万平米的自建高标准仓库。2019年，红杉时尚科技产业股权投资基金及多家行业战略投资人与深圳歌力思集团和百秋成功达成战略投资协议；4.943亿战略投资的落地，将再次续写百秋发展的新篇章！",
  wordEight:"百秋致力于成为国际品牌最信赖的战略合作伙伴和令人尊敬的全球化企业，重新定义零售行业新型中间商关系，将全球美好生活方式体验，带给每一位消费者。",
  word_eg:'A leading service provider for affordable luxury and luxury brands in the fashion industry '
}
export default BqTarget