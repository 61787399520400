import React, { useState } from 'react';
import xia from '@/assets/img/circleBottom.png'
import shang from '@/assets/img/circleTop.png'
import style from './JobList.module.scss';

const ListItem:React.FC<JobData> = props => {
  const [open, setOpen] = useState<boolean>(false)

  const openEvent = () => {
    setOpen(!open)
  }
  return (
    <li className='li'>
      <div className='name'>{props.title_zh}</div>
      <div className='detail'>
        {/* 岗位职责 */}
        <section className='post'>
          <span className='label'>岗位职责</span>
          <div className='dl' dangerouslySetInnerHTML={{__html: props.Job_responsibilities}}></div>
        </section>
        {/* 任职要求 */}
        <section className='nead'>
          <div className='order' onClick={() => openEvent()}>
              <div className='label' >任职要求</div>
                {
                  open?
                  <img src={xia} alt="" className='jiantou'/>:
                  <img src={shang} alt="" className='jiantou'/>
                }
          </div>
          <div className={open?'dl2':'dl_box2'} dangerouslySetInnerHTML={{__html: props.Job_requirements}}></div>
        </section>
      </div>
    </li>
  )
}

interface JobData {
  title_zh: string // 岗位名称
  Job_requirements: string // 岗位要求
  Job_responsibilities: string // 岗位职责
}
interface Jobs {
  jobList?: JobData[]
}
const FC: React.FC<Jobs> = (props) => {
  return (
    <ul className={style.JobList}>
      {
        props.jobList && props.jobList.map(((item, index) => {
          return (
            <React.Fragment key={index}>
              <ListItem {...item}/>
            </React.Fragment>
          )
        }))
      }
    </ul>
  )
}

export default FC
