import React from 'react';
import './TopPhoto.scss'
// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
interface title {
  pc_url: string,
  HTML_title: string
}
interface module {
  module_data: title[]
}
//大图片
const TopPhoto: React.FC<module> = (prop) => {
  return (
    <section className='TopPhotoPC'>
      {prop.module_data.map((item, index) => {
        return (
          <div className='topContent' key={item.HTML_title + index}>
            {
              item.pc_url&&
              <img src={item.pc_url} alt="" className='backPhoto' />
            }
            <div className={`target ${is_Zh?'zh':'cn'}`} dangerouslySetInnerHTML={{ __html: item.HTML_title }}></div>
          </div>
        )
      })}
    </section>
  )
}
TopPhoto.defaultProps = {
  module_data: [
    {
      pc_url: 'https://www.buyquickly.com/sites/default/files/new_retail/2018-10/center_4.jpg',
      HTML_title:
        `
        <p>未来<br/></p>
        <p>零售<br/></p>
        <p>新中间<br/></p>
      `
    }
  ]
}
export default TopPhoto