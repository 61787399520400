import React, { useState, useEffect } from 'react';
import { throttle, isInViewPort } from '@/utils/index';
import shuqian from '@/assets/img/shuqian.png'
import SmallBanner from '../SmallBanner/SmallBanner';
import './Honorar.scss'

interface CartNum {
  id: string,
  title_zh?: number,
  content: string,
}

// 卡片组件
const Cart: React.FC<CartNum> = (prop) => {
  const [show, setShow] = useState<Boolean>(false)
  useEffect(() => {
    const aboutbqEl = document.getElementsByClassName(`cart${prop.id}`)
    const listener = throttle(() => {
      const isinview = isInViewPort(aboutbqEl, -10) // 是否进入了可视区域了可视区域
      if (isinview) setShow(true)
    }, 50)
    document.addEventListener('scroll', listener)
    return () => {
      document.removeEventListener('scroll', listener)
    }
  }, [setShow, prop.id])
  return (
    <div className='outCart'>
      <div
        className={`cart cart${prop.id}`}
        style={{
          animationName: show ? 'openCart' : '',
          animationDelay: `${(Number(prop.id) - 1) * 0.6}s`
        }}
      >
        <div className='time'>{prop.title_zh}</div>
        <div className="prize" dangerouslySetInnerHTML={{ __html: prop.content }}></div>
        <img src={shuqian} alt="" className='icon' />
        {/* 遮盖层 */}
        <div
          className='cartOpa'
          style={{
            animationName: show ? 'closeCart' : '',
            animationDelay: `${(Number(prop.id) - 1) * 0.5}s`
          }}
        ></div>
      </div>
    </div>
  )
}
//荣誉资质
interface HonorData {
  id: string,
  title_zh: number,
  content: string,
}
interface CartData{
  module_param:{
    sub_title:string
    title_zh:string
  }
  module_data:HonorData[]
}
const Honorar: React.FC<CartData> = (prop) => {
  const [list, setlist] = useState<Array<Array<CartNum>>>([])
  // 返回的数据
  // 处理返回数据
  useEffect(() => {
    let data: Array<Array<CartNum>> = [
      [],
      [],
      [],
    ]
    prop.module_data.forEach((item, index) => {
      data[index % 3].push(item)
    })
    setlist(data)
  }, [])
  return (
    <section className='HonorarPC'>
      <SmallBanner title_ch={prop.module_param.title_zh} title_eg={prop.module_param.sub_title}></SmallBanner>
      <div className='allContent'>
        {
          list.map((item, index) => {
            return (
              <div
                key={index}
                className={`column column${index}`}
              >
                {
                  item.map((self, key) => {
                    return (
                      <Cart key={self.id} {...self}></Cart>
                    )
                  })
                }
              </div>
            )
          })
        }
      </div>
    </section>
  )
}
// Honorar.defaultProps = {
//   line: [
//     {
//       id: 1,
//       time: 2020,
//       content: `
//         <p>2020年度</br><p>
//         <p>最佳合作伙伴奖</br><p>
//       `,
//     },
//     {
//       id: 2,
//       time: 2020,
//       content: `
//         <p>2020年度</br><p>
//         <p>最佳合作伙伴奖</br><p>
//       `,
//     },
//     {
//       id: 3,
//       time: 2020,
//       content: `
//         <p>2020年度</br><p>
//         <p>最佳合作伙伴奖</br><p>
//       `,
//     },
//     {
//       id: 4,
//       time: 2020,
//       content: `
//         <p>2020年度</br><p>
//         <p>最佳合作伙伴奖</br><p>
//       `,
//     },
//     {
//       id: 5,
//       time: 2020,
//       content: `
//         <p>2020年度</br><p>
//         <p>最佳合作伙伴奖</br><p>
//       `,
//     },
//     {
//       id: 6,
//       time: 2020,
//       content: `
//         <p>2020年度</br><p>
//         <p>最佳合作伙伴奖</br><p>
//       `,
//     },
//   ]
// }
export default Honorar