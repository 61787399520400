import React from 'react';
import CardOpen from '@/components/CommonComponents/mob/CardOpen/CardOpen';
import style from './NewsList.module.scss';

interface DataItem {
  mob_image_url: string
  HTML_title: string
  Display_time: string
  id: string
  width_scale: string
  wordStyle?: string
  ws_url: string
}

interface NewsListProps {
  data: DataItem[]
}

const NewsList: React.FC<NewsListProps> = (props) => {
  const clickEvent = (link: string) => {
    window.location.href = link
  }
  return (
    <section className={style.newsList}>
      <div className='border'></div>
      <div className='listBox'>
        {
          props.data.map((item, index) => {
            return (
              <CardOpen cardClassName={`card${index}`} width={'100%'} defalutOpen={index < 2} key={index}
              >
                <div className={((index+1)%2!==1)?'blockLeft':'blockRight'}>
                  {
                    (index+1) % 4 === 2 &&
                    (item.HTML_title || item.Display_time) &&
                    <div className='cardFootCenter'>
                      <span className='word' dangerouslySetInnerHTML={{ __html: item.HTML_title }}></span>
                      <span className='data'>{item.Display_time}</span>
                    </div>
                  }
                  <div
                    className='imgBox'
                    onClick={() => clickEvent((item.ws_url))} style={{
                      cursor: item.id ? 'pointer' : '',
                    }}>
                    {
                      item.mob_image_url &&
                      <img src={item.mob_image_url} alt="" className='img' />
                    }
                  </div>
                  {
                    ((index+1) % 2 === 1) &&
                    (item.HTML_title || item.Display_time) &&
                    <div
                      className='cardFootRight'
                    >
                      <span className='word' dangerouslySetInnerHTML={{ __html: item.HTML_title }}></span>
                      <span className='data'>{item.Display_time}</span>
                    </div>
                  }
                  {
                    (index+1) % 4 === 0 &&
                    (item.HTML_title || item.Display_time) &&
                    <div
                      className='cardFootLeft'
                    >
                      <span className='word' dangerouslySetInnerHTML={{ __html: item.HTML_title }}></span>
                      <span className='data'>{item.Display_time}</span>
                    </div>
                  }
                </div>
                {/* {
                !item.wordStyle &&
                (item.HTML_title || item.Display_time) &&
                <div className='cardFoot'>
                  <span dangerouslySetInnerHTML={{__html: item.HTML_title}}></span>
                  <span className='data'>{item.Display_time}</span>
                </div>
              } */}
              </CardOpen>
            )
          })
        }
      </div>
    </section>
  )
}

export default NewsList