import React from 'react';
import style from  './TopBanner.module.scss'
// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
interface title{
  titleChinese?:string,
  titleEnglish:string
}
//标题
const TopBanner: React.FC<title> = (prop) => {
  
  return (
    <section className={style.TopBannerMob}>
      <div className='titleBox'>
        {
          (prop.titleChinese&&is_Zh)&&
          <div className='tltle_ch'>
            <a href="/services">{prop.titleChinese}</a>
          </div>
        }
        <div className={`tltle_eg`}>
          <a href="/services">{prop.titleEnglish}</a>
        </div>
      </div>
    </section>
  )
}
TopBanner.defaultProps={
  titleChinese:'服务',
  titleEnglish:'Service'
}
export default TopBanner