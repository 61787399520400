
import React from 'react';
import videojs from "video.js";
import "video.js/dist/video-js.css";
import style from './Video.module.scss'
// https://blog.csdn.net/a0405221/article/details/80923090


export const VideoJS = ( props:any ) => {
  //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
  const ua = navigator.userAgent;

  const videoRef = React.useRef<any>();
  const playerRef = React.useRef<any>();
  const { options, onReady, height } = props;

  React.useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const isWeixinAdroidPhone = isWeiXin()

      const player = playerRef.current = videojs(videoElement, {...options, controls: isWeixinAdroidPhone}, () => {
        // console.log("player is ready");
        onReady && onReady(player);
      });
    } else {
      // you can update player here [update player through props]
      // const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [options, videoRef,height]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;
    // console.log(player, 'p');
    return () => {
      if (player) {
        // player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);
  
  //判断是否是微信浏览器的函数
  const isWeiXin = () => {
    //通过正则表达式匹配ua中是否含有micromessenger字符串
    if(ua.match(/MicroMessenger/i)){
      return true;
    } else{
      return false;
    }
  }
  // 判断是否是安卓机
  // const isAdroid = () => {
  //   return ua.indexOf('Android')>-1 || ua.indexOf('Linux')>-1
  // }

  return (
    <div data-vjs-player>
      <video
        muted
        loop
        autoPlay
        x5-video-player-type="h5"
        x5-video-player-fullscreen="true"
        webkit-playinline='true'
        playsInline={true}
        x-webkit-airplay="true"
        ref={videoRef}
        className={`${style.video} video-js vjs-big-play-centered`}
      />
    </div>
  );
}

export default VideoJS;