import NewsHeader from './NewsHeader/NewsHeader'
import NewsList from './NewsList/NewsList'
import NewsHonorList from './NewsHonorList/NewsHonorList'
import Pages from '@/components/CommonComponents/mob/Pages/Pages'
import Border from '@/components/CommonComponents/mob/Border/Border'
const comp = {
  // 头部
  NewsHeader,
  // 新闻列表
  NewsList,
  // 分页
  Pages,
  // 荣誉
  NewsHonorList,
  Border
}
export default comp