import NewsHeader from '../newsMods/NewsHeader/NewsHeader';
import Text from '@/components/CommonComponents/pc/Text/Text';
import OneImage from '@/components/CommonComponents/pc/OneImage/OneImage';
import TwoImage from '@/components/CommonComponents/pc/TwoImage/TwoImage';
import RecommendNews from '../newsDetail/RecommendNews/RecommendNews';

const comp = {
  // 头部
  NewsHeader,
  // 文本组件
  Text,
  // 一张图片
  OneImage,
  // 两张图片
  TwoImage,
  // 推荐新闻组件
  RecommendNews,
}

export default comp
