import React from 'react';
import style from './index.module.scss';
interface  letter{
  letter:string,
  letter2:string
}
// 圆
const FC:React.FC <letter>= (props) => {
  return (
    <div className={style.Circular}>
      <div className={`${style.text} first_text`}>{props.letter}</div>
      <div className={`${style.text} last_text`}>
        <div className='letter'>{props.letter2}</div>
      </div>
    </div>
  )
}

export default FC
