import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { throttle, isInViewPort } from '@/utils/index'
import './ServiceScope.scss'

interface ServiceScopeList {
	module_data: ServiceScopeData[]
}
interface ServiceScopeData {
	id: string
	title_zh: string
	content: string
}
//服务范围
const ServiceScope: React.FC<ServiceScopeList> = prop => {
	const { t } = useTranslation()
	// 数据
	const [show, setShow] = useState<boolean>(false)
	const [show1, setShow1] = useState<boolean>(false)
	useEffect(() => {
		const ServiceBlock = document.getElementsByClassName('ServiceScopePC')
		const ServiceBlock1 = document.getElementsByClassName('ServiceScopePC')
		const listener = throttle(() => {
			const isinview = isInViewPort(ServiceBlock, 500) // 是否进入了可视区域了可视区域
			const isinview1 = isInViewPort(ServiceBlock1) // 是否进入了可视区域了可视区域
			if (isinview) {
				setShow(true)
			}
			if (isinview1) {
				setShow1(true)
			}
		}, 50)
		document.addEventListener('scroll', listener)
		return () => {
			document.removeEventListener('scroll', listener)
		}
	})
	return (
		<section className="ServiceScopePC">
			{/* Service scope */}
			<div className="left">
				<div
					className="word"
					style={{
						top: show ? '0' : '100px'
					}}>
					Service scope
				</div>
			</div>
			{/* 服务范围 */}
			<div className="centerCircle">
				<div className="circle">
					<div
						className="word2"
						style={{
							top: show ? '0' : '100px'
						}}>
						{t('服务范围')}
					</div>
				</div>
			</div>
			{/* 具体内容 */}
			<div className="rightContent">
				{prop.module_data.map((item, index) => {
					return (
						<div className="outBox" key={index}>
							<div
								className={`detaileContent detaileContent${item.id} ${
									prop.module_data.length === 1 && 'detaileContentSingle'
								}`}
								key={item.id}
								style={{
									animationName: show1 ? 'wordTop' : '',
									animationDelay: `${(index + 1) * 0.5}s`
								}}>
								<div className="topTitle">{item.title_zh}</div>
								<div className="bottomTitle">
									{item.content &&
										item.content !== '<p><br/></p>' &&
										item.content.split('/n').map((textContent, i) => {
											return (
												<p
													key={i}
													dangerouslySetInnerHTML={{ __html: textContent }}></p>
											)
										})}
								</div>
							</div>
							{/* 边框线 */}
							<div
								className="borders"
								style={{
									animationName: show1 ? 'wordWidth' : '',
									animationDelay: `${(index + 1) * 0.3}s`
								}}></div>
						</div>
					)
				})}
			</div>
		</section>
	)
}
export default ServiceScope
