import React from 'react';
import style from './TitleHor.module.scss'
// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
interface TitleHorData{
  TitleHor_ch:string,
  TitleHor_eg:string,
  other_title?:string
}
//标题
const TitleHor: React.FC<TitleHorData> = (prop) => {
  
  return (
    <section className={style.TitleHorMob}>
      <div className='TitleHor'>
        {
          is_Zh&&
          <div className='chinese'>{prop.TitleHor_ch}</div>
        }
        <div className='english'>{is_Zh?prop.TitleHor_eg:prop.other_title}</div>
      </div>
    </section>
  )
}
TitleHor.defaultProps={
  TitleHor_ch:'百秋简介',
  TitleHor_eg:'Introduction',
  other_title:'Honorary Qualification'
}
export default TitleHor