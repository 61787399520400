import React, { useState, useEffect, useRef } from 'react';
import CardFilp from '@/components/CommonComponents/pc/CardFilp/CardFilp';
import { TitlesType } from '@/ts/api'
import { throttle } from '@/utils/index';
import honor from '@/assets/img/honor.png'
import style from './OurHonor.module.scss'
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
interface BannerData {
  module_data: HonorCard[]
  module_param: TitlesType
}

interface HonorCard {
  content: string
  title_zh: string
  id: string
  sub_title: string
}
// 卡片组件
interface CartNum {
  id: string,
  title_zh: string,
  content: string,
  style?: string,
  sub_title: string,
}
export interface CartData {
  list: Array<Array<CartNum>>
}
// 正面
const Cart: React.FC<CartNum> = (prop) => {
  return (
    <div
      className={`${prop.content === '' ? '' : style.outCart}`}
    >
      {
        !(prop.content === '') &&
        <img src={honor} alt="" className='honorImg' />
      }
      <div className={prop.content === '' ? style.honer : 'cart'}>
        {
          is_Zh&&
          <div className={prop.content === '' ? "all" : 'name'}>{prop.title_zh}</div>
        }
        {
          is_Zh&&
          <div 
            className={
              prop.content === '' ? "honorEg" : "english"
            }
          >{prop.sub_title}</div>
        }
        {
          !is_Zh&&
          <div className='egWord'>
            {prop.sub_title}
          </div>
        }
      </div>
    </div>
  )
}
// 卡片翻转后 
const Back: React.FC<CartNum> = (prop) => {
  return (
    <div className={style.backHeight}>
      {
        !(prop.content === '') &&
        (<div className='backCard'>
          <div
            className="contain"
            dangerouslySetInnerHTML={{ __html: prop.content }}
          >
          </div>
        </div>)
      }
    </div>
  )
}
// 我们的荣誉
const Fc: React.FC<BannerData> = (prop: BannerData) => {
  const [scHeight, setscHeight] = useState<number>(0)
  const [list, setlist] = useState<Array<Array<CartNum>>>([])
  // 给翻转组件传递的卡片尺寸
  const styleBlock = {
    width: '',
    borderRadius: '4px'
  }
  const honorStyle = {
    border: 'none'
  }
  // 返回的数据
  const line = [
    {
      id: 1,
      name: "奢品",
      content: 'Luxuiy',
      cantain: '数据银行认证服务商,阿里巴巴“十佳数据先锋”奖,阿里巴巴数据生态服务大会“2020年度优秀内容数据服务商”天猫&《哈佛商业评论》“2021年数字化先锋案例合作伙伴奖”服务BALENCIAGA，获天猫&《哈佛商业评论》“2021年数字化先锋案例奖”服务6IXTY8IGHT，获品牌数据银行先锋大赛“实践先锋”奖'
    },
    {
      id: 2,
      name: "零售",
      content: 'Luxuiy'
    },
    {
      id: 3,
      name: "直播",
      content: 'Luxuiy'
    },
    {
      id: 4,
      name: "运营",
      content: 'Luxuiy'
    },
    {
      id: 5,
      name: "奢品",
      content: 'Luxuiy'
    },
    {
      id: 6,
      name: "我们的荣誉",
      radio: true,
      content: 'Luxuiy'
    },
    {
      id: 7,
      name: "奢品",
      content: 'Luxuiy'
    },
    {
      id: 8,
      name: "奢品",
      content: 'Luxuiy'
    },
    {
      id: 9,
      name: "奢品",
      content: 'Luxuiy'
    },
    {
      id: 10,
      name: "奢品",
      content: 'Luxuiy'
    }
  ]
  // 处理返回数据
  useEffect(() => {
    let data: Array<Array<CartNum>> = [
      [],
      [],
      [],
      []
    ]
    prop.module_data.forEach((item, index) => {
      data[index % 4].push(item)
    })
    setlist(data)

    document.addEventListener('scroll', compareHeight)
    return () => {
      document.removeEventListener('scroll', compareHeight)
    }
  }, [])

  // 获取距离视图高度
  const myRef = useRef<HTMLElement | null>(null)
  // 获取屏幕宽度
  if (myRef.current) {
    // 动态计算卡片宽度
    let cardHeight = (myRef.current?.clientWidth / 4.69) + 'px'
    styleBlock.width = cardHeight
  }
  //控制卡片移动的高度
  const compareHeight = throttle(() => {
    let offsetTop: number = myRef.current && myRef.current.offsetTop ? myRef.current.offsetTop : 0;
    let clientHeight: number = myRef.current && myRef.current.clientHeight ? myRef.current.clientHeight : 1680;
    let scrollTop: number = window.pageYOffset || document.documentElement.scrollTop ||document.body.scrollTop || 0;
    let height: number = scrollTop - offsetTop
    let changeTrans:number = Math.ceil(80-80*(height/clientHeight))
    if(height>-1000 && changeTrans>0) {
      setscHeight(changeTrans)
    }
  }, 20)
  return (
    <section className={style.OurHonorPc} ref={myRef}>
      {
        list.map((item, index) => {
          return (
            <div
              className={`content${index} content`}
              key={index}
              style={{
                transform: `translateY(${index === 1 || index === 3 ? `${scHeight}px` : '0px'}`
              }}
            >
              {
                item.map((self, key) => {
                  return (
                    <div key={key} className='outBlock'>
                      {
                        self.content === '' &&
                        <CardFilp
                          frontChildren={<Cart  {...self} />}
                          backChildren={<Back  {...self} />}
                          style={styleBlock}
                          frontStyle={honorStyle}
                        ></CardFilp>
                      }
                      {
                        !(self.content === '') &&
                        <CardFilp
                          frontChildren={<Cart  {...self} />}
                          backChildren={<Back  {...self} />}
                          style={styleBlock}
                        ></CardFilp>
                      }
                    </div>
                  )
                })
              }
            </div>
          )
        })
      }
    </section>
  )
}

export default Fc