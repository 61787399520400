import React from 'react'
import CardOpen from '@/components/CommonComponents/pc/CardOpen/CardOpen'
import FilterImage from './FilterImage'
import { NewsModuleData } from '@/ts/api'
import style from './NewsList.module.scss'

// interface DataItem {
//   pc_image_url: string
//   HTML_title: string
//   Display_time: string
//   id: string
//   width_scale: number
// }

interface NewsListProps {
	data: NewsModuleData[]
}

const NewsList: React.FC<NewsListProps> = props => {
	const is_Zh: boolean = localStorage.getItem('store_view') !== 'En'

	const clickEvent = (link: string) => {
		// window.location.href = '/new?id='+link
		window.location.href = link
	}
	return (
		<section className={style.newsList}>
			{props.data.map((item, index) => {
				return (
					<CardOpen
						cardClassName={`card${index}`}
						width={`${item.width_scale * 10}%`}
						defalutOpen={index < 2}
						key={index}>
						<div
							className="imgBox"
							onClick={() => clickEvent(item.ws_url)}
							style={{ cursor: item.id ? 'pointer' : '' }}>
							<FilterImage pc_image_url={item.pc_image_url}></FilterImage>
						</div>
						{(item.HTML_title || item.Display_time) && (
							<div className={`cardFoot ${is_Zh ? 'zh' : 'en'}`}>
								<span
									dangerouslySetInnerHTML={{ __html: item.HTML_title }}></span>
								<span>{item.Display_time}</span>
							</div>
						)}
					</CardOpen>
				)
			})}
		</section>
	)
}

export default NewsList
