import React, { useEffect, useState } from 'react'
import { getCms } from '@/api/apis'
import './index.scss'

const MobPolicy: React.FC = () => {
	const [content, setContent] = useState<string>('')
	useEffect(() => {
		getBQData()
		return () => {
			setContent('')
		}
	}, [])

	function getBQData() {
		getCms({
			params: {
				terminal: 'Ws_Pc',
				content_id: 'Privacy_Policy_and_Legal_Statement',
				param_id: 'Privacy_Policy_and_Legal_Statement'
			}
		}).then((res: any) => {
			if (res.data?.page_data?.length > 0) {
				setContent(res.data?.page_data[0]?.module_data[0]?.pc_layout || '')
			}
		})
	}
	return (
		<div className="policy-contain">
			<div dangerouslySetInnerHTML={{ __html: content }}></div>
		</div>
	)
}

export default MobPolicy
