import React, { useEffect, useState }  from 'react'
import Components from '@/components/PcComponents/cultureMods';
import style from './club.module.scss';
import { getCms } from '@/api/apis';
interface PageData {
  module_id: string
  module_data?: any
  sort:number
}
const list = [
  {
    zh: '激情勤奋',
    cn: 'Passion & Diligence'
  },
  {
    zh: '诚信务实',
    cn: 'Honesty & Pragmatism'
  },
  {
    zh: '简单专注',
    cn: 'Simplicity & Concentration'
  },
  {
    zh: '共创共赢',
    cn: 'Win-Win Cooperation'
  },
  {
    zh: '客户第一',
    cn: 'Customer First'
  },
]

const Join: React.FC = () => {
  const JoinHeader = Components.JoinHeader

  const [caseList, setCaseList] = useState<PageData[]>([]);
  useEffect(() => {
    getCms({
      params: { terminal: 'Ws_Pc', content_id: 'club', param_id: 'club' }
    }).then((res) => {
      if (res) {
        res.data.page_data.map((item:PageData)=>{
          if(item.sort===3){
            item.module_id='Growth'
          }else if(item.sort===6){
            item.module_id='Club'
          }else if(item.sort===7){
            item.module_id='Activity'
          }
          return(
            item.module_id
          )
        })
        setCaseList(res.data.page_data)

      }
    })
  }, []);
  return (
    <div className={style.culture}>
      <JoinHeader />
      {
        caseList.map((item, index) => {
          const Com: any = Components[item['module_id']]
          return Com && item['module_id']==='Sense'?
          <Com key={index} data={item} title={'价值观'} content={list}/>:
          <Com key={index} data={item}/>
        })
      }
    </div>
  )
}

export default Join
