/*
 * @Author: your name
 * @Date: 2022-01-07 10:57:55
 * @LastEditTime: 2022-06-22 18:16:27
 * @LastEditors: ten ten.yan@ibaiqiu.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \bq-official-web\src\locales\zh.js
 */
// 简体中文
const zh = {
	// 头部和底部
	中文: '中文',
	英文: '英文',
	首页: '首页',
	服务: '服务',
	案例: '案例',
	百秋: '百秋',
	新闻: '新闻',
	加入: '加入',
	联系: '联系',
	公司电话: '公司电话',
	联系邮箱: '联系邮箱',
	品牌合作: '品牌合作',
	直播合作: '直播合作',
	媒体合作: '媒体合作',
	媒体合作邮箱: '媒体合作邮箱',
	求职招聘邮箱: '求职招聘邮箱',
	商务项目合作: '商务项目合作',
	营销业务合作: '营销业务合作',
	公司地址: '公司地址',
	公司详细地址: '上海市长宁区协和路1358号B座',
	公司详细地址分行: '上海市长宁区协和路1358号B座',
	联系我们: '联系我们',
	姓名: '姓名',
	电话: '电话',
	邮箱: '邮箱',
	公司名称: '公司名称',
	您的需求: '您的需求',
	提交: '提交',
	必填: '必填！',
	重置: '重置',
	沪公网安备: '沪公网安备 31011402003088号',
	版权: '上海百秋尚美科技服务集团股份有限公司版权所有',
	备案: '沪ICP备14003092号-1 ',
	隐私政策: '隐私政策',
	免责声明: '免责声明',
	知识版权: '知识版权',
	服务商: '服务商',
	了解品牌: '了解品牌',
	品牌视频: '品牌视频',
	// 首页
	查看全部新闻: '查看全部新闻',
	了解更多: '了解更多',
	// 服务
	战略咨询: '战略咨询',
	店铺运营: '店铺运营',
	全域营销: '全域营销',
	创意视觉: '创意视觉',
	全域数据: '全域数据',
	服务范围: '服务范围',
	美妆运营: '美妆运营',
	内容直播: '内容直播',
	IT解决方案: 'IT解决方案',
	仓储物流: '仓储物流',
	视觉创意: '视觉创意',
	全渠道运营: '全渠道运营',
	数智解决方案: '数智解决方案',
	客户服务: '客户服务',
	供应链管理: '供应链管理',
	品牌孵化: '品牌孵化',
	// 案例
	所在行业: '所在行业',
	服务类型: '服务类型',
	排序: '排序:',
	全部: '全部',
	珠宝: '珠宝',
	男装: '男装',
	女装: '女装',
	箱包: '箱包',
	腕表眼镜: '腕表眼镜',
	运动户外: '运动户外',
	内衣: '内衣',
	美妆: '美妆',
	鞋履: '鞋履',
	新零售: '新零售',
	创意工作室: '创意工作室',
	品牌咨询: '品牌咨询',
	创新技术: '创新技术',
	数字营销: '数字营销',
	发布时间: '发布时间',
	访问量: '访问量',
	精选推荐: '精选推荐',
	查看更多: '查看更多',
	// BQ
	未来零售新中间: '未来·零售·新中间',
	语录: '语录',
	集团化发展: '集团化发展',
	// 新闻
	荣誉: '荣誉',
	绩效: '绩效',
	// 加入
	百秋文化: '百秋文化',
	加入百秋: '加入百秋',
	校园招聘: '校园招聘',
	社会招聘: '社会招聘',
	二文化: '“二”文化',
	价值观: '价值观',
	成长晋升: '成长晋升',
	工作环境: '工作环境',
	社团活动: '社团活动',
	员工活动: '员工活动',
	推荐级别: '推荐级别',
	伯乐奖: '伯乐奖（税前）',
	// 联系
	聆听: '聆听您的需求，为您提供量身定制的方案',
	Tel: '联系电话',
	// 搜索
	展开更多: '展开更多',
	未来: '未来',
	零售: '零售',
	新中间: '新中间',
	专注国际时尚领域: '专注国际时尚领域',
	国内领军的服务商: '国内领军的全域数字零售服务商',
	关于百秋的第一段描述:
		'上海百秋尚美科技服务集团股份有限公司，英文名BUY QUICKLY，成立于2010年，是国内领军的聚焦于国际时尚领域的全域数字零售服务商。百秋尚美致力于通过全新的中间商模式，专注构建多渠道全链路一站式的零售运营服务能力，帮助超百家国际知名品牌实现在中国市场多渠道一体化协同运营。',
	关于百秋的第二段描述:
		'百秋尚美以科技和数据智能为引擎，自主建设打造的零售新基建服务链路，涵盖了全平台精细化店铺运营、品牌战略咨询、全域营销、全域数据洞察、消费者生命周期管理、创意视觉、内容直播、数字化解决方案、供应链管理等一系列高效的服务闭环。未来，百秋尚美以“做新商业文明的创行者”为使命，以“未来零售新中间”为愿景，致力于成为品牌最信赖的战略合作伙伴，为品牌和消费者提供更大价值的零售服务。',
	加入方式: '加入方式',
	前程无忧: '前程无忧',
	智联招聘: '智联招聘',
	猎聘: '猎聘',
	在线投递简历: '在线投递简历',
	关注百秋招聘官网微信公众号: '关注“百秋招聘”官网微信公众号',
	了解最新招聘信息: '了解最新招聘信息',
	用人理念: '用人理念',
	附: '附：本制度的最终解释权归属上海百秋人力资源部所有',
	提交成功: '提交成功',
	您已提交成功: '您已提交成功，我们会在5个工作日内联系您',
	返回: '返回',
	秒后自动返回: '秒后自动返回'
}

export default zh
