import React from 'react';
import './SmallBanner.scss'
interface ContentData{
  title_ch:string,
  title_eg:string,
}
// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
//栏目具体模块
const SmallBanner: React.FC <ContentData>= (prop) => {
  
  return (
    <section className='SmallBannerPC'>
      <div className='contentTitle'>
        {
          is_Zh&&
        <div className='title_ch'>{prop.title_ch}</div>
        }
        <div className={`${is_Zh?'title_eg':'title_en'}`}>{prop.title_eg}</div>
      </div>
    </section>
  )
}
SmallBanner.defaultProps={
  title_ch:'战略咨询',
  title_eg:'Strategy consulting',
}
export default SmallBanner