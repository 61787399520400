import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { throttle, isInViewPort } from '@/utils/index'
// import back from '@/assets/img/-s-mob.png'
import style from './Sense.module.scss'

interface senseData {
	title?: string
	list?: {
		zh: string
		cn: string
	}[]
}

// @ts-ignore
const is_Zh: boolean = localStorage.getItem('store_view') !== 'En'
const Sense: React.FC<senseData> = props => {
	const [open, setOpen] = useState<boolean>(false)
	const { t } = useTranslation()
	useEffect(() => {
		const aboutbqEl = document.getElementsByClassName(`${style.sense}`)
		const isinview = isInViewPort(aboutbqEl) // 是否进入了可视区域
		setOpen(isinview)
		const listener = throttle(() => {
			const isinview = isInViewPort(aboutbqEl) // 是否进入了可视区域
			if (isinview !== open) setOpen(isinview)
		}, 50)
		document.addEventListener('scroll', listener)
		return () => {
			document.removeEventListener('scroll', listener)
		}
	}, [])

	return (
		<section className={style.sense}>
			<div
				className={`${open ? 'title_active' : ''} title ${
					!is_Zh ? 'cn' : 'zh'
				}`}>
				{t(props.title || '')}
			</div>
			<ul
				className={['list', props.title == '价值观' ? 'special' : ''].join(
					' '
				)}>
				{is_Zh &&
					props.list &&
					props.list.map((item, index) => {
						return (
							<li
								key={item.zh}
								className={`${open ? 'li_active' : ''}`}
								style={{
									transitionDelay: open ? index * 0.1 + 's' : '0',
									transitionDuration: open ? '.3s' : '0'
								}}>
								<span className="zhl">{item.zh}</span>
								<span className="cnl">{item.cn}</span>
							</li>
						)
					})}
				{!is_Zh &&
					props.list &&
					props.list.map((item, index) => {
						return (
							<li
								key={item.zh}
								className={`${open ? 'li_active' : ''}`}
								style={{
									transitionDelay: open ? index * 0.1 + 's' : '0',
									transitionDuration: open ? '.3s' : '0'
								}}>
								{/* <span className='zh'>{item.zh}</span> */}
								<span className="en" style={{ fontSize: '18px' }}>
									{item.cn}
								</span>
							</li>
						)
					})}
			</ul>
		</section>
	)
}
Sense.defaultProps = {
	title: '价值观',
	list: [
		{
			zh: '激情勤奋',
			cn: 'Passionate diligent'
		},
		{
			zh: '诚信务实',
			cn: 'Honest pragmatic'
		},
		{
			zh: '简单专注',
			cn: 'Simple focus'
		},
		{
			zh: '共创共赢',
			cn: 'Co-creation win-win'
		},
		{
			zh: '客户第一',
			cn: 'Customer first'
		}
	]
}
export default Sense
