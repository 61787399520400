/*
 * @Author: your name
 * @Date: 2021-12-21 17:28:34
 * @LastEditTime: 2022-01-21 15:16:40
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \bq-official-web\src\components\MobComponents\CaseMods\index.ts
 */
import Case from './CaseList/CaseList'
const comp={
  Case,
}
export default comp