import React from 'react'
import Components from '@/components/PcComponents/contactMods';
import style from './Contact.module.scss';

const FC: React.FC = () => {
  const ContactHead = Components.ContactHead
  const ContactForm = Components.ContactForm
  const ContactUs = Components.ContactUs
  const Map = Components.Map
  return (
    <div className={style.Contact}>
      <ContactHead />
      <ContactForm />
      <ContactUs />
      <Map />
    </div>
  )
}

export default FC
