import React, { useEffect, useState } from 'react';
import { throttle, isInViewPort } from '@/utils/index'
import SmallBanner from '../SmallBanner/SmallBanner'
import * as comp from '@/components/CommonComponents/mob/Graphical_detaile/Graphical_detaile'
import { useTranslation } from 'react-i18next';
import './ContentBlock.scss'
// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
interface ContentItem {
  title_zh:string,
  sub_title:string
  shape_type: string,
  content:string
  letter:string,
  letter2:string
}
interface ContentData{
  module_data:ContentItem[]
  long?:boolean
}
//栏目具体模块
const ContentBlock: React.FC<ContentData> = (prop) => {
	const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(true)
  useEffect(() => {
    const aboutbqEl = document.getElementsByClassName('ContentBlockMob')
    const listener = throttle(() => {
      const isinview = isInViewPort(aboutbqEl) // 是否进入了可视区域了可视区域
      setOpen(isinview)
    }, 50)
    document.addEventListener('scroll', listener)
    return () => {
      document.removeEventListener('scroll', listener)
    }
  }, [])
  const Comp: any = comp[prop.module_data[0].shape_type]
  return (
    <section className='ContentBlockMob'>
      <div className='border'></div>
      <div className='content'>
        <div className='topTitle'>
          <div className='topPart' >
            <SmallBanner title_ch={prop.module_data[0].title_zh} title_eg={prop.module_data[0].sub_title}
            long={prop.long}></SmallBanner>
            <div className='outBlock'>
              <div
                className='contentWords'
                style={{ animationName: open ? 'open_height' : '' }}
                dangerouslySetInnerHTML={{__html:prop.module_data[0].content}}
              ></div>
            </div>
          </div>
          {/* <a href="/contact">
            <div className={`contactUs ${is_Zh?'zh':"en"}`}>{t("联系我们")}</div>
          </a> */}
        </div>
        <div className='graph'>
          {
            Comp && <Comp letter={prop.module_data[0].letter} letter2={prop.module_data[0].letter2}/>
          }
        </div>
      </div>
    </section>
  )
}

export default ContentBlock