import React,{useRef} from 'react';
import { RightOutlined,LeftOutlined } from '@ant-design/icons';
import { Carousel } from 'antd';
import style from './Rotation.module.scss'
interface CarouselRef {
  goTo:any
  next:any
  prev:any 
  autoPlay:any 
  innerSlider:any
}

//轮播
const Rotation: React.FC= () => {
  //轮播原点样式
  const dotsName={
    className:'circles'
  }
  // 数据
  const obj=[
    {
      item1:{
        img:'http://www.buyquickly.com/sites/default/files/news/2021-01/2.jpg',
        describe:'共克疫情：上海百秋 “千秋公益行动”精准援助湖北贫困山区教育',
        data:'2020/10/10',
      },
      item2:{
        img:'http://www.buyquickly.com/sites/default/files/news/2021-01/lADPDgfLP2-2k3PNAhLNAyA_800_530.jpg',
        describe:'共克疫情：上海百秋 “千秋公益行动”精准援助湖北贫困山区教育',
        data:'2020/10/10'
      }
    },
    {
      item1:{
        img:'http://www.buyquickly.com/sites/default/files/news/2021-01/2.jpg',
        describe:'共克疫情：上海百秋 “千秋公益行动”精准援助湖北贫困山区教育',
        data:'2020/10/10',
      },
      item2:{
        img:'http://www.buyquickly.com/sites/default/files/news/2021-01/lADPDgfLP2-2k3PNAhLNAyA_800_530.jpg',
        describe:'共克疫情：上海百秋 “千秋公益行动”精准援助湖北贫困山区教育',
        data:'2020/10/10'
      }
    },
  ]
  const carousel = useRef<CarouselRef|null>(null)
  // 切换轮播
  function handleChange(type:string) {
    return (
      carousel.current && carousel.current[type]()
    )
  }
  function handleChangeGo(type:string) {
    return (
      carousel.current && carousel.current[type]()
    )
  }
  return (
    <section className={style.RotationMob}>
      {/* 向左 */}
      <span className='leftButton' onClick={()=>handleChange('prev')}>
        <LeftOutlined 
          style={{fontSize: '17px',color:'#999999'}}
        />
      </span>
      {/* 向右 */}
      <span className='rightButton' onClick={()=>handleChangeGo('next')}>
        <RightOutlined 
          style={{fontSize: '17px',color:'#999999'}}
        />
      </span>
      <Carousel  
        dots={dotsName}
        ref={carousel}
      >
        {
          obj.map((item,index)=>{
            return(
              <div className='blockItem' key={index}>
                {/* 第一个模块 */}
                <div className='item1'>
                  <img src={item.item1.img} alt="" className='imgScial'/>
                  <div className='describe'>{item.item1.describe}</div>
                  <div className='date'>{item.item1.data}</div>
                </div>
                {/* 第二个模块 */}
                <div className='item1'>
                  <img src={item.item2.img} alt="" className='imgScial'/>
                  <div className='describe'>{item.item2.describe}</div>
                  <div className='date'>{item.item2.data}</div>
                </div>
              </div>
            )
          })
        }
      </Carousel >
    </section>
  )
}
export default Rotation