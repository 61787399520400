import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './Reward.module.scss';
// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
// 人才推荐奖励机制

interface LayoutData {
  sub_title: string
  title_zh: string
}
interface LayoutList {
  layout: LayoutData[]
}

const StandardTable: React.FC<LayoutList> = (props) => {
	const { t } = useTranslation()
  return (
    <table cellSpacing='0' className={style.awardTable}>
      <thead>
        <tr>
          <th>
            <span className={`${is_Zh ? 'zh': 'en'}`}>{t('推荐级别')}</span>
          </th>
          {
            props.layout.map((item) => {
              return (
                <th key={item.title_zh + item.sub_title}>{item.title_zh}</th>
              )
            })
          }
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><span className={`${is_Zh ? 'zh': 'en'}`}>{t('伯乐奖')}</span></td>
          {
            props.layout.map((item, index) => {
              return (
                <td key={item.sub_title + index}>
                  {parseInt(item.sub_title)}<span>元</span>
                </td>
              )
            })
          }
        </tr>
      </tbody>
    </table>
  )
}


interface RewardData {
  title_zh: string
  content: string
  layout?: [{
    sub_title: string
    title_zh: string
  }]
}

interface RewardList {
  module_data: RewardData[]
}
const FC: React.FC<RewardList> = (props) => {

  return (
    <section className={style.reward}>
      <header className='header'>
        {
          is_Zh &&
          <span className='zh'>人才推荐奖励机制</span>
        }
        <span className={`${is_Zh ? 'cn' : 'cn_eg'}`}>Talent Recommendation& Rewarding</span>
      </header>
      <ul className='list'>
        {
          props.module_data.map((item, index) => {
            return (
              <li key={index} className='li'>
                <div className='label'>{item.title_zh}</div>
                <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                {
                  item.layout && item.layout.length > 1 && <StandardTable layout={item.layout} />
                }
              </li>
            )
          })
        }
      </ul>
    </section>
  )
}

export default FC
