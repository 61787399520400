import NewsHeader from '../newsMods/NewsHeader/NewsHeader';
import Border from '@/components/CommonComponents/mob/Border/Border';
import Text from '@/components/CommonComponents/mob/Text/Text';
import OneImage from '@/components/CommonComponents/mob/OneImage/OneImage';
import TwoImage from '@/components/CommonComponents/mob/TwoImage/TwoImage';
import RecommendNews from './RecommendNews/RecommendNews'

const comp = {
  // 头部
  NewsHeader,
  //分界线
  Border,
  // 文本组件
  Text,
  // 一张图片
  OneImage,
  // 两张图片
  TwoImage,
  // 推荐新闻组件
  RecommendNews,
}

export default comp
