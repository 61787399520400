import React, { useEffect } from 'react'
import { Carousel } from 'antd'
import objectFitImages from 'object-fit-images'
import VideoJS from '@/components/CommonComponents/mob/Video/Video' // point to where the functional component is stored
import style from './Banner.module.scss'

interface BannerData {
	module_data: banner[]
}
// banner接口类型

interface banner {
	mob_url: string
	pc_url: string
	parent_id: number
	type: string
}
const Banner: React.FC<BannerData> = (prop: BannerData) => {
	// 解决Ie不兼容
	useEffect(() => {
		const img = document.querySelectorAll(`.${style.bqImage}`)
		objectFitImages(img)
	})
	const playerRef = React.useRef(null)
	let options = {
		// lookup the options in the docs for more options
		autoplay: true, // 自动播放
		controls: false, // 隐藏默认控件
		responsive: true,
		fluid: true,
		loop: true,
		muted: true,
		sources: [
			{
				src: '',
				type: 'video/mp4'
			}
		],
		chilldren: ['bigPlayButton']
	}
	const handlePlayerReady = (player: any) => {
		playerRef.current = player
		// you can handle player events here
		player.on('waiting', () => {
			// eslint-disable-next-line no-console
			console.log('player is waiting')
		})

		player.on('dispose', () => {
			// eslint-disable-next-line no-console
			console.log('player will dispose')
		})
	}
	return (
		<div>
			<Carousel autoplay={true} dots={true}>
				{prop.module_data.map((item, index) => {
					item.type === 'video' && (options.sources[0].src = item.mob_url)
					return (
						<div key={index} className={style.bqBlock}>
							{
								item.type === 'video' && (
									<VideoJS
										options={options}
										onReady={handlePlayerReady}
										height={true}
									/>
								)
								// <video
								//   className={style.bqVideo}
								//   src={item.mob_url}
								//   autoPlay
								//   muted
								//   loop
								//   poster="titleUrl"
								//   controlsList='nodownload'
								//   x5-video-player-fullscreen='true'
								//   playsInline
								// ></video>
							}
							{item.type === 'image' && (
								<img className={style.bqImage} src={item.mob_url} alt="" />
							)}
						</div>
					)
				})}
			</Carousel>
		</div>
	)
}

export default Banner
