import React from 'react';
import style from './Input.module.scss';
import { Input } from 'antd';

interface InputProps {
  label?: string
  newname?: string
}

const FC:React.FC<InputProps> = (props) => {
  return (
    <div className={`${props.newname} ${style.bq_input}`}>
      <label className='label'>
        {props.label}
      </label>
      <Input {...props}/>
    </div>
  )
}

export default FC
