import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { throttle, isInViewPort } from '@/utils/index';
import ClubLife from './ClubLife/ClubLife';
import ClubList from './ClubList/ClubList';
import style from './Club.module.scss';
// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
// 社团活动
interface ClubData{
  data:{
    module_data:{
      title_zh: string
      sub_title: string
      mob_image_url: string
      content: string
      layout:{
        pc_image_url:string
      }[]
    }[]
  }
}
const FC: React.FC<ClubData> = (prop) => {
  const [open, setOpen] = useState<boolean>(false)
	const { t } = useTranslation()
  
  useEffect(() => {
    const aboutbqEl = document.getElementsByClassName(`${style.club}`)

    const listener = throttle(() => {
      const isinview = isInViewPort(aboutbqEl, 200) // 是否进入了可视区域
      setOpen(isinview)
    }, 50)
    document.addEventListener('scroll', listener)
    return () => {
      document.removeEventListener('scroll', listener)
    }
  }, [])
  return (
    <section className={style.club}>
      <header className={`title ${!is_Zh?'cn':'zh'}`} style={{animationName: open ? style.title_open : style.title_close}}>
        {t('社团活动')}
      </header>
      <ClubLife />
      <ClubList {...prop}/>
    </section>
  )
}

export default FC

