import ContactHead from './ContactHead/ContactHead';
import ContactForm from './ContactForm/ContactForm';
import ContactUs from './ContactUs/ContactUs';
import Map from './Map/Map';
import Border from '@/components/CommonComponents/mob/Border/Border'
const comp={
  ContactHead,
  ContactForm,
  ContactUs,
  Map,
  Border
}
export default comp