import React from 'react'
import { Carousel } from 'antd'
// import objectFitImages from 'object-fit-images'
import style from './Banner.module.scss'
interface BannerData {
	module_data: banner[]
}
// banner接口类型

interface banner {
	mob_url: string
	pc_url: string
	parent_id: number
	type: string
}
const Fc: React.FC<BannerData> = (props: BannerData) => {
	// useEffect(() => {
	//   const img = document.querySelectorAll(`.${style.bqImage}`)
	//   objectFitImages(img)
	// })

	return (
		<section>
			<Carousel autoplay={true} dots={true}>
				{props.module_data.map((item, index) => {
					return (
						<div key={index}>
							{item.type === 'video' && (
								<video
									className={style.bqVideo}
									src={item.pc_url}
									autoPlay
									muted
									loop></video>
							)}
							{item.type === 'image' && (
								<img className={style.bqImage} src={item.pc_url} alt="" />
							)}
						</div>
					)
				})}
			</Carousel>
		</section>
	)
}

export default Fc
