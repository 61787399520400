import TopBanner from '../../serverMods/ServiceDetaile/TopBanner/TopBanner';
import React from 'react';
// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
const NewsHeader: React.FC = () => {
  return (
    <div>
      <TopBanner titleChinese='加入' titleEnglish='Join Us' />
    </div>
  )
}

export default NewsHeader