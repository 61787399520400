import ContactHead from './ContactHead/ContactHead';
import ContactForm from './ContactForm/ContactForm';
import ContactUs from './ContactUs/ContactUs';
import Map from './Map/Map';
const comp={
  ContactHead,
  ContactForm,
  ContactUs,
  Map
}
export default comp