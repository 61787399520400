import React,{useEffect,useState} from 'react';
import time from '@/assets/img/time.png'
import eye from '@/assets/img/eye.png'
import './TopBanner.scss'
import {setpPgeViewList} from '@/api/apis'
import { getQuery } from '@/utils/index'

interface Module_Data {
  img: string
  HTML_title: string
  mob_image_url: string
  service_type: { [key: string]: string }
  Display_time: string
  pc_image_url: string
}
interface BannerData {
  module_data: Module_Data[]
}
interface idData{
  id?:string
}
//案例详情头部
const TopBanner: React.FC<BannerData> = (prop) => {
  const [nums,setNum]=useState<number>(0)
  useEffect(()=>{
    const id:idData= getQuery(window.location.search)
    setpPgeViewList({
      data:{id:Number(id.id)}
    }).then((res)=>{
      const num=Object.values(res.data)
      if(num){
        setNum(Number(num[0]))
      }
    })
  })
  return (
    <section className='TopBannerPC'>
      <div className='topInfor'>
        <div className='slogan' dangerouslySetInnerHTML={{ __html: prop.module_data[0].HTML_title }}></div>
        {
          prop.module_data[0].img&&
          <img src={prop.module_data[0].img} alt="" />
        }
      </div>
      <div className='bottomInfor'>
        <div className='label'>
          {
            Object.values(prop.module_data[0].service_type).map((item, index) => {
              return (
                <div className='labelItem' key={item + index}>{item}</div>
              )
            })
          }
        </div>
        <div className='dataInfom'>
          <div className='num'>
            <img src={eye} alt="" className='icon' />
            {nums}
          </div>
          {/* <div className='num'>
            <img src={time} alt="" className='icon' />
            {prop.module_data[0].Display_time}
          </div> */}
        </div>
      </div>
      <div className="CaseBanner">
        {
          prop.module_data[0].pc_image_url&&
          <img src={prop.module_data[0].pc_image_url} alt="" />
        }
      </div>
    </section>
  )
}

export default TopBanner