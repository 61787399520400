import React,{useState,useEffect} from 'react'
import Components from '@/components/PcComponents/serverMods/ServiceDetaile';
import { getCms } from '@/api/apis';

// 全域数据
const DigitalIntelligenceSolutions: React.FC = () => {
  interface comTtem {
    module_id:string
    module_data: []
    module_param: []
    module_remark:string
    module_title:string
    parent_id:string
    sort:number
  }
  const ServiceDetaileData = [
    {
      module_id: 'TopBanner',
      data: null
    },
    {
      module_id: 'Column',
      data: null
    },
    {
      module_id: 'ContentBlock',
      data: {
        title: {
          title_ch: '客户服务',
          title_eg: 'Customer service',
        },
        comp: 'SquareBoth',
        brief: '客户服务为基础准则，客户销售为结果导向，双方结合共同促成品牌完成业绩目标。',
        content: '百秋一直坚持运营以客服为中心，客服以客户为中心的服务理念，提升客户体验，用情感故事维系给消费者传递品牌理念和服务；区别于其他TP公司，公司定位在时尚、奢品、轻奢领域，坚持客服团队全员设置在上海总部，不仅能更好地收纳新人才，还能从大环境上了解客户第一手时尚走势，以此提供更个性化的服务，满足用户更多需求，不断提升客户体验。除此之外，百秋还一直坚持运营和客服为一个团队，客服在接受到消费者反馈的第一时间直接传送给各个部门，提高解决问题的效率。',
      }
    },
    {
      module_id: 'Goodness',
      data: {
        title_alive: true,
        obj: [
          {
            id: 1,
            nameLabel: '百秋客服团队独创奢品团队，收纳各方人才，通过层层考核，进行奢品体验官认证，认证成功方可服务奢于奢品品牌；',
          },
          {
            id: 2,
            nameLabel: '设置视频客服用来传递品牌理念和服务精神，做到比品牌更懂客户，比客户更懂品牌',
          },
        ]
      }
    },
    {
      module_id: 'ServiceScope',
      data: {
        obj: [
          {
            id: 1,
            title: '百秋提供全渠道客户服务',
            smallTitle: '不仅仅在天猫有专业的服务领域，还在京东、官网、小程序等渠道提供一体化解决方案，并通过全渠道系统实现将所有渠道打通。'
          },
        ]
      }
    },
    {
      module_id: 'Honorar',
      data: {
        obj: [
          {
            id: 1,
            time: 2021,
            content: `
              <p>百秋携手所服务时尚
              奢侈品品牌获得2021
              年数字化先锋案例合作
              伙伴奖<p>
            `,
          },
          {
            id: 2,
            time: 2020,
            content: `
              <p>2021年数字化先锋
              案例奖，成为天猫六星
              梯队中唯一斩获数字化
              先锋案例奖的奢品品牌
              服务商<p>
            `,
          },
        ]
      }
    },
    {
      module_id: 'BusinessCase',
      data: {}
    }
  ]
  const [data,setData]=useState<Array<comTtem>>([])
  useEffect(() => {
    getCms({
      params: { terminal: 'Ws_Pc', content_id: 'Global_data', param_id: 'Global_data' }
    }).then((res: any) => {
    if(res){
      setData(res.data.page_data)
    }
  })
    return () => {
      setData([])
    }
  },[])
  return (
    <div>
      {
        data.map((item, index) => {
          const ServiceDetaile: any = Components[item['module_id']]
          return ServiceDetaile && <ServiceDetaile key={index} {...item} />
        })
      }
    </div>
  )
}

export default DigitalIntelligenceSolutions
