import React, { useEffect,useState ,useRef} from 'react';
import { throttle, isInViewPort } from '@/utils/index';
// import objectFitImages from 'object-fit-images';
import { useSpring,animated } from '@react-spring/web';
import {TitlesType} from  '@/ts/api'
import { useTranslation } from 'react-i18next';
import './Service.scss'
// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
interface BannerData{
  module_param:TitlesType
}
// 接收参数的数据类型
interface classifica{
  card:string
}
// 我们的服务
const Advantage: React.FC<BannerData> = (prop:BannerData) => {
	const { t } = useTranslation()

  // 数据
  const contant:classifica[]=[
    { 
      card:`
        <p>策略咨询与品牌营销<br/></p>
        <p>进口和规范服务<br/></p>
        <p>全盘库存解决方案<br/></p>
        <p>订单管理系统（OMS）<br/></p>
        <p>商铺运营<br/></p>
        <p>客服团队<br/></p>
        <p>商品生命周期策划<br/></p>
        <p>防伪解决方案<br/></p>
        <p>数字安全与数据支持<br/></p>
        <p>保税仓、区域高标仓城市前置仓联动布局<br/></p>
      `
    },
    {
      card:`
        <p>策略咨询与品牌营销<br/></p>
        <p>进口和规范服务<br/></p>
        <p>全盘库存解决方案<br/></p>
        <p>订单管理系统（OMS）<br/></p>
        <p>商铺运营<br/></p>
        <p>客服团队<br/></p>
        <p>商品生命周期策划<br/></p>
        <p>防伪解决方案<br/></p>
        <p>数字安全与数据支持<br/></p>
        <p>保税仓、区域高标仓城市前置仓联动布局<br/></p>
      `
    },
    {
      card:`
        <p>策略咨询与品牌营销<br/></p>
        <p>进口和规范服务<br/></p>
        <p>全盘库存解决方案<br/></p>
        <p>订单管理系统（OMS）<br/></p>
        <p>商铺运营<br/></p>
        <p>客服团队<br/></p>
        <p>商品生命周期策划<br/></p>
        <p>防伪解决方案<br/></p>
        <p>数字安全与数据支持<br/></p>
        <p>保税仓、区域高标仓城市前置仓联动布局<br/></p>
      `
    },
    {
      card:`
        <p>策略咨询与品牌营销<br/></p>
        <p>进口和规范服务<br/></p>
        <p>全盘库存解决方案<br/></p>
        <p>订单管理系统（OMS）<br/></p>
        <p>商铺运营<br/></p>
        <p>客服团队<br/></p>
        <p>商品生命周期策划<br/></p>
        <p>防伪解决方案<br/></p>
        <p>数字安全与数据支持<br/></p>
        <p>保税仓、区域高标仓城市前置仓联动布局<br/></p>
      `
    },
    {
      card:`
        <p>策略咨询与品牌营销<br/></p>
        <p>进口和规范服务<br/></p>
        <p>全盘库存解决方案<br/></p>
        <p>订单管理系统（OMS）<br/></p>
        <p>商铺运营<br/></p>
        <p>客服团队<br/></p>
        <p>商品生命周期策划<br/></p>
        <p>防伪解决方案<br/></p>
        <p>数字安全与数据支持<br/></p>
        <p>保税仓、区域高标仓城市前置仓联动布局<br/></p>
      `
    },
    {
      card:`
        <p>策略咨询与品牌营销<br/></p>
        <p>进口和规范服务<br/></p>
        <p>全盘库存解决方案<br/></p>
        <p>订单管理系统（OMS）<br/></p>
        <p>商铺运营<br/></p>
        <p>客服团队<br/></p>
        <p>商品生命周期策划<br/></p>
        <p>防伪解决方案<br/></p>
        <p>数字安全与数据支持<br/></p>
        <p>保税仓、区域高标仓城市前置仓联动布局<br/></p>
      `
    }
  ]
  // 标题
  const [title,setTitle]=useSpring(()=>({
      opacity:'0',config:{duration:400}
  }))
  const [border,setBorder]=useSpring(()=>({
    width:'0',comfig:{duration:400}
  }))
  useEffect(() => {
    const service=document.getElementsByClassName('ServicePc')
    const ball:any=document.getElementsByClassName('ball')
    const listener = throttle(() => {
      const isinview = isInViewPort(service) // 是否进入了可视区域
      if(isinview){
        setTitle.start({opacity: isinview ? '1':'0'})
        setBorder.start({ width: isinview ? '100%':'0'})
        ball[0].play()
      }else {
        ball[0].load()
        ball[0].pause()
      }
    }, 50)
    document.addEventListener('scroll', listener)
    return ()=>{
      document.removeEventListener('scroll', listener)
    }
  }, [setTitle,setBorder])
  //获取鼠标距离
  const [changeX,setChangeX]=useState<Number>(0)
  //鼠标进入后的初始距离
  const enterNum=useRef<Number>(0)
  // 监听鼠标的移动
  const moveCard=(event:any)=>{
    setChangeX(event.clientX-Number(enterNum.current))
  }
  const handleEnder=(event:any)=>{
    enterNum.current=event.clientX
  }
  const handleLeave=(event:any)=>{
    setChangeX(0)
  }
  return (
    <section className='ServicePc'>
      {/* 标题 */}
      <h2 className='h2'>
        {
          is_Zh&&
          <animated.span className="brandTitle" style={title}>
          {prop.module_param.title_zh}
          </animated.span>
        }
        <animated.span className="brand" style={title}>{prop.module_param.sub_title}</animated.span>
      </h2>
      <animated.div className="border" style={border}></animated.div>
      {/* 视频 */}
      <div className="player" >
        <video 
          src={require('@/assets/video/serve.mp4').default} 
          className='ball'
          muted
          loop
        >
        </video>
        {
          contant.map((item,index)=>{
            return(
              <div  key={index} className={`card card${index}`}
              >
                  <div 
                    className={`pos pos${index}`} 
                    onMouseMove={moveCard}
                    onMouseEnter={handleEnder}
                    onMouseLeave={handleLeave}>
                  </div>
                  <div  
                    style={{transform:`translateX(${`${changeX}px`}`,   }} 
                    className='inside'>
                    <div 
                      className='contain'
                      dangerouslySetInnerHTML={{__html:item.card}}
                    >
                    </div>
                  </div>
                  
              </div>
            )
          })
        }
      </div>
      {/* 按钮 */}
      <div className='more'>
        <a href='/services' className={`knowMore ${is_Zh?'zh':'cn'}`}>
        {t("了解更多")}
        </a>
      </div>
    </section>
  )
}

export default Advantage