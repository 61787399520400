import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { throttle, isInViewPort } from '@/utils/index'
import style from './Sense.module.scss'
// @ts-ignore
const is_Zh: boolean = localStorage.getItem('store_view') !== 'En'
interface waveData {
	title: string
	content: {
		zh: string
		cn: string
	}[]
}
const FC: React.FC<waveData> = props => {
	const [open, setOpen] = useState<boolean>(false)
	const { t } = useTranslation()

	useEffect(() => {
		const aboutbqEl = document.getElementsByClassName(`${style.sense}`)
		const isinview = isInViewPort(aboutbqEl) // 是否进入了可视区域
		setOpen(isinview)
		const listener = throttle(() => {
			const isinview = isInViewPort(aboutbqEl) // 是否进入了可视区域
			if (isinview !== open) setOpen(isinview)
		}, 50)
		document.addEventListener('scroll', listener)
		return () => {
			document.removeEventListener('scroll', listener)
		}
	}, [])

	return (
		<section className={style.sense}>
			<div
				className={`${open ? 'title_active' : ''} title ${
					is_Zh ? 'zh' : 'cn'
				}`}>
				{t(props.title)}
			</div>
			<ul className="list">
				{is_Zh &&
					props.content.map((item, index) => {
						return (
							<li
								key={item.zh}
								className={`${open ? 'li_active' : ''}`}
								style={{
									transitionDelay: open ? index * 0.1 + 's' : '0',
									transitionDuration: open ? '.3s' : '0'
								}}>
								<span className="zh">{item.zh}</span>
								<span className="cn">{item.cn}</span>
							</li>
						)
					})}
				{!is_Zh &&
					props.content.map((item, index) => {
						return (
							<li
								key={item.zh}
								className={`${open ? 'li_active' : ''}`}
								style={{
									transitionDelay: open ? index * 0.1 + 's' : '0',
									transitionDuration: open ? '.3s' : '0'
								}}>
								{/* <span className='zh'>{item.zh}</span> */}
								<span className="en" style={{ fontSize: '28px' }}>
									{item.cn}
								</span>
							</li>
						)
					})}
			</ul>
		</section>
	)
}

export default FC
