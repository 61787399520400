import React, { useState, useEffect } from 'react'
import PageBq from '../PageBq/PageBq'
import Title from '@/components/MobComponents/BQMods/TitleHor/TitleHor'
import style from './HonoraryQuali.module.scss'

interface dataNum {
	title_zh: string
	content: any
	mob_image_url: string
	openTime: number // 开启的年份
	index: number // 第几个
	openIndex: number // 开启的第几个， 用来解决最左边的边框问题
	length: number // 数据的长度，用来解决选中最后一个时，倒数第二个没有边框的问题
}

// 时间块组件
const BlockItem: React.FC<dataNum> = prop => {
	let isShowLeftBorder = false
	// Math.abs(Number(prop.openIndex)-Number(prop.index))<=1 && prop.index!==0
	if (prop.openIndex === 0) {
		isShowLeftBorder = true
	} else if (
		prop.openIndex === prop.length - 1 &&
		prop.index === prop.length - 2
	) {
		isShowLeftBorder = true
	} else if (Number(prop.index) - Number(prop.openIndex) >= 0) {
		isShowLeftBorder = true
	}
	return (
		<div
			className="timeBlock"
			style={{
				width: prop.openTime === Number(prop.title_zh) ? '80vw' : '10vw',
				borderLeftColor: isShowLeftBorder ? '#fff' : '#000'
			}}>
			<div
				className={`blockOne ${
					prop.openTime === Number(prop.title_zh) ? 'openblockOne' : ''
				}`}>
				{prop.title_zh}
			</div>
			<div style={{ width: '100%', height: '100px' }}></div>
			{/* 展开的内容 */}
			<div
				className="showOpen"
				style={{
					display: prop.openTime === Number(prop.title_zh) ? 'block' : 'none'
				}}>
				<div
					className="detaile"
					dangerouslySetInnerHTML={{ __html: prop.content }}></div>
				{prop.mob_image_url && (
					<img src={prop.mob_image_url} alt="" className="imgItem" />
				)}
			</div>
		</div>
	)
}
interface HonorData {
	title_zh: string
	mob_image_url: string
	content: string
}

interface HonorList {
	module_data: HonorData[]
	module_param: {
		title_zh: string
		sub_title: string
	}
}
//荣誉资质
const HonoraryQuali: React.FC<HonorList> = prop => {
	// 判断展开哪个时间段
	const [openTime, setopenTime] = useState<number>(
		Number(prop.module_data[prop.module_data.length - 2].title_zh)
	)
	// 判断展开第几个
	const [openIndex, setopenIndex] = useState<number>(0)
	// 移动距离
	const [translateX, settranslateX] = useState<number | string>(0)
	// 下方时间
	const [timePage, setTimePage] = useState<number[]>([])

	useEffect(() => {
		let timePageCopy: number[] = []
		if (prop.module_data.length > 0) {
			prop.module_data.forEach(item => {
				timePageCopy.push(Number(item.title_zh))
			})
			setTimePage(timePageCopy)
			changeTime(Number(prop.module_data[prop.module_data.length - 2].title_zh))
		}
	}, [])

	// 点击时间回调
	function changeTime(time: number) {
		let index = prop.module_data.findIndex(
			item => Number(item.title_zh) === time
		)
		setopenTime(time)
		setopenIndex(index)
		if (index <= 1) {
			settranslateX(0)
		} else if (index === prop.module_data.length - 1) {
			settranslateX(-(index - 2) * 10 + 'vw')
		} else {
			settranslateX(-(index - 1) * 10 + 'vw')
		}
	}
	// 标题
	const title = {
		TitleHor_ch: prop.module_param.title_zh,
		TitleHor_eg: prop.module_param.sub_title
	}
	return (
		<section className={style.HonoraryQualiMob}>
			<Title {...title} other_title={prop.module_param.sub_title} />
			<div className="honorBox">
				<div
					className="animateBox"
					style={{
						transform: `translateX(${translateX})`
					}}>
					{prop.module_data.map((item, index) => {
						return (
							<div
								className="outside"
								onClick={() => {
									changeTime(Number(item.title_zh))
								}}
								key={index}>
								<BlockItem
									{...item}
									openTime={openTime}
									index={index}
									openIndex={openIndex}
									length={prop.module_data.length}
								/>
							</div>
						)
					})}
				</div>
			</div>
			<PageBq
				timePage={timePage}
				changeHander={changeTime}
				openTime={openTime}
			/>
		</section>
	)
}

export default HonoraryQuali
