import React, { useEffect, useState } from 'react'
import Components from '@/components/MobComponents/BQMods';
import { getCms } from '@/api/apis';

const MobBQ: React.FC = () => {
  interface comTtem {
    module_id: string,
    module_data?: any
  }
  const [BQData, setBQData] = useState<Array<comTtem>>([])
  useEffect(() => {
    getBQData()
    return () => {
      setBQData([])
    }
  }, [])
  function getBQData() {
    getCms({
      params: { terminal: 'Ws_Mob', content_id: 'about', param_id: 'about' }
    }).then((res: any) => {
      if (res) {
        setBQData([...res.data.page_data])
      }
    })
  }
  return (
    <>
      {
        BQData.map((item, index) => {
          const BQ: any = Components[item['module_id']]
          if(item.module_id === "DevelopHistory") {
            item.module_data.reverse()
          }
          return BQ && <BQ key={index} {...item}/>
        })
      }
    </>
  )
}

export default MobBQ
