import TopPhoto from './TopPhoto/TopPhoto'
import Title from './Title/Title'
// import BqTarget from './BqTarget/BqTarget'
import Present from './Present/Present'
import VisionMission from './VisionMission/VisionMission'
import ChairmanMessage from './LeaderTalk/LeaderTalk'
import Leader from './Leader/Leader'
import HonoraryQuali from './HonoraryQuali/HonoraryQuali'
import OverseasUnder from './OverseasUnder/OverseasUnder'
import SocialRespons from './SocialRespons/SocialRespons'
import Rotation from './Rotation/Rotation'
import DevelopHistory from './DevelopHistory/DevelopHistory'
const comp = {
  Banner: TopPhoto,
  Title,
  ChairmanMessage,
  Present,
  VisionMission,
  Leader,
  HonoraryQuali,
  OverseasUnder,
  SocialRespons,
  Rotation,
  DevelopHistory,
}
export default comp