import React, { useState, useEffect, useRef } from 'react';
import JoinForm from '../JoinForm/JoinForm';
import JobList from '../JobList/JobList';
import Pages from '@/components/CommonComponents/mob/Pages/Pages'
import { getQuery } from '../../../../utils/index.js'
interface JobData {
  title_zh: string // 岗位名称
  Job_requirements: string // 岗位要求
  Job_responsibilities: string // 岗位职责
}

interface RecruitData {
  title_zh: string // 招聘类型中文名
  children: JobData[]
}

interface RecruitList {
  module_data: RecruitData[]
}
interface recruType {
  type?: string
}
const Recruit: React.FC<RecruitList> = (prop) => {
  const pagepath = window.location.search
  const query: recruType = getQuery(pagepath)
  const type = query.type ? query.type : 0
  const recruitList = useRef<RecruitData[]>([]) // 所有招聘信息
  const [joinData, setJoinData] = useState<RecruitData>() // 当前选择的招聘类型
  const jobListBak = useRef<JobData[]>() // 所有职位备份
  // const searchKey = useRef('')
  const [jobList, setJobList] = useState<JobData[]>() // 要展示的职位
  const [total, setTotal] = useState(0) //总条数
  // const pageSize = useRef(0) // 每页条数
  const [pageSize,setPageSize]=useState(5)// 每页条数
  const [current, setCurrent] = useState(0) // 当前页面
  const [sliceData,setSliceData]=useState<JobData[]>()
  useEffect(() => {
    if (prop.module_data.length) {
      recruitList.current = prop.module_data
      setJoinData(recruitList.current[Number(type)])
      jobListBak.current = recruitList.current[Number(type)].children
      setJobList(jobListBak.current.slice(0,pageSize))
      setTotal(jobListBak.current.length)
    }
  }, [prop.module_data])
  /**
   * @description: 招聘类型改变处理
   * @param {number} index 招聘类型的 index
   */
  function onChangeType(index: number) {
    setJoinData(recruitList.current[index])
    jobListBak.current = recruitList.current[index].children
    setJobList(jobListBak.current)
    setTotal(jobListBak.current.length)
  }

  /**
   * @description: 搜索职位
   * @param {string} job 搜索职位名称
   */
  function onSearchJob(job: string) {
    if (jobListBak.current) {
      let list = filterData(jobListBak.current, job, ['title_zh'])
      setSliceData(list)
      setTotal(list.length)
      let listOne=list.slice(0,pageSize)
      setJobList(listOne)
    }
  }

  /**
   * @description: 筛选符合条件的数据
   * @param {T} data 需要筛选的数据
   * @param {string} condition 筛选的条件
   * @param {string} filed 条件字段组
   * @return {T} 符合条件的数据
   */
  function filterData<T>(data: T[], condition: string, filed: string[]) {
    let reg = new RegExp(condition)
    let res = data.filter((dataitem, index) => {
      let flag = false
      for (let i = 0; i < filed.length; i++) {
        flag = reg.test(dataitem[filed[i]])
        if (flag === false) return flag
      }
      return flag
    })
    return res
  }

  /**
   * @description: 切换分页，截取相应的数据
   * @param {number} page 分页的当前页
   */
  function changePage(page: number) {
    let start = (page-1) * pageSize
    let end = start + pageSize
    if(sliceData){
      let list = sliceData.slice(start, end)
      setJobList(list)
    }else if (jobListBak.current) {
      let list = jobListBak.current.slice(start, end)
      setJobList(list)
    }
  }
  return (
    <>
      <JoinForm {...joinData} type={Number(type)} onSearchJob={onSearchJob} />
      <JobList jobList={jobList} />
      <Pages totle={total} defaultPageSize={pageSize} current={current} pages={changePage}/>
    </>
  )
}

export default Recruit