
import style from './Graphical_detaile.module.scss'


// 圆形
export const circular= (prop:any) => {
  return(
    <div className={style.circular1}>
      <div className='round'>
        <div className='text1 fontSize'>{prop.letter}</div>
        <div className='boxCircle'>
          <div className='text fontSize'>{prop.letter2}</div>
        </div>
      </div>
    </div>
  )
}
// 正方形
export const square = (prop:any) => {
  return(
    <div className={style.squareBox1}>
      <div 
        className='cube'
        style={{
          height:prop.height,
          width:prop.width,
          transform:prop.rotate
        }}
      >
        <div className='text fontSize'>
          <div className='egOne'>{prop.letter}</div>
        </div>
        <div className='text1 fontSize'>{prop.letter2}</div>
      </div>
    </div>
  )
}
// 菱形
export const squareRotate = (prop:any) => {
  return(
    <div className={style.rhomb}>
      <div 
        className='lozenge'
        style={{
          height:prop.height,
          width:prop.width,
          transform:prop.rotate
        }}
      >
        <div className='text fontSize'>{prop.letter}</div>
        <div className='text1 fontSize'>{prop.letter2}</div>
      </div>
    </div>
  )
}
//三角形
export const triangleLeft = (prop:any) => {
  return(
    <div className={style.square1}>
      <div className='leftGrap'>
        <div className='line'></div>
        <div className='text fontSize'>{prop.letter}</div>
        <div className='text1 fontSize'>
          <div className='egOne'>{prop.letter2}</div>
        </div>
      </div>
    </div>
  )
}
//翻转三角形
export const  triangleRight = (prop:any) => {
  return(
    <div className={style.squareFlip}>
      <div className='leftGrapFlip'>
        <div className='line'></div>
        <div className='oneLetter'>
          <div className='text fontSize'>{prop.letter}</div>
        </div>
        <div className='text1 fontSize'>
          <div className='egOne'>{prop.letter2}</div>
        </div>
      </div>
    </div>
  )
}
//正六边形
export const hexagon = (prop:any) => {
  return(
    <div className={style.shape1}>
      <div className='text'>
        <div className='egOne'>{prop.letter}</div>
      </div>
      <div className='text1'>
        <div className='egTwo'>{prop.letter2}</div>
      </div>
      <div className='topGrap'>
        <div className='one'></div>
        <div className='two'></div>
        <div className='three'></div>
      </div>
      <div className='bottomLine1'></div>
    </div>
  )
}
//长方形
export const squareBoth = (prop:any) => {
  return(
    <div className={style.oblong1}>
      <div className='rectangle1'>
        <div className='text fontSize'>{prop.letter}</div>
        <div className='text1 fontSize'>{prop.letter2}</div>
      </div>
    </div>
  )
}

