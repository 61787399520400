import React from 'react';
import './CaseLight.scss'
// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
interface dataInform{
  sub_title:string,
  title_zh:string
}
//案例亮点
const CaseLight: React.FC<dataInform> = (prop) => {
  return (
    <section className='CaseLightPC'>
      <h2 className="h2">
        {
          is_Zh&&
          <div className="caseTitle">{prop.title_zh}</div>
        }
        <div className="ch">{prop.sub_title}</div>
      </h2>
      <div className="border"></div>
    </section>
  )
}
CaseLight.defaultProps={
  title_zh:'案件亮点',
  sub_title:"Cases Highlight"
}

export default CaseLight