import React, { useEffect, useRef, useState } from 'react'
import { throttle, isInViewPort } from '@/utils/index'
// import objectFitImages from 'object-fit-images';
import { useSpring, animated } from '@react-spring/web'
import { TitlesType } from '@/ts/api'
import { useTranslation } from 'react-i18next'
import VideoJS from '@/components/CommonComponents/mob/Video/Video' // point to where the functional component is stored
import './Service.scss'
// 接收参数的数据类型
// @ts-ignore
const is_Zh: boolean = localStorage.getItem('store_view') !== 'En'
interface classifica {
	card: string
}
interface BannerData {
	module_param: TitlesType
}
// 我们的服务
const Advantage: React.FC<BannerData> = (prop: BannerData) => {
	const { t } = useTranslation()

	// 数据
	const contant: classifica[] = [
		{
			card: `
        <p>策略咨询与品牌营销<br/></p>
        <p>进口和规范服务<br/></p>
        <p>全盘库存解决方案<br/></p>
        <p>订单管理系统（OMS）<br/></p>
        <p>商铺运营<br/></p>
        <p>客服团队<br/></p>
        <p>商品生命周期策划<br/></p>
        <p>防伪解决方案<br/></p>
        <p>数字安全与数据支持<br/></p>
        <p>保税仓、区域高标仓城市前置仓联动布局<br/></p>
      `
		},
		{
			card: `
        <p>策略咨询与品牌营销<br/></p>
        <p>进口和规范服务<br/></p>
        <p>全盘库存解决方案<br/></p>
        <p>订单管理系统（OMS）<br/></p>
        <p>商铺运营<br/></p>
        <p>客服团队<br/></p>
        <p>商品生命周期策划<br/></p>
        <p>防伪解决方案<br/></p>
        <p>数字安全与数据支持<br/></p>
        <p>保税仓、区域高标仓城市前置仓联动布局<br/></p>
      `
		},
		{
			card: `
        <p>策略咨询与品牌营销<br/></p>
        <p>进口和规范服务<br/></p>
        <p>全盘库存解决方案<br/></p>
        <p>订单管理系统（OMS）<br/></p>
        <p>商铺运营<br/></p>
        <p>客服团队<br/></p>
        <p>商品生命周期策划<br/></p>
        <p>防伪解决方案<br/></p>
        <p>数字安全与数据支持<br/></p>
        <p>保税仓、区域高标仓城市前置仓联动布局<br/></p>
      `
		},
		{
			card: `
        <p>策略咨询与品牌营销<br/></p>
        <p>进口和规范服务<br/></p>
        <p>全盘库存解决方案<br/></p>
        <p>订单管理系统（OMS）<br/></p>
        <p>商铺运营<br/></p>
        <p>客服团队<br/></p>
        <p>商品生命周期策划<br/></p>
        <p>防伪解决方案<br/></p>
        <p>数字安全与数据支持<br/></p>
        <p>保税仓、区域高标仓城市前置仓联动布局<br/></p>
      `
		},
		{
			card: `
        <p>策略咨询与品牌营销<br/></p>
        <p>进口和规范服务<br/></p>
        <p>全盘库存解决方案<br/></p>
        <p>订单管理系统（OMS）<br/></p>
        <p>商铺运营<br/></p>
        <p>客服团队<br/></p>
        <p>商品生命周期策划<br/></p>
        <p>防伪解决方案<br/></p>
        <p>数字安全与数据支持<br/></p>
        <p>保税仓、区域高标仓城市前置仓联动布局<br/></p>
      `
		},
		{
			card: `
        <p>策略咨询与品牌营销<br/></p>
        <p>进口和规范服务<br/></p>
        <p>全盘库存解决方案<br/></p>
        <p>订单管理系统（OMS）<br/></p>
        <p>商铺运营<br/></p>
        <p>客服团队<br/></p>
        <p>商品生命周期策划<br/></p>
        <p>防伪解决方案<br/></p>
        <p>数字安全与数据支持<br/></p>
        <p>保税仓、区域高标仓城市前置仓联动布局<br/></p>
      `
		}
	]
	const [title, setTitle] = useSpring(() => ({
		opacity: '0',
		config: { duration: 400 }
	}))
	const [border, setBorder] = useSpring(() => ({
		width: '0',
		comfig: { duration: 400 }
	}))
	let options = {
		// lookup the options in the docs for more options
		autoplay: true, // 自动播放
		controls: false, // 隐藏默认控件
		responsive: true,
		fluid: true,
		loop: true,
		muted: true,
		sources: [
			{
				src: require('@/assets/video/serveMob.mp4').default,
				type: 'video/mp4'
			}
		],
		chilldren: ['bigPlayButton']
	}
	const playerRef = React.useRef(null)
	useEffect(() => {
		const service = document.getElementsByClassName('ServiceMob')
		// const ball:any=document.getElementsByClassName('ball')
		const listener = throttle(() => {
			const isinview = isInViewPort(service) // 是否进入了可视区域
			setTitle.start({ opacity: isinview ? '1' : '0' })
			setBorder.start({ width: isinview ? '100%' : '0' })
			// if(isinview){
			//   setTimeout(() => {
			//     ball.play()
			//   }, 10 );
			// }else {
			//   ball.load()
			//   ball.pause()
			// }
		}, 50)
		document.addEventListener('scroll', listener)
		return () => {
			document.removeEventListener('scroll', listener)
		}
	}, [setTitle, setBorder])
	// useEffect(() => {

	//   const isplay = document.querySelector('video')?.play()
	//   if (isplay !== undefined) {
	//     isplay.then(_ => {
	//       setPlay(true)
	//     }).catch(() => {
	//       setPlay(false)
	//     })
	//   }
	// })
	//获取视频是否播放
	const [videoPlay, setPlay] = useState<boolean>(false)
	//获取鼠标距离
	const [changeX, setChangeX] = useState<Number>(0)
	//鼠标进入后的初始距离
	const enterNum = useRef<Number>(0)
	// 监听鼠标的移动
	const moveCard = (event: any) => {
		setChangeX(event.clientX - Number(enterNum.current))
	}
	const handleEnder = (event: any) => {
		enterNum.current = event.clientX
	}
	const handleLeave = (event: any) => {
		setChangeX(0)
	}
	const handlePlayerReady = (player: any) => {
		playerRef.current = player
		// you can handle player events here
		player.on('waiting', () => {
			// eslint-disable-next-line no-console
			console.log('player is waiting')
		})

		player.on('dispose', () => {
			// eslint-disable-next-line no-console
			console.log('player will dispose')
		})
		player.on('play', () => {
			setPlay(true)
		})
	}
	return (
		<section className="ServiceMob">
			{/* 标题 */}
			<h2 className="h2">
				{is_Zh && (
					<animated.span className="brandTitle" style={title}>
						{prop.module_param.title_zh}
					</animated.span>
				)}
				<animated.span className="brand" style={title}>
					{prop.module_param.sub_title}
				</animated.span>
			</h2>
			<animated.div className="border" style={border}></animated.div>
			<div></div>
			{/* 视频 */}
			<div className={`player`}>
				<VideoJS options={options} onReady={handlePlayerReady} height={true} />
				{/* <video 
          src={require('@/assets/video/serveMob.mp4').default} 
          className='ball'
          muted
          loop
          x5-video-player-fullscreen='true'
          playsInline
        >
        </video> */}
				{contant.map((item, index) => {
					return (
						<div
							key={index}
							className={`card card${index}`}
							style={{
								transform: `translateX(${videoPlay ? '0px' : '500px'})`
							}}>
							<div
								className={`pos pos${index}`}
								onMouseMove={moveCard}
								onMouseEnter={handleEnder}
								onMouseLeave={handleLeave}
								style={{
									transform: `translateX(${videoPlay ? '0px' : '500px'})`
								}}></div>
							<div
								style={{ transform: `translateX(${`${changeX}px`})` }}
								className="inside">
								<div
									className="contain"
									dangerouslySetInnerHTML={{ __html: item.card }}></div>
							</div>
						</div>
					)
				})}
				{/* <div className='back'
        style={{ transform: `translateX(${videoPlay?'0px':'500px'})`}}></div> */}
			</div>
			{/* 按钮 */}
			<div className="more">
				<a href="/services" className={`knowMore ${is_Zh ? 'zh' : 'cn'}`}>
					{t('了解更多')}
				</a>
			</div>
		</section>
	)
}

export default Advantage
