/*
 * @Author: your name
 * @Date: 2022-01-07 10:57:55
 * @LastEditTime: 2022-06-22 09:08:42
 * @LastEditors: ten ten.yan@ibaiqiu.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \bq-official-web\src\locales\en.js
 */
// 英文
const en = {
	// 头部和底部
	中文: 'Zh',
	英文: 'En',
	首页: 'Home',
	服务: 'Services',
	案例: 'Cases',
	百秋: 'Buy Quickly',
	新闻: 'News',
	加入: 'Join Us',
	联系: 'Contact Us',
	公司电话: 'Tel',
	联系邮箱: 'Contact Email',
	品牌合作: 'Brand Cooperation',
	直播合作: 'Livestreaming Cooperation',
	媒体合作: 'Media Cooperation',
	媒体合作邮箱: 'Email for Media Cooperation',
	求职招聘邮箱: 'Email for Job Recruitment',
	商务项目合作: 'Email for Business Inquiries',
	营销业务合作: 'Email for Marketing Inquiries',
	公司地址: 'Company Address',
	公司详细地址: 'Block B, 1358 Xiehe Road, Changning Dist. Shanghai ',
	公司详细地址分行: 'Block B, 1358 Xiehe Road,\nChangning Dist. Shanghai ',
	联系我们: 'Contact Us',
	姓名: 'Name',
	电话: 'Phone',
	邮箱: 'Email',
	公司名称: 'Name of Company',
	您的需求: 'Message ',
	必填: 'Required！',
	提交: 'Submit',
	重置: 'Reset',
	沪公网安备: 'Shanghai Public Security Record No. 31011402003088 ',
	版权: 'Shanghai Buy Quickly E-commerce Co., Ltd. All Rights Reserved.',
	备案: 'Shanghai ICP Security Record No. 14003092-1',
	隐私政策: 'Privacy Policy and Legal Statement',
	免责声明: 'Disclaimer',
	知识版权: 'Intellectual Property and Copyright',
	服务商: 'Service',
	了解品牌: 'Learn about BQ',
	品牌视频: 'Brand Videos',
	// 首页
	查看全部新闻: 'View All News',
	了解更多: 'Learn More',
	// 服务
	战略咨询: 'Strategy Consulting',
	店铺运营: 'Store Operation',
	全域营销: 'Integrated Marketing',
	创意视觉: 'Creative Vision',
	全域数据: 'Omni-Channel Data',
	服务范围: 'Scope of services',
	美妆运营: 'Beauty Operations',
	内容直播: 'Content Livestreaming',
	IT解决方案: 'IT Solutions',
	仓储物流: ' Warehouse and Logistics',
	视觉创意: 'Visual Creativity',
	全渠道运营: 'Omni-channel Operations',
	数智解决方案: 'Digital and Intelligent Solution',
	客户服务: 'Customer Service',
	供应链管理: 'Supply Chain Management',
	品牌孵化: '品牌孵化',
	// 案例
	所在行业: 'Industry',
	服务类型: 'Service Type',
	排序: 'Ranking：',
	全部: 'All',
	珠宝: 'Jewelry',
	男装: 'Men’s Wear',
	女装: 'Women’s Wear',
	箱包: 'Bags',
	腕表眼镜: 'Wristwatches & Glasses',
	运动户外: 'Sports & Outdoor',
	内衣: 'Underwear',
	美妆: 'Cosmetics',
	鞋履: 'Footwear',
	新零售: 'New Retail',
	创意工作室: 'Creative Studio',
	品牌咨询: 'Brand Consulting',
	创新技术: 'Innovative Technology',
	数字营销: 'Digital Marketing',
	发布时间: 'Release Time',
	访问量: 'Visits',
	精选推荐: 'Selected Recommendations',
	查看更多: 'Learn More',
	// BQ
	未来零售新中间: 'Future Retail New Intermediary',
	语录: 'Quotations',
	// 新闻
	荣誉: 'Honors',
	绩效: 'Performance',
	// 加入
	百秋文化: 'Corporate Culture',
	加入百秋: 'Join BQ',
	校园招聘: 'Campus Recruitment',
	社会招聘: 'Social Recruitment',
	二文化: 'Corporate Culture of “Er”',
	价值观: 'Values',
	成长晋升: 'Growth & Promotion',
	工作环境: 'Working environment',
	社团活动: 'Club activities',
	员工活动: 'Staff Activities',
	推荐级别: 'Recommendation Positions',
	伯乐奖: 'Talent Scout  Award (Pre-tax)',
	// 联系
	聆听: 'We offer the customized solutions when receiving your inquiries.',
	Tel: 'Company Telephone',
	展开更多: 'Load More',
	未来: 'New Intermediary Partner',
	零售: 'Of',
	新中间: 'Future Retail',
	专注国际时尚领域: 'Focus on the international fashion industry',
	国内领军的服务商: 'The leading omni-channel digital retail service provider',
	关于百秋的第一段描述:
		'Established in 2010, Shanghai Buy Quickly BMax Technology Services Group Co., Ltd. was a leading omni-channel digital retail service provider in China, focusing on the international fashion industry. Buy Quickly is dedicated to building a comprehensive retail operation service capability with a multi-channel, end-to-end approach through its innovative intermediary business model, assisting over a hundred international fashion brands in achieving integrated, multi-channel operations in the Chinese market.',
	关于百秋的第二段描述: `Driven by technology and data intelligence, Buy Quickly has developed its own retail new infrastructure service chain, which includes refined store operations across multiple ecommerce platforms, brand strategy consulting, digital marketing, data analysis, consumer lifecycle management, creative visual design, content livestreaming, digital solutions and supply chain management. In the future, Buy Quickly will take the mission of "to be a pioneer of new commercial civilization" and the vision of becoming the "New Intermediary Partner of Future Retail". Buy Quickly is committed to being the most trusted strategic partner of brands with providing the greater value of retail services for both brands and consumers.`,
	加入方式: 'Joining Path',
	前程无忧: 'www.51job.com',
	智联招聘: 'www.zhaopin.com',
	猎聘: 'www.liepin.com',
	在线投递简历: 'Online CV Submission',
	关注百秋招聘官网微信公众号:
		'Follow  the  official  Wechat  account  of  “Buy Quickly Recruitment”',
	了解最新招聘信息: ' for the latest information',
	用人理念: 'Employment Ideas',
	附: 'Attached:  The  final  interpretation  right  of  this  system belongs  to  Shanghai  Buy  Quickly  Human  Resources Department',
	提交成功: 'Submission Successful. ',
	您已提交成功:
		'Your submission has been received successfully. We will contact you within 5 business days. ',
	返回: 'Back',
	秒后自动返回: ' seconds return in'
}

export default en
