import React from 'react'
import Title from '@/components/PcComponents/BQMods/Title/Title'
import './VisionMission.scss'

interface title {
	block: {
		blockItem: {
			id: number
			nameCh: string
			nameEng: string
			describe: string
		}[]
		blockItemEn: {
			id: number
			nameCh: string
			nameEng: string
			describe: string
		}[]
	}
}
//大图片
const VisionMission: React.FC<title> = prop => {
	const is_Zh: boolean = localStorage.getItem('store_view') !== 'En'

	// 标题
	const title = {
		title_ch: '愿景使命',
		title_eg: ' Vision & Mission',
		other_title: 'Vision & Mission'
	}
	return (
		<section className="VisionMissionPC">
			<Title {...title}></Title>
			<div className="target">
				{is_Zh &&
					prop.block.blockItem.map((item, index) => {
						return (
							<div className="mission" key={item.id}>
								<div className="name">
									<div className="ch">{item.nameCh}</div>
									<div className="eng">{item.nameEng}</div>
								</div>
								<div className="describe">{item.describe}</div>
							</div>
						)
					})}
				{!is_Zh &&
					prop.block.blockItemEn.map((item, index) => {
						return (
							<div className="mission" key={item.id}>
								<div className="name">
									<div className="ch">{item.nameCh}</div>
									{/* <div className="eng">{item.nameEng}</div> */}
								</div>
								<div className="describe">{item.describe}</div>
							</div>
						)
					})}
			</div>
		</section>
	)
}
VisionMission.defaultProps = {
	block: {
		blockItem: [
			{
				id: 1,
				nameCh: '使命',
				nameEng: 'Mission',
				describe: '做新商业文明的创行者'
			},
			{
				id: 2,
				nameCh: '愿景',
				nameEng: 'Vision',
				describe: '未来零售新中间'
			}
		],
		blockItemEn: [
			{
				id: 1,
				nameCh: ' Mission',
				nameEng: 'Mission',
				describe: 'To Be The Pioneer Of New Commercial Civilization'
			},
			{
				id: 2,
				nameCh: 'Vision',
				nameEng: 'Vision',
				describe: 'New Intermediary Partner Of Future Retail'
			}
		]
	}
}
export default VisionMission
