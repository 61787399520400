import React, { useState, useEffect } from 'react'
import { throttle, isInViewPort } from '@/utils/index'
import style from './ContactForm.module.scss'
import { Form, Button, Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { subscribe } from '@/api/apis'
import { getPhoneReg } from '@/utils/index'
import BqInput from './Form/Input/Input'
import ClosePage from './ClosePage/ClosePage'

interface formData {
	phone: string
	company: string
	demand: string
	email: string
	username: string
}
// @ts-ignore
const is_Zh: boolean = localStorage.getItem('store_view') !== 'En'

const FC: React.FC = () => {
	const { t } = useTranslation()
	const [open, setOpen] = useState<boolean>(false)
	//提交成功
	const [success, setSuccess] = useState<boolean>(false)
	useEffect(() => {
		const el = document.getElementsByClassName(`${style.form}`)
		const isinview = isInViewPort(el)
		setOpen(isinview)
		// 监听滚动条
		const listener = throttle(() => {
			const isinview = isInViewPort(el)
			setOpen(isinview)
		}, 50)
		document.addEventListener('scroll', listener)
		return () => {
			document.removeEventListener('scroll', listener)
		}
	}, [])
	//表单组件
	const FormFC = () => {
		const { t } = useTranslation()
		const [open, setOpen] = useState<boolean>(false)
		const [anmiPhone, showAnmi] = useState<string>('false')
		const [anmiEmail, showEmail] = useState<string>('false')
		const [form] = Form.useForm()
		const onFinishFailed = (errorInfo: any) => {
			// eslint-disable-next-line no-console
			console.log('Failed:', errorInfo)
		}

		const onReset = () => {
			form.resetFields()
			showEmail('false')
			showAnmi('false')
		}
		const onFinish = (values: formData) => {
			setSuccess(false)
			let obj = {}
			if (values.phone) {
				obj = {
					phone: values.phone,
					email: values.email,
					name: values.username,
					company: values.company,
					remark: values.demand
				}
			} else {
				obj = {
					email: values.email,
					name: values.username,
					company: values.company,
					remark: values.demand
				}
			}
			subscribe({
				data: {
					...obj
				}
			}).then(() => {
				setSuccess(true)
				// onReset()
			})
		}
		useEffect(() => {
			const el = document.querySelector(`.${style.form}`)
			const isinview = isInViewPort(el)
			setOpen(isinview)
			// 监听滚动条
			const listener = throttle(() => {
				const isinview = isInViewPort(el)
				setOpen(isinview)
			}, 50)
			document.addEventListener('scroll', listener)
			return () => {
				document.removeEventListener('scroll', listener)
			}
		}, [])
		const changeAmi = () => {
			if (form.getFieldsValue().email !== undefined) {
				showEmail('true')
			}
			if (form.getFieldsValue().phone !== undefined) {
				showAnmi('true')
			}
		}
		return (
			<div>
				<Form
					className={style.form}
					name="basic"
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					form={form}
					autoComplete="off"
					onChange={changeAmi}>
					<Row gutter={{ xs: 40, sm: 30, md: 20 }}>
						<Col
							span={10}
							className={`${open ? 'open_active' : ''} bq_col`}
							style={{
								transitionDelay: open ? '.4s' : ''
							}}>
							<Form.Item
								name="username"
								rules={[
									{
										required: true,
										message: `${
											is_Zh ? '请输入您的姓名' : 'Please enter your first name.'
										}`
									}
								]}
								validateTrigger="onBlur">
								<BqInput label_zh="您的姓名" label_cn="Your Name*" />
							</Form.Item>
						</Col>
						<Col
							span={12}
							className={`${open ? 'open_active' : ''} bq_col`}
							style={{
								transitionDelay: open ? '.5s' : ''
							}}>
							<Form.Item
								name="phone"
								rules={[
									{
										pattern: getPhoneReg(),
										message: `${
											is_Zh ? '请输入正确电话' : 'Please enter right phone.'
										}`
									}
								]}>
								<BqInput
									label_zh="您的联系方式"
									label_cn="Your Contact Details"
									anmi={anmiPhone}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col
							span={24}
							className={`${open ? 'open_active' : ''} bq_col`}
							style={{
								transitionDelay: open ? '.6s' : ''
							}}>
							<Form.Item
								name="email"
								rules={[
									{
										required: true,
										message: `${
											is_Zh ? '请输入邮箱地址' : 'Please enter email.'
										}`
									},
									{
										type: 'email',
										message: `${
											is_Zh ? '请输入正确邮箱地址' : 'Please enter right email.'
										}`
									}
								]}
								validateTrigger="onChange">
								<BqInput
									label_zh="邮箱地址"
									label_cn="Email*"
									anmi={anmiEmail}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col
							span={24}
							className={`${open ? 'open_active' : ''} bq_col`}
							style={{
								transitionDelay: open ? '.7s' : ''
							}}>
							<Form.Item
								name="company"
								rules={[
									{
										required: true,
										message: `${
											is_Zh ? '请输入公司名称' : 'Please enter company name.'
										}`
									}
								]}>
								<BqInput label_zh="公司名称" label_cn="Company Name*" />
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col
							span={24}
							className={`${open ? 'open_active' : ''} bq_col`}
							style={{
								transitionDelay: open ? '.8s' : ''
							}}>
							<Form.Item
								name="demand"
								rules={[
									{
										required: true,
										message: `${
											is_Zh ? '请输入您的需求' : 'Please enter your demand.'
										}`
									}
								]}>
								<BqInput
									label_zh="您有什么样的需求"
									label_cn="What Are Your Needs*"
								/>
							</Form.Item>
						</Col>
					</Row>

					<Row>
						<Col
							span={24}
							className={`${open ? 'open_active' : ''} bq_col`}
							style={{
								transitionDelay: open ? '.9s' : ''
							}}>
							<Form.Item>
								<Button
									type="primary"
									htmlType="submit"
									className={`submit ${is_Zh ? 'zh' : 'en'}`}>
									{t('提交').toUpperCase()}
								</Button>
								<Button
									htmlType="button"
									onClick={onReset}
									className={`onreset ${is_Zh ? 'zh' : 'en'}`}>
									{t('重置').toUpperCase()}
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</div>
		)
	}
	return (
		<section className={style.ContactForm}>
			<header className="header">
				<div className="left">
					{is_Zh && (
						<>
							<span
								className={`${open ? 'open_active' : ''} zh`}
								style={{
									transitionDelay: open ? '.1s' : '0'
								}}>
								合作留言
							</span>
							<span
								className={`${open ? 'open_active' : ''} cn`}
								style={{
									transitionDelay: open ? '.2s' : '0'
								}}>
								Cooperation Message
							</span>
						</>
					)}
					{!is_Zh && (
						<>
							<span
								className={`${open ? 'open_active' : ''} en`}
								style={{
									transitionDelay: open ? '.2s' : '0'
								}}>
								Cooperation Message
							</span>
						</>
					)}
				</div>
				<div className="right">
					<div
						className={`${open ? 'open_active' : ''} tips en`}
						style={{
							transitionDelay: open ? '.3s' : '0'
						}}>
						{t('聆听')}
					</div>
					<FormFC />
				</div>
			</header>
			{success && <ClosePage success={success} />}
		</section>
	)
}

export default FC
