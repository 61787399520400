import React, { useState } from 'react';
import style from './JoinForm.module.scss';
import {
  SearchOutlined,
} from '@ant-design/icons';
// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'

const TYPES = [
  {
    name: '社会招聘',
    en_name: 'Social Recruitment'
  },
  {
    name: '校园招聘',
    en_name: 'Campus Recruitment'
  },

]
interface JoinData {
  type: number
  title_zh?: string
  onSearchJob: (jobName: string) => void
}
const FC: React.FC<JoinData> = (props) => {
  const [searchValue, setSearchValue] = useState('')
  const searchJob: React.MouseEventHandler<HTMLImageElement> = (e) => {
    props.onSearchJob(searchValue)
  }
  const inputHandler: React.FormEventHandler<HTMLInputElement> = (e) => {
    // @ts-ignore
    setSearchValue(e.target.value)
  }
  const keyDownEvent = (e:any) => {
    if(e.keyCode === 13 && searchValue) { // 点击搜索
      props.onSearchJob(searchValue)
    }
  }
  return (
    <section className={style.form}>
      <div className="left">
        {
          is_Zh && <span className='zh'>{TYPES[props.type].name}</span>
        }
        <span className='cn'>{TYPES[props.type].en_name}</span>
      </div>
      <div className="right">
        <div className="top">
          <span className='search_text'>Search</span>
          <SearchOutlined className='search_icon' onClick={searchJob}/>
        </div>
        <form action="#!" onSubmit={()=>{}}>
          <input type="search" className='input' value={searchValue} onChange={inputHandler} onKeyDown={(e) => keyDownEvent(e)} />
        </form>
      </div>
    </section>
  )
}

export default FC
