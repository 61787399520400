import React, { useState, useEffect, useRef } from 'react';
import { throttle, isInViewPort } from '@/utils/index';
// import * as Shape from '@/components/CommonComponents/Graphical/Graphical'
import ShapeMods from '@/components/CommonComponents/ShapeMods';
import style from './strategy.module.scss';
// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
interface BtnProps {
  Button_link: string // 按钮链接
  buttons: string // 按钮文案
  customize_html: 'default'|'black'|'' // 按钮样式，自己的样式，后端配一下class名
}
interface ServiceData {
  module_data:DataItem[]
}
interface DataItem {
  title_zh:string // 中文标题
  sub_title:string // 英文标题
  content:string // 文案
  layout: BtnProps[] // 按钮
  shape_type: 'hexagon'|'square'|'circular'|'squareRotate'|'squareBoth'|'triangleRight'|'triangleLeft' // 形状
  letter:string//字母
  letter2:string//字母
}

interface LineProps {
  index: number
}

interface TextAndShapProps extends DataItem {
  index: number
}

// 线条
const Line:React.FC<LineProps> = (props) => {
  const [open, setopen] = useState(false)
  useEffect(() => {
    const El = document.getElementsByClassName(`${style.line+props.index}`)

    // 监听滚动事件，throttle节流函数
    const listener = throttle(() => {
      const isinview = isInViewPort(El, 1400) // 是否进入了可视区域
      setopen(isinview)
    }, 50)
    document.addEventListener('scroll', listener)
    return () => {
      document.removeEventListener('scroll', listener)
    }
  }, [props.index])
  return (
    <div
      className={`${style.line} ${style.line+props.index}`}
      style={{ animationName: open ? style.open_frames: ''}}
    ></div>
  )
}

// 内容和形状
const TextAndShape:React.FC<TextAndShapProps> = (props) => {
  const ShapeItem = ShapeMods[props.shape_type]
  const [open, setopen] = useState(false)
  useEffect(() => {
    const El = document.getElementsByClassName(`${'text'+props.index}`)
    // 监听滚动事件，throttle节流函数
    const listener = throttle(() => {
      const isinview = isInViewPort(El, 1050) // 是否进入了可视区域
      if(isinview) setopen(isinview)
    }, 50)
    document.addEventListener('scroll', listener)
    return () => {
      document.removeEventListener('scroll', listener)
    }
  }, [props])
  return (
    <li
      className={`${'text'+props.index}`}
    >
      <div
        className='contentText'
        style={{
          top: open ? '0' : '400px',
          opacity: open ? '1':'0'
        }}
      >
        {
          is_Zh&&
          <div className="zh">{ props.title_zh }</div>
        }
        <div className="cn">{ props.sub_title }</div>
        <div className='text' dangerouslySetInnerHTML={{__html:props.content}}></div>
        <div className='btns'>
          {
            props.layout.map(item => {
              return (
                <a
                  key={item.buttons}
                  className={`${item.customize_html || 'default'} btn ${is_Zh?'zh':'cn'}`}
                  href={item.Button_link}
                >
                  { item.buttons }
                </a>
              )
            })
          }
        </div>
      </div>
      <div className='shapeBox'>
        <ShapeItem letter={props.letter} letter2={props.letter2}/>
      </div>
    </li>
  )
}

const FC:React.FC <ServiceData>= (prop:ServiceData) => {
  const contentRef = useRef<any>() // 获取内容的高度
  const [height, setHeight] = useState<number>(0) // 获取内容的高度，设置给父标签

  useEffect(() => {
    if(contentRef.current) {
      setHeight((contentRef.current.clientHeight-300))
    }
  }, [])
  
  return (
    <section className={style.strategy} style={{height: height}}>
      {/* 线条 */}
      {/* <div className='lineList'>
        {
          prop.module_data.map((item, index) => {
            return (
              <React.Fragment key={index}>
                {
                  index < prop.module_data.length-1 && 
                  <Line index={index} />
                }
              </React.Fragment>
            )
          })
        }
      </div> */}
      {/* 内容+形状 */}
      <ul className='contentList' ref={contentRef}>
        {
          prop.module_data.map((item, index) => {
            return (
              <TextAndShape key={'shap'+index} index={index} {...item} />
            )
          })
        }
      </ul>
    </section>
  )
}

export default FC
