import React, { useEffect, useState } from 'react'
import Components from '@/components/MobComponents/serverMods'
import { getCms } from '@/api/apis'

const Service: React.FC = () => {
	interface comItem {
		module_id: string
		module_data: []
		module_param: []
		module_remark: string
		module_title: string
		parent_id: string
		sort: number
	}
	// const serviceData : comTtem[] = [{
	//   module_id:'TopPicture'
	// },{
	//   module_id:'Strategy'
	// }]
	const [data, setData] = useState<Array<comItem>>([])
	useEffect(() => {
		getCms({
			params: {
				terminal: 'Ws_Mob',
				content_id: 'services',
				param_id: 'services'
			}
		}).then((res: any) => {
			if (res) {
				setData(res.data.page_data)
			}
		})
		return () => {
			setData([])
		}
	}, [])
	return (
		<>
			{data.map((item, index) => {
				const Service: any = Components[item['module_id']]
				return Service && <Service key={index} {...item} />
			})}
		</>
	)
}

export default Service
