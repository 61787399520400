import React, { useEffect } from 'react'
import styled from 'styled-components'
import PcHeader from '@/components/PcComponents/PcHeader/PcHeader'
import MobHeader from '@/components/MobComponents/MobHeader/MobHeader'
import PcFooter from '@/components/PcComponents/PcFooter/PcFooter'
import MobFooter from '@/components/MobComponents/MobFooter/MobFooter'
import * as pcViews from '@/views/PcViews/index';
import * as mobileViews from '@/views/MobileViews/index';
import useDevice from '@/hooks/useDevice';
import { publishResize } from './utils'
// import './App.scss'
// import '@/assets/css/reset.scss'

const App: React.FC = () => {
	// 获取当前是pc端还是移动端
	const device = useDevice()
	const Main = styled.div`
		width: 100%;
		background-color: #000;
		color: #fff;
		overflow: hidden;
		position: relative;
		font-size: 16px;
		padding-top: ${device === 'pc' ? '122px' : '50px'};
		box-sizing: border-box;
	`
	const Content = styled.div`
		// overflow-x: hidden;
		// overflow-y: auto;
		position: relative;
	`
  // const [showTop, setShowTop] = useState<boolean>(false)
  // const [number, setNumber] = useState<number>(0)
	useEffect(() => {
		// console.log(navigator.language,'navigator')
    let resizeListener = publishResize()
		if(!localStorage.getItem('store_view')){
			localStorage.setItem('store_view', 'Zh' )
		}
		// const el=document.getElementsByClassName('outSide')
    // // 监听滚动条
    // const listener = throttle(() => {
    //   const isinviewTop = isInViewPort(el, 3000)
		// 	console.log(isinviewTop?1:2,'isinview')
		// 	if(isinviewTop){
		// 		setNumber(2)
		// 	}
    //   // setShowTop(isinview)
    // }, 50)
    // document.addEventListener('scroll', listener)
		return (() => {
			window.removeEventListener('resize', resizeListener)
      // document.removeEventListener('scroll', listener)
		})
	}, [])
	// @ts-ignore
	let content_id = window.page.body?.content_id || 'index'
	let View, Head, Foot;
	if(device === 'pc') {
	// @ts-ignore
		View =  pcViews[content_id]
		Head = PcHeader
		Foot = PcFooter
	} else {
	// @ts-ignore
		View = mobileViews[content_id]
		Head = MobHeader
		Foot = MobFooter
	}
  return (
			<Main>
				<Head/>
				<Content>
					<View />
					<Foot />
				</Content>
			</Main>
	)
}


export default App
