import React, { CSSProperties, useEffect } from 'react';
import CardOpen from '@/components/CommonComponents/pc/CardOpen/CardOpen';
import style from './OneImage.module.scss'
import objectFitImages from 'object-fit-images';

interface OneImageProps {
  src: string // 图片的链接
  width?: string // 宽度
  link?: string // 图片的跳转地址
  style?: CSSProperties // 图片的样式
}

const mystyle: CSSProperties = {
  marginTop: '59px',
  width: '100%',
  padding: '0 15px 51px 35px'
}

const FC: React.FC<OneImageProps> = (props) => {
  useEffect(() => {
    // 解决ie不兼容css: objec-fit属性
    const img: HTMLElement|null = document.querySelector(`.${style.img}`)
    objectFitImages(img)
  })
  // 随机classm名
  const cardClassName = 'cardopen_' + Math.random().toString().slice(2)
  const clickEvent = (link: string|undefined) => {
    if(link) {
      window.location.href = link
    }
  }
  return (
    <div className={style.oneImage} style={{...mystyle}}>
      <CardOpen cardClassName={cardClassName} width='100%' style={{marginTop: '0'}} bodyStyle={{padding: 0}}>
        <img
          onClick={()=>clickEvent(props.link)}
          alt=''
          src='https://moose-ows-res.ibaiqiu.com/moose/cms/20211210/162059.jpg' 
          className='img'
          style={{
            cursor: props.link ? 'pointer' : ''
          }}
        ></img>
      </CardOpen>
    </div>
  )
}

export default FC