import React, { useEffect, useState } from 'react';
import { throttle, isInViewPort } from '@/utils/index';
import SmallBanner from '../SmallBanner/SmallBanner'
import './ServiceAdvantages.scss'
interface CircleData{
  module_data:{
    id:string,
    content:any,
    describeList: string
    layout:{
      content:string
    }[]
  }[]
  module_param:{
    title_zh:string
    sub_title:string
  }
}
// 圆形组件
const Circles: React.FC<CircleData> = (prop) => {
  const [show, setShow] = useState<boolean>(false)
  useEffect(() => {
    const service = document.getElementsByClassName('contents')
    const listener = throttle(() => {
      const isinview = isInViewPort(service) // 是否进入了可视区域
      if(isinview) setShow(isinview)
    }, 50)
    document.addEventListener('scroll', listener)
    return () => {
      document.removeEventListener('scroll', listener)
    }
  }, [setShow])
  return (
    <div className={`contents ${show ? (prop.module_data.length === 1 ? 'one' : (prop.module_data.length % 2 === 0 ? 'odd' : 'even')) : ''}`}>
      {
        prop.module_data.map((item, index) => {
          return (
            <div className="advantage" key={item.id}>
              <div
                className={`big big${index}`}
                key={item.id}
              >
                <div className='small'>
                  <div className='word'
                    dangerouslySetInnerHTML={{ __html: item.content }}></div>
                </div>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}
//工具
const ServiceAdvantages: React.FC<CircleData> = (prop) => {

  return (
    <section className='ServiceAdvantagesMob'>
      <SmallBanner title_ch={prop.module_param.title_zh} title_eg={prop.module_param.sub_title}></SmallBanner>
      <Circles {...prop}></Circles>
    </section>
  )
}
export default ServiceAdvantages