import React, { useState } from 'react';
import style from './JoinForm.module.scss';
import SearchImg from '@/assets/img/ss.png';


// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
const TYPES = [
  {
    name: '社会招聘',
    en_name: 'Social Recruitment'
  },
  {
    name: '校园招聘',
    en_name: 'Campus Recruitment'
  },

]
interface JoinData {
  title_zh?: string
  onChangeType: (index: number) => void
  onSearchJob: (jobName: string) => void
}
const FC: React.FC<JoinData> = (props) => {
  const [type, setType] = useState<number>(0) // 0-校园招聘；1社会招聘
  const [searchValue, setSearchValue] = useState('')
  // 切换招聘类型
  const changeType = (index: number) => {
    setType(index)
    props.onChangeType(index)
  }
  const searchJob: React.MouseEventHandler<HTMLImageElement> = (e) => {
    props.onSearchJob(searchValue)
  }
  const inputHandler: React.FormEventHandler<HTMLInputElement> = (e) => {
    // @ts-ignore
    setSearchValue(e.target.value)
  }
  const keyDownEvent = (e:any) => {
    if(e.keyCode === 13 && searchValue) { // 点击搜索
      props.onSearchJob(searchValue)
    }
  }
  return (
    <section className={style.form}>
      <div className="left">
        {
          is_Zh && <span className='zh'> {TYPES[type].name}</span>
        }
        <span className='cn'>{TYPES[type].en_name}</span>
      </div>
      <div className="right">
        <div className="top">
          <span className='search_text'>Search</span>
          <img src={SearchImg} className='search_icon' alt="" onClick={searchJob} />
        </div>
        <input type="text" className='input' value={searchValue} onChange={inputHandler} onKeyDown={(e) => keyDownEvent(e)}/>
      </div>
      {/* <div className='types'>
        {
          TYPES.map((item, index) => {
            return (
              <div
                className={`
                  type
                  ${index === type && 'type_active'}
                  ${is_Zh ? 'zh' : 'cn'}
                `}
                key={item.name}
                onClick={() => changeType(index)}
              >
                {is_Zh ? item.name : item.en_name}
              </div>
            )
          })
        }
      </div> */}
    </section>
  )
}

export default FC
