import React, { useEffect,useState }  from 'react';
import { useSpring,animated } from 'react-spring';
import { throttle, isInViewPort } from '@/utils/index';
import './CenterPicture.scss'

interface timeItem {
  id: string
  mob_image_url:string
  pc_image_url:string
}
interface PictureData{
  picture1?:string
  picture2?:string
}
//中间图片动画
const CenterPicture: React.FC <timeItem[]>= (prop) => {
  const [open1,setOpen1]=useSpring(()=>({width:'0',height:'0',config:{duration: 500,transitionProperty: 'all'}}))
  // const [open2,setOpen2]=useSpring(()=>({width:'0',height:'0',config:{duration: 500,transitionProperty: 'all'}}))
  const [pictures,showPicture]=useState<PictureData>({})
  const [show,setShow]=useState<boolean>(false)
  useEffect(() => {
    showPicture(Object.assign({},{picture1:prop[0].pc_image_url},{picture2:prop[1].pc_image_url}))
  },[])
  //图片组
  const Photos = (props:PictureData)=>{
    useEffect(() => {
      const topPhoto=document.getElementsByClassName('topPhoto')
      const listener = throttle(() => {
        const isinview = isInViewPort(topPhoto) // 是否进入了可视区域
        if(isinview) setShow(isinview)
        // setOpen3(isinview2)
        // setOpen1.start({
        //   width: isinview ? '100%':'0',
        //   height: isinview ? '100%':'0',
        // })
        // setOpen2.start({
        //   width: isinview ? '100%':'0',
        //   height: isinview ? '100%':'0',
        // })
      }, 50)
      document.addEventListener('scroll', listener)
        return () => {
        document.removeEventListener('scroll', listener)
      }
    }, [])
    return(
      <div className='topPhoto' >
        <div className='outside'>
          {
            props.picture1&&
            <img 
              src={props.picture1} 
              alt="" 
              className='leftPhoto'
              style={{animationName:show?'open_height_width':''}}
            >
            </img>
          }
        </div>
        <div className='outsideRight' >
          {
            props.picture2&&
            <img 
              src={props.picture2}
              alt="" 
              className='rightPhoto'
              style={{animationName:show?'open_height_width':''}}
            >
            </img>
          }
        </div>
      </div>
    )
  }
  //大图片
  const BigPhoto=(prop:timeItem)=>{
    useEffect(() => {
      const bottomPhoto=document.getElementsByClassName('bigPhoto')
      
      const listener = throttle(() => {
          const isinview2 = isInViewPort(bottomPhoto) // 是否进入了可视区域
          if(isinview2){
            setOpen1.start({
              width: isinview2 ? '100%':'0',
              height: isinview2 ? '100%':'0',
            })
          }
        }, 50)
        document.addEventListener('scroll', listener)
          return () => {
          document.removeEventListener('scroll', listener)
        }
    },[])
    return(
      <div className='bigPhoto'>
        {
          prop.pc_image_url&&
          <animated.img 
            src={prop.pc_image_url}
            alt="" 
            className='photoItem'
            style={open1}
          >
          </animated.img>
        }
      </div>
    )
  }
  return (
    <section className='CenterPicturePC'>
      <div className='pictureInform'>
        <Photos {...pictures}></Photos>
        <BigPhoto {...prop[2]}></BigPhoto>
      </div>
    </section>
  )
}

export default CenterPicture