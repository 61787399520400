import React,{useState,useEffect} from 'react';
// import { useSpring, animated,Controller} from 'react-spring';
import { throttle, isInViewPort } from '@/utils/index';
import {Triangle,Circular,Square,Hexagon,Rectangle,Diamond,TriangleFlip} from '@/components/CommonComponents/Graphical_mob/Graphical_mob'
import './strategy.scss'
// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
interface BtnProps {
  Button_link: string // 按钮链接
  buttons: string // 按钮文案
  customize_html: 'default'|'black'|'' // 按钮样式，自己的样式，后端配一下class名
}
interface moduleData{
  title_zh:string,
  sub_title:string,
  content:string
  index:number
  layout:BtnProps[]
  shape_type:string
  letter:string
  letter2:string
}
interface grapData{
  index:number
  shape_type:string
  open:boolean
}
interface ServiceData {
  module_data:moduleData[]
}
//服务
const Strategy: React.FC<ServiceData> = (prop:ServiceData) => {
  //形状动画组件
  const Pic = (param:grapData) => {
    return (
      <div
         className='picture'
      >
        { 
          (param.shape_type==='hexagon'&&<Hexagon {...param}/>)||
          (param.shape_type==='triangleRight'&&<div className='grapOne'><TriangleFlip {...param}/></div>)||
          (param.shape_type==='squareBoth'&&<Rectangle {...param}/>)||
          (param.shape_type==='circular'&&<Circular {...param}/>)||
          (param.shape_type==='square'&&<Square {...param}/>)||
          (param.shape_type==='squareRotate'&&<Diamond {...param}/>)||
          (param.shape_type==='triangleLeft'&&
          <Triangle {...param}/>)
        }
      </div>
    )
  }
  //文字动画
  const Blocks=(prop:moduleData)=>{

    const [open, setopen] = useState(false)
    useEffect(() => {
      const El = document.getElementsByClassName(`blockItem${prop.index}`)
      const isinview = isInViewPort(El) // 是否进入了可视区域
        if(isinview) setopen(isinview)
      // 监听滚动事件，throttle节流函数
      const listener = throttle(() => {
        const isinview = isInViewPort(El) // 是否进入了可视区域
        if(isinview) setopen(isinview)
      }, 50)
      document.addEventListener('scroll', listener)
      return () => {
        document.removeEventListener('scroll', listener)
      }
    }, [])
    return(
      <div className={`blockItem blockItem${prop.index}`}>
        {/* 文字介绍 */}
        <div 
          className={`box `}
          style={{
            opacity:open?'1':'0',
            top: open ? '0' : '200px'
          }} 
        >  
        {
          is_Zh&&
          <div className="head">{ prop.title_zh }</div>
        }
          <div 
            className='engName'
          >{prop.sub_title}</div>
          <div 
            className='content'
            dangerouslySetInnerHTML={{__html:prop.content}}
          ></div>
          <div 
            className='btn'
          >
            {
              prop.layout.map(item => {
                return (
                  <a
                    key={item.buttons}
                    className={`${item.customize_html || 'more'}  ${is_Zh?'zh':'cn'}`}
                    href={item.Button_link}
                  >
                    { item.buttons }
                  </a>
                )
              })
            }
          </div>
        </div>
        <Pic
          {...prop}
          open={open}
        ></Pic>
        
      </div>
    )
  }
  return (
    <section className='strategyMob'>
      <div 
        className='bottomAni' 
      >
        {
          prop.module_data.map((item, index)=>{
            return(
              <Blocks {...item} key={index} index={index}></Blocks>
            )
          })
        }
      </div>
    </section>
  )
}

export default Strategy