import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import {getViewStore} from '@/utils/index'

import zh from './locales/zh';
import en from './locales/en';
const resources = {
  // 简体中文
  Zh: {
    translation: {
      ...zh
    }
  },
  // 英文-香港
  En: {
    translation: {
      ...en
    }
  }
}

i18n.use(initReactI18next).init({
  resources,
  lng: getViewStore(),
  keySeparator: false, // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
})

export default i18n;
