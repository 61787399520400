import React from 'react';
import './CaseLight.scss'
// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
interface dataInform{
  light:string,
  eng:string
}
//案例亮点
const CaseLight: React.FC<dataInform> = (prop) => {
  return (
    <section className='CaseLightMob'>
      {
        is_Zh&&
        <h2 className="h2">{prop.light}</h2>

      }

      <div className="ch">{prop.eng}</div>
      <div className="border"></div>
    </section>
  )
}
CaseLight.defaultProps={
  light:'案件亮点',
  eng:"Cases Highlight"
}

export default CaseLight