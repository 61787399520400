import React, { useEffect, useState } from 'react'
import { throttle, isInViewPort } from '@/utils/index'
import SmallBanner from '../SmallBanner/SmallBanner'
import './ServiceAdvantages.scss'
interface CirclePageData {
	module_data: {
		id: string
		content: any
		describeList: string
		layout: {
			content: string
		}[]
	}[]
	module_param: {
		title_zh: string
		sub_title: string
	}
}
// 圆形组件
const Circles: React.FC<CirclePageData> = prop => {
	const is_Zh: boolean = localStorage.getItem('store_view') !== 'En'

	const [show, setShow] = useState<boolean>(false)
	useEffect(() => {
		const service = document.getElementsByClassName('contents')
		const listener = throttle(() => {
			const isinview = isInViewPort(service, 450) // 是否进入了可视区域
			if (isinview) setShow(isinview)
		}, 50)
		document.addEventListener('scroll', listener)
		return () => {
			document.removeEventListener('scroll', listener)
		}
	}, [setShow])
	return (
		<div
			className={`contents ${
				show
					? prop.module_data.length === 1
						? 'one'
						: prop.module_data.length % 2 === 0
						? 'odd'
						: 'even'
					: ''
			}`}>
			{prop.module_data.map((item, index) => {
				return (
					<div className="advantage" key={item.id}>
						<div className={`big big${index}`} key={item.id}>
							<div className="small">
								<div
									className={`word ${is_Zh ? 'zh' : 'en'}`}
									dangerouslySetInnerHTML={{ __html: item.content }}></div>
							</div>
						</div>
						{item.layout.length !== 0 && (
							<div className="describe">
								<div
									className={`word ${is_Zh ? 'zh' : 'en'}`}
									dangerouslySetInnerHTML={{
										__html: item.layout[0].content
									}}></div>
							</div>
						)}
					</div>
				)
			})}
		</div>
	)
}
//工具
const ServiceAdvantages: React.FC<CirclePageData> = prop => {
	// const[circleData,setCircleData]=useState<CirclePageData>(prop)
	// useEffect(()=>{
	//   if(prop.module_data.length %2  !== 0){
	//     const sliceData=prop.module_data.splice(0,1)
	//     prop.module_data.splice(1,0,sliceData[0])
	//     const arr:CirclePageData=prop
	//     setCircleData(arr)
	//   }else{
	//     setCircleData(prop)
	//   }
	//   console.log(circleData,'circleData')
	// },[prop])
	return (
		<section className="ServiceAdvantagesPC">
			<SmallBanner
				title_ch={prop.module_param.title_zh}
				title_eg={prop.module_param.sub_title}></SmallBanner>
			<Circles {...prop}></Circles>
		</section>
	)
}

export default ServiceAdvantages
