import React, { useState, useEffect } from 'react'
import logo from '@/assets/img/logo.png'
import { useTranslation } from 'react-i18next'
import style from './PcHeader.module.scss'
import { setLang } from '@/api/apis'
import close from '@/assets/img/close.png'
import SearchImg from '@/assets/img/ss.png'

const PcHeader: React.FC = () => {
	const { t } = useTranslation()
	const [lang, setLangs] = useState<string | null>(
		localStorage.getItem('store_view') === 'Zh' ? 'En' : 'Zh'
	)

	const is_Zh: boolean = localStorage.getItem('store_view') === 'Zh'
	// 搜索框内容
	let content: HTMLInputElement | null = null
	// 是否搜索
	const [search, setSearch] = useState<boolean>(false)
	const navList = [
		{
			name: t('首页'),
			url: '/',
			actives: ['/']
		},
		{
			name: t('服务'),
			url: '/services',
			actives: [
				'/services',
				'/strategic',
				'/store_operation',
				'/global_data',
				'/beauty_operation',
				'/marketing',
				'/operations',
				'/creativity',
				'/live',
				'/brand_incubation',
				'/digital_intelligence',
				'/supply_management'
			]
		},
		{ name: t('案例'), url: '/cases', actives: ['/cases', '/case'] },
		{ name: t('百秋'), url: '/about', actives: ['/about'] },
		{
			name: t('新闻'),
			url: '/news',
			actives: ['/news', '/Honors', '/Performance', '/new']
		},
		{ name: t('加入'), url: '/job', actives: ['/job', '/club'] },
		{ name: t('联系'), url: '/contact', actives: ['/contact'] }
	]
	const [selectedNav, setSelectedNav] = useState(-1)
	const changeLange = () => {
		setLang({
			data: {
				lang: localStorage.getItem('store_view') === 'Zh' ? 'en_US' : 'zh_CHS'
			}
		}).then(() => {
			localStorage.setItem('store_view', lang === 'Zh' ? 'Zh' : 'En')
			setLangs(localStorage.getItem('store_view') === 'Zh' ? 'En' : 'Zh')
			window.location.href = window.location.pathname
		})
	}
	// 搜索方法
	const Search = () => {
		setSearch(!search)
		if (content) {
			window.location.href = '/Search?searchWord=' + content.value
		}
	}
	const SearchOpen = () => {
		setSearch(!search)
	}
	const keyDownEvent = (e: any) => {
		if (e.keyCode === 13 && content?.value) {
			// 点击搜索
			window.location.href = '/Search?searchWord=' + content.value
		}
	}
	useEffect(() => {
		//将所在页面的URL高亮
		const pathname = window.location.pathname
		let lightPath: string
		if (pathname !== '/' && pathname) {
			const patt1 = new RegExp(/^\/[\w]+(\/)?/)
			const pathAll = patt1.exec(pathname)
			if (pathAll && pathAll[1]) {
				lightPath = pathAll[0].slice(0, -1)
			} else {
				lightPath = pathname
			}
		} else {
			lightPath = pathname
		}
		const index = navList.findIndex(item => {
			return item.actives.includes(lightPath)
		})
		setSelectedNav(index)
	}, [])
	return (
		<header className={`${style.header} ${!is_Zh && 'en'}`}>
			<a href="/">
				<img className="logo" src={logo} alt="" />
			</a>
			{!search && (
				<div className="nav">
					{navList.map((nav, index) => (
						<a
							href={nav.url}
							className={`navItem ${selectedNav === index && 'selectedNav'} `}
							key={nav.name}>
							{nav.name}
						</a>
					))}
				</div>
			)}
			{/* 搜索框 */}
			{search && (
				<div className="searchItem">
					<input
						type="search"
						className="searchWord"
						placeholder="Search"
						ref={word => {
							content = word
						}}
						onKeyDown={e => keyDownEvent(e)}
					/>
					<img
						src={SearchImg}
						alt=""
						className="searchInnerBtn"
						onClick={Search}
					/>
				</div>
			)}
			<div className="rightMenu">
				<img
					src={search ? close : SearchImg}
					alt=""
					className="searchBtn"
					onClick={SearchOpen}
				/>
				<div className="langeBtn" onClick={() => changeLange()}>
					{lang === 'Zh' ? '中文' : 'En'}
				</div>
			</div>
		</header>
	)
}

export default PcHeader
