import React, { useState, useEffect } from 'react'
import Title from '../Title/Title'
import './DevelopHistory.scss'

interface DevelopHistoryData {
	module_data: {
		Display_time: string
		describe: string
		pc_image_url: string
	}[]
	module_param: {
		title_zh: string
		sub_title: string
	}
}
interface DevelopData {
	Display_time: string
	describe: string
	pc_image_url: string
}
//发展历程
const DevelopHistory: React.FC<DevelopHistoryData> = prop => {
	const title = {
		title_ch: prop.module_param.title_zh,
		title_eg: prop.module_param.sub_title,
		other_title: prop.module_param.sub_title
	}
	const [pageData, setPageData] = useState<DevelopData[]>(prop.module_data)
	useEffect(() => {
		const arr: DevelopData[] = prop.module_data.map(item => {
			if (item.describe) {
				item.describe = item.describe.replace(/\n/g, '<br/>')
			}
			return item
		})
		setPageData(arr)
	}, [prop.module_data])
	// 点击哪个时间
	const [select, setSelect] = useState<number>(1)

	// 点击哪个日期
	const [selectDate, setSelectDate] = useState<string>('2021')

	const updateDate = (item: any, index: any) => {
		setSelect(index)
		setSelectDate(item.Display_time)
	}
	return (
		<section className="DevelopHistoryPC">
			<Title {...title} />
			<div
				className={[
					'developBox',
					selectDate == '2022' ? 'heightWrap' : ''
				].join(' ')}>
				<div className="left">
					<div className="time">
						{/* 时间轴 */}
						{prop.module_data.map((item, index) => {
							return (
								<div key={index} className="timeItem">
									<div className="rod"></div>
									{/* 具体时间 */}
									<div
										className="timeDetaile"
										onClick={() => {
											updateDate(item, index)
										}}
										style={{ color: select === index ? '#F38400' : '#FFFFFF' }}>
										{item.Display_time}
									</div>
								</div>
							)
						})}
					</div>
				</div>
				{/* 右侧详细信息 */}
				<div className="right">
					<div>
						{pageData[select].pc_image_url && (
							<img
								src={pageData[select].pc_image_url}
								alt=""
								className="photo"
							/>
						)}
						<div className="bigTime">
							{isNaN(Number(pageData[select].Display_time))
								? pageData[select].Display_time
								: `${pageData[select].Display_time}`}
						</div>
						<div
							className="word"
							dangerouslySetInnerHTML={{
								__html: pageData[select].describe
							}}></div>
					</div>
				</div>
			</div>
		</section>
	)
}
export default DevelopHistory
