import React,{useEffect, useState} from 'react'
import Components from '@/components/PcComponents/SearchMods';
import {searchDetail} from '@/api/apis'
import { getQuery } from '@/utils/index'
interface searchList{
  total?:number
  list?:{

  }[]
}
interface comTtem {
  module_id:string
}
interface searchValue{
  searchWord?:string
}
const Search: React.FC = () => {
  const [search,setSearch]=useState<searchList>({})
  const SearchData : comTtem[]=[
    {
      module_id:'Search'
    }
  ]
  useEffect(()=>{
    const content:searchValue =getQuery(decodeURI(window.location.search))
    searchDetail({
      data:{
        key_words:content.searchWord,
        language:localStorage.getItem('store_view') === 'Zh' ? 'zh_CHS' : 'en_US',
        terminal: 'Ws_Pc',
      }
    }).then((res)=>{
      setSearch(res.data)
    })
  },[])
  return (
    <div>
      {
        SearchData.map((item,index)=>{
          const Search:any=Components[item['module_id']]
          return Search && <Search key={index} {...search}/>
        })
      }
    </div>
  )
}

export default Search
