import React from 'react';
import Title from '../Title/Title'
import './OverseasUnder.scss'
import Paris from '@/assets/img/Paris.png'
import Over from '@/assets/img/over.jpg'
import NewYork from '@/assets/img/New York.png'
interface OverseasData{
  content:string,
  OverseasUnder_eg:string,
  pc_image_url:string
}
interface OverseasList {
  module_data: OverseasData[]
}
//标题
const OverseasUnder: React.FC<OverseasData> = (prop) => {
  const title ={
    title_ch:'海外事业',
    title_eg:'Overseas undertaking',
    other_title:'Overseas Business'
  }
  return (
    <section className='OverseasUnderPC'>
      <Title {...title}/>
      <div className='moduleBlock'>
      <img src={Paris} alt=""  className='paris'/>
        <img src={NewYork} alt="" className='newYork'/>
        <div className='name'>{prop.content}</div>
        {
          Over&&
          <img src={Over} alt="" className='img'/>
        }
        <div className='eng'>{prop.OverseasUnder_eg}</div>
      </div>
    </section>
  )
}
OverseasUnder.defaultProps={
  content:'在纽约、巴黎和米兰成立在分公司，开拓当地业务、孵化当地新锐设计师品牌，全面布局和参与未来世界奢侈品时尚行业的发展。',
  OverseasUnder_eg:'Milan',
  pc_image_url:'https://www.buyquickly.com/sites/default/files/capability/2018-10/about%20us-warehouse.jpg'
}
export default OverseasUnder