import React, { useState, useEffect, useRef } from 'react'
import Top from '@/assets/img/top.png'
import Bottom from '@/assets/img/bottom.png'
import { useTranslation } from 'react-i18next'
import { changeData } from '@/utils/index'
import { setpPgeViewCount } from '@/api/apis'
import './CaseList.scss'
// @ts-ignore
const is_Zh: boolean = localStorage.getItem('store_view') !== 'En'
// interface BrandInfor {
//   children: BrandItem[]
// }
interface BrandItem {
	id: string
	img: string
	title: string
	Sort_value: string
	mob_image_url: string
	creation_time: string
	Industry: {}
	visits?: number
	service_type: any
	ws_url: string
}
interface brandData {
	module_data: BrandItem[]
}
interface industryName {
	id: number
	Industry?: string
	service_type?: string
}
interface rules {
	Industry?: string
	service_type?: string
}
// interface sortData {
//   id: number
//   slectName: string
// }
//品牌卡片组件
const BrandCart: React.FC<BrandItem> = prop => {
	// 品牌标签参数
	const [label, setLabel] = useState([])
	// 处理时间
	const [time, setTime] = useState<string>('')
	//案例详情跳转
	const detaile = (id: string, ws_url: string) => {
		if (id) {
			setpPgeViewCount({
				data: { id: Number(id) }
			}).then(() => {
				window.location.href = ws_url + '?id=' + id
			})
		}
	}
	useEffect(() => {
		// 筛选出label渲染的标签
		setLabel(Object.values(prop.service_type))
		setTime(prop.creation_time.split(' ')[0])
	}, [prop.service_type, prop.creation_time])
	return (
		<div className="cardBox">
			<div
				className="brandTop"
				onClick={() => {
					detaile(prop.id, prop.ws_url)
				}}>
				{prop.mob_image_url && (
					<img src={prop.mob_image_url} alt="" className="brandPhoto" />
				)}
				{prop.img && <img src={prop.img} alt="" className="brandName" />}
				<div className="brandDetaile">{prop.title}</div>
			</div>
			<div className="cardBottom">
				<div className="labels">
					{label.map((item, index) => {
						return (
							<div
								key={item + index}
								className="labelItem"
								style={{ padding: is_Zh ? '0 8px' : '0 2px' }}>
								{item}
							</div>
						)
					})}
				</div>
				{/* <div className='data'>{changeData(time)}</div> */}
			</div>
		</div>
	)
}
//服务
const CaseList: React.FC<brandData> = prop => {
	const { t } = useTranslation()
	// 返回的数据
	const obj = {
		module_data1: [
			{
				id: 1,
				Industry: t('全部')
			},
			{
				id: 2,
				Industry: t('珠宝')
			},
			{
				id: 3,
				Industry: t('男装')
			},
			{
				id: 4,
				Industry: t('女装')
			},
			{
				id: 5,
				Industry: t('箱包')
			},
			{
				id: 6,
				Industry: t('腕表眼镜')
			},
			{
				id: 7,
				Industry: t('运动户外')
			},
			{
				id: 8,
				Industry: t('内衣')
			},
			{
				id: 9,
				Industry: t('美妆')
			},
			{
				id: 10,
				Industry: t('鞋履')
			}
		],
		module_data2: [
			{
				id: 1,
				service_type: t('全部')
			},
			{
				id: 2,
				service_type: t('战略咨询')
			},
			{
				id: 3,
				service_type: t('店铺运营')
			},
			{
				id: 4,
				service_type: t('全域数据')
			},
			{
				id: 5,
				service_type: t('全域营销')
			},
			{
				id: 6,
				service_type: t('创意视觉')
			},
			{
				id: 7,
				service_type: t('美妆运营')
			},
			{
				id: 8,
				service_type: t('内容直播')
			},
			// {
			//   id: 9,
			//   service_type: t('品牌孵化')
			// },
			{
				id: 10,
				service_type: t('IT解决方案')
			},
			{
				id: 11,
				service_type: t('仓储物流')
			}
		],
		module_data3: [
			{
				id: 1,
				slectName: t('发布时间')
			},
			{
				id: 2,
				slectName: t('访问量')
			},
			{
				id: 3,
				slectName: t('精选推荐')
			}
		]
	}
	// 页面渲染的数据
	const [pageData, setPageData] = useState<BrandItem[]>(prop.module_data)
	//选中所在行业
	const [selectNav, setSelectNav] = useState<Number>(1)
	//选中服务类型
	const [selectType, setSelectType] = useState<Number>(1)
	//选中排序规则
	// const [selectSort, setSelectSort] = useState<Number>(0)
	// 向下箭头的展开
	const [open, setOpen] = useState<boolean>(true)
	const [open1, setOpen1] = useState<boolean>(true)
	const [openBrand, setOpenBrand] = useState<number>(1) //展开的页数
	const caseRef = useRef({ Industry: 'All', service_type: 'All' })
	const pageLength = Math.ceil(prop.module_data.length / 6)
	const [showPage, setShowPage] = useState<boolean>(true) //是否显示分页
	useEffect(() => {
		// 添加访问量排序字段
		prop.module_data.map(item => {
			return (item = Object.assign(item, { visits: 0 }))
		})
		setPageData(prop.module_data.slice(0, 6))
	}, [prop.module_data])
	// 分页
	const pages = () => {
		if (pageLength >= openBrand) {
			setOpenBrand(openBrand + 1)
			setPageData(prop.module_data.slice(0, (openBrand + 1) * 6))
		}
	}
	// 所在行业,服务类型选择
	const selectIndustry = (item: industryName) => {
		if (item.Industry) {
			caseRef.current.Industry = item.Industry
			setSelectNav(item.id)
		} else if (item.service_type) {
			caseRef.current.service_type = item.service_type
			setSelectType(item.id)
		}
		const arr = filterCase(prop.module_data, caseRef.current)
		setPageData(arr)
		setShowPage(false)
	}
	/**
	 * @description 组合查询筛选
	 * @param {BrandItem[]} origin 筛选的数据
	 * @param {rules} rules 查询规则
	 * @returns {BrandItem[]} 筛选后返回的数据
	 */
	function filterCase(origin: BrandItem[], rules: rules) {
		let res = []
		res = origin.filter(item => {
			let filterRes = true //是否符合筛选条件
			for (const key in rules) {
				// 判断筛选的规则是否在原数据存在
				if (Object.hasOwnProperty.call(item, key)) {
					const value = item[key] //数据的行业或者服务类型 array
					const rule = rules[key] //筛选的行业或者服务类型 string
					if (rule === 'All' || rule === '全部') {
						continue
					}
					filterRes = Object.values(value).includes(rule)
					if (!filterRes) {
						break
					}
				} else {
					filterRes = false
				}
			}
			if (filterRes) {
				return item
			} else {
				return null
			}
		})
		return res
	}
	/**
	 * @description 排序
	 * @param {string} field 需要排序的字段
	 * @param {string} rule 排序的规则
	 * @param {BrandItem[]} data 需要排序的数据
	 * @returns {BrandItem[]} 筛选后的数据
	 */
	// function orderBy(field: string, rule: string, data: BrandItem[]) {
	//   let res = [...data]
	//   res = res.sort((a, b) => {
	//     if (rule === 'desc') {
	//       return b[field] - a[field]
	//     } else {
	//       return a[field] - b[field]
	//     }
	//   })
	//   return res
	// }
	// 排序选择
	// const sortRank=async (item:sortData)=>{
	//   setSelectSort(item.id)
	//   let sortName=''
	//   if(item.id===1){
	//     sortName='creation_time'
	//   }else if(item.id===2){
	//     sortName='visits'
	//     await visits()
	//   }else {
	//     sortName='Sort_value'
	//   }
	//   setPageData(orderBy(sortName,'desc',prop.module_data))
	// }
	//访问量查询
	// const visits=()=>{
	//   return new Promise((resolve)=>{
	//     setpPgeViewList({
	//     }).then((res)=>{
	//       Object.keys(res.data).forEach((key)=>{
	//         prop.module_data.map(item=>{
	//           if(item.id===String(key)){
	//             return(item.visits=res.data[key])
	//           }else{
	//             return 0
	//           }
	//         })
	//       })
	//       resolve(true)
	//     })
	//   })
	// }
	return (
		<section className="caseListMob">
			<div className="caseBox">
				<h2 className="h2">
					{is_Zh && <span className="caseTitle">案例</span>}
					<span className="casebq">Showcase</span>
				</h2>
				{/* 二级菜单 */}
				<div className="block">
					<div className="industry">
						<span className="word">{t('所在行业')}</span>
						<img
							src={open ? Top : Bottom}
							alt=""
							onClick={() => {
								setOpen(!open)
							}}
							className="icon"
						/>
					</div>
					{open && (
						<ul className="allIndustry">
							{obj.module_data1.map(item => {
								return (
									<li
										key={item.id}
										className={`indusName  ${
											selectNav === item.id ? 'selectIndus' : ''
										}`}
										onClick={() => {
											selectIndustry(item)
										}}>
										{item.Industry}
									</li>
								)
							})}
						</ul>
					)}
				</div>
				<div className="block">
					<div className="industry">
						<span>{t('服务类型')}</span>
						<img
							src={open1 ? Top : Bottom}
							alt=""
							onClick={() => {
								setOpen1(!open1)
							}}
							className="icon"
						/>
					</div>
					{open1 && (
						<ul className="allIndustry">
							{obj.module_data2.map(item => {
								return (
									<li
										key={item.id}
										className={`indusName ${
											selectType === item.id ? 'selectIndus' : ''
										}`}
										onClick={() => {
											selectIndustry(item)
										}}>
										{item.service_type}
									</li>
								)
							})}
						</ul>
					)}
				</div>
				{/* 排序 */}
				{/* <div className={`sort ${is_Zh ? "eng" : "chs"}`}>
          <div className={`${is_Zh ? 'sortName' : 'sortNameEg'}`}>{t("排序")}</div>
          <ul className='selectNum'>
            {
              obj.module_data3.map((item, index) => {
                return (
                  <li
                    key={item.id}
                    className={`select ${selectSort === index + 1 && 'selectSort'} `}
                    onClick={() => { sortRank(item) }}
                    style={{ marginRight: item.id === 1 ? '5px' : '0' }}
                  >{item.slectName}
                  </li>
                )
              })
            }
          </ul>
        </div> */}
			</div>
			{/* 品牌 */}
			<div className="brand">
				{pageData.map((item, index) => {
					return <BrandCart key={index} {...item}></BrandCart>
				})}
				{showPage && pageLength > openBrand && (
					<div
						className={`more ${is_Zh ? 'zh' : 'en'}`}
						onClick={() => {
							pages()
						}}>
						{t('查看更多')}
					</div>
				)}
			</div>
		</section>
	)
}

export default CaseList
