import React, { useState, useEffect, useRef, SyntheticEvent } from 'react';
import CardFilp from '@/components/CommonComponents/pc/CardFilp/CardFilp';
import { throttle } from '@/utils/index';
import XiaPng from '@/assets/img/xiangxia.png';
// import objectFitImages from 'object-fit-images';
import honor from '@/assets/img/honor.png'
import { TitlesType } from '@/ts/api'

import style from './OurHonor.module.scss'
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
// 卡片组件
interface BannerData {
  module_data: HonorCard[]
  module_param: TitlesType
}

interface HonorCard {
  content: string
  title_zh: string
  id: string
  sub_title: string
}
// 卡片组件
interface CartNum {
  id: string,
  title_zh: string,
  content: string,
  style?: string,
  sub_title: string,
}
const Cart:React.FC<CartNum>=(prop:CartNum)=>{
  return(
    <div className={prop.content===''?'':style.outCart}>
      <div className={prop.content===''?style.honer:'cart'}>
        {
          is_Zh&&
          <div className={prop.content === '' ? "all" : 'name'}>{prop.title_zh}</div>
        }
        {
          is_Zh&&
          <div 
            className={
              prop.content === '' ? "honorEg" : "english"
            }
          >{prop.sub_title}</div>
        }
        {
          !is_Zh&&
          <div className='egWord'>
            {prop.sub_title}
          </div>
        }
        {
          !(prop.content === '') &&
          <img src={honor} alt="" className='honorImg' />
        }
      </div>
    </div>
  )
}
// 卡片翻转后 
const Back:React.FC<CartNum>=(prop)=>{
  const myRef=useRef<any|undefined>()
  const [showImg,setShowImg]=useState<boolean>(false)
  const scrollHandler=(e:any)=>{
    let el=e.target
    if(el.scrollTop+el.clientHeight>=el.scrollHeight){
      setShowImg(false)
    } else {
      setShowImg(true)
    }
  }
  useEffect(()=>{
    if(myRef.current){
      if(myRef.current.clientHeight<myRef.current.scrollHeight){
        setShowImg(true)
      }
    }
  }, [])
  return (
    <div className={style.backHeight}>
      {
        !(prop.content === '') &&
        (<div className='backCard'>
          <div
            className="contain"
            dangerouslySetInnerHTML={{ __html: prop.content }}
            onScroll={scrollHandler}
            ref={myRef}
          >
          </div>
          {
            showImg&&
            <img src={XiaPng} alt="" className="more"/>
          }
        </div>)
      }
    </div>
  )
}
// 我们的荣誉
const Fc: React.FC<BannerData> = (prop: BannerData) => {
  const [scHeight, setscHeight] = useState<number>(0)
  const [list, setlist] = useState<Array<Array<CartNum>>>([])
  // 给翻转组件传递的卡片尺寸
  const styleBlock = {
    width: '',
    borderRadius: '2px'
  }
  const honorStyle = {
    border: 'none'
  }
  // 处理返回数据
  useEffect(() => {
    let data: Array<Array<CartNum>> = [
      [],
      [],
    ]
    prop.module_data.forEach((item, index) => {
      data[index % 2].push(item)
    })
    setlist(data)
    document.addEventListener('scroll', compareHeight)
    return () => {
      document.removeEventListener('scroll', compareHeight)
    }
  }, [])

  // 获取距离视图高度
  const myRef = useRef<HTMLElement | null>(null)
  // 获取屏幕宽度
  if (myRef.current) {
    // 动态计算卡片宽度
    let cardHeight = (myRef.current?.clientWidth / 2.222) + 'px'
    styleBlock.width = cardHeight
  }
  //控制卡片移动的高度
  const compareHeight = throttle(() => {
    let offsetTop = myRef.current && myRef.current.offsetTop ? myRef.current.offsetTop : 0
    let clientHeight = myRef.current?.clientHeight||1400
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop ||document.body.scrollTop || 0;
    let height = scrollTop - offsetTop
    if(height>=-700&&height<clientHeight+200) {
      let changeTrans = Math.ceil(30-30*(height/clientHeight))
      setscHeight(changeTrans)
    }
  }, 20)
  return (
    <section className={style.OurHonorPc_mob} ref={myRef}>
      {
        list.map((item, index) => {
          return (
            <div
              className={`content${index} content`}
              key={index}
              style={{
                transform: `translateY(${index === 1 ? `${scHeight}px` : '0px'}`
              }}
            >
              {
                item.map((self, key) => {
                  return (
                    <div key={key} className='outBlock'>
                      {
                        self.content === '' &&
                        <CardFilp
                          frontChildren={<Cart  {...self} />}
                          backChildren={<Back  {...self} />}
                          style={styleBlock}
                          frontStyle={honorStyle}
                        ></CardFilp>
                      }
                      {
                        !(self.content === '') &&
                        <CardFilp
                          frontChildren={<Cart  {...self} />}
                          backChildren={<Back  {...self} />}
                          style={styleBlock}
                        ></CardFilp>
                      }
                    </div>
                  )
                })
              }
            </div>
          )
        })
      }
    </section>
  )
}

export default Fc