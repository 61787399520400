import { formatQs } from '@/utils';
import { PREFIX } from './config';
import http from './http';

/**
 * url:接口地址。PREFIX代理时使用，STORE_VIEW（axios）拦截时会替换为window.page.store_view，formatQs将传入的对象{id:123,num:321}转为字符串?id=123&num=321
 * methods: 方法。支持get/post/put/delete
 * Authorization: 接口是否需要token
 * showLoading: 接口是否需要显示Loading效果
 * */ 
// getCms(dat: {
//   content_id:"index",
//   param_id: 'index'
// },
// params: {

// }
// ).then(res => {

// })

 export const getCms = ({data={}, params={}}) => {
  return http({
    url: `${PREFIX}/rest/STORE_VIEW/V1/applet/getCustomCms${formatQs(params)}`,
    methods: 'get',
    data,
    Authorization: false,
    showLoading: true
  })
}

export const getlangList = ({data={}, params={}}) => {
  return http({
    url: `${PREFIX}/rest/language`,
    methods: 'post',
    data,
    Authorization: false,
    showLoading: true
  })
}

export const setLang = ({data={}, params={}}) => {
  return http({
    url: `${PREFIX}/rest/language/setLanguage`,
    methods: 'post',
    data,
    Authorization: false,
    showLoading: true
  })
}
export const setpPgeViewCount = ({data={}, params={}}) => {
  return http({
    url: `${PREFIX}/index.php/rest/visit/pageViewCount`,
    methods: 'post',
    data,
    Authorization: false,
    showLoading: true
  })
}
export const setpPgeViewList = ({data={}, params={}}) => {
  return http({
    url: `${PREFIX}/index.php/rest/visit/pageViewList`,
    methods: 'post',
    data,
    Authorization: false,
    showLoading: true
  })
}
export const subscribe = ({data={}, params={}}) => {
  return http({
    url: `${PREFIX}/rest/subscribe`,
    methods: 'post',
    data,
    Authorization: false,
    showLoading: true
  })
}
export const searchDetail = ({data={}, params={}}) => {
  return http({
    url: `${PREFIX}/rest/search`,
    methods: 'post',
    data,
    Authorization: false,
    showLoading: true
  })
}
