import React, { useState, useEffect, useRef } from 'react';
import PageBq from '../PageBq/PageBq';
import Title from '@/components/PcComponents/BQMods/Title/Title';
import './HonoraryQuali.scss'

interface dataNum {
  title_zh: string
  pc_image_url: string
  content: string
  openTime: number // 开启的年份
  openWidth: number // 开启的宽度
  closeWidth: number // 关闭的宽度
}

// 时间块组件
const BlockItem: React.FC<dataNum> = (props) => {
  
  return (
    <div className='timeBlock' style={{ width: props.openTime ==  Number(props.title_zh) ? props.openWidth + 'px' : props.closeWidth + 'px'}} >
      <div className={`blockOne ${props.openTime === Number(props.title_zh) ? 'openblockOne' : ''}`} style={{ width: props.closeWidth + 'px' }}>
        {props.title_zh}
      </div>
      {/* 展开的内容 */}
      <div className='showOpen' style={{ display: props.openTime === Number(props.title_zh) ? 'block' : 'none' }}>
          <div className='detaile' dangerouslySetInnerHTML={{ __html: props.content }}>
        </div>
        {
          props.pc_image_url&&
          <img src={props.pc_image_url} alt="" className='imgItem' />
        }
      </div>
    </div>
  )
}
interface HonorData {
  title_zh: string
  pc_image_url: string
  content: string
}

interface HonorList {
  module_data: HonorData[]
  module_param:{
    title_zh:string
    sub_title:string
  }
}

//荣誉资质
const HonoraryQuali: React.FC<HonorList> = (prop) => {
  // 数据
  const [timePage, setTimePage] = useState<number[]>([])
  const [openTime, setopenTime] = useState<number>(Number(prop.module_data[prop.module_data.length - 3].title_zh)) // 打开的年份
  const [openWidth, setopenWidth] = useState<number>(0)
  const [closeWidth, setcloseWidth] = useState<number>(0)
  useEffect(() => {
    init()
    filterPage()
  }, [])
  // 处理分页数据
  const filterPage = () => {
    let timePageCopy: number[] = []
    prop.module_data.forEach((item) => {
      timePageCopy.push(Number(item.title_zh))
    })
    setTimePage(timePageCopy)
  }
  // 初始化时间块宽度
  const init = () => {
    const viewPortWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    const len = prop.module_data.length - 1
    let openW = Math.floor(viewPortWidth * 0.53)
    let closeW = Math.floor((viewPortWidth - openW) / len)
    setopenWidth(openW)
    setcloseWidth(closeW)
  }
  // 点击时间回调
  const callBack = (year: number) => {
    if (openTime !== year) {
      setopenTime(year)
    }
  }
  // 标题
  const title = {
    title_ch: prop.module_param.title_zh,
    title_eg: prop.module_param.sub_title
  }
  return (
    <section className='HonoraryQualiPC'>
      <Title {...title} other_title={prop.module_param.sub_title} />
      <div className='honorBox'>
        {
          prop.module_data.map((item, index) => {
            return (
              <div className='outside' onClick={() => { callBack(Number(item.title_zh)) }} key={index} >
                <BlockItem {...item} openTime={openTime} openWidth={openWidth} closeWidth={closeWidth} />
              </div>
            )
          })
        }
      </div>
      <PageBq
        timePage={timePage}
        changeHander={(num: number) => callBack(num)}
        active={openTime}
      />
    </section>
  )
}

export default HonoraryQuali