import React from 'react';
import TopBanner from '../../serverMods/ServiceDetaile/TopBanner/TopBanner';

// @ts-ignore
// const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
const FC:React.FC = () => {
  return (
    <div>
      {/* {
        is_Zh&&
        <TopBanner titleEnglish='Contact'  />
      } */}
      <TopBanner  titleChinese='联系' titleEnglish='Contact' />
    </div>
  )
}

export default FC
