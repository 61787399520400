/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
// import { validatorPhone } from '@/utils/form';
import { Form, Button } from 'antd'
import BqInput from './Input/Input'
import { useTranslation } from 'react-i18next'
import BQ from '@/assets/img/bq.png'
import Weixin from '@/assets/img/weixin.png'
import Weibo from '@/assets/img/weibo.png'
import IN from '@/assets/img/in.png'
import style from './PcFooter.module.scss'
import { subscribe } from '@/api/apis'
import ClosePage from './ClosePage/ClosePage'
import { getPhoneReg } from '@/utils/index'

// @ts-ignore
const is_Zh: boolean = localStorage.getItem('store_view') !== 'En'
const PcFooter: React.FC = () => {
	//提交成功
	const [success, setSuccess] = useState<boolean>(false)
	const { t } = useTranslation()
	const [form] = Form.useForm()
	const onFinish = (values: any) => {
		setSuccess(false)
		let obj = {}
		if (values.phone) {
			obj = {
				phone: values.phone,
				email: values.email,
				name: values.username,
				company: values.company,
				remark: values.demand
			}
		} else {
			obj = {
				email: values.email,
				name: values.username,
				company: values.company,
				remark: values.demand
			}
		}
		subscribe({
			data: {
				...obj
			}
		}).then(() => {
			form.resetFields()
			setSuccess(true)
		})
	}

	const onFinishFailed = (errorInfo: any) => {
		// eslint-disable-next-line no-console
		console.log('Failed:', errorInfo)
	}
	return (
		<footer className={`${style.footer} ${is_Zh ? 'en' : 'zh'}`}>
			<div className="topNav">
				<dl className="menu">
					<dt>Menu</dt>
					<dd>
						<a href="/services">{t('服务')}</a>
					</dd>
					<dd>
						<a href="/cases">{t('案例')}</a>
					</dd>
					<dd>
						<a href="/about">{t('百秋')}</a>
					</dd>
					<dd>
						<a href="/news">{t('新闻')}</a>
					</dd>
					<dd>
						<a href="/job">{t('加入')}</a>
					</dd>
					<dd>
						<a href="/contact">{t('联系')}</a>
					</dd>
				</dl>

				<dl className="contact">
					<dt>Contact</dt>
					<dd>
						{/* <div className='label'>{t('媒体合作邮箱')}</div>
						<div className='value'>pr@ibaiqiu.com</div> */}
						{/* <div className='label'>{t('求职招聘邮箱')}</div>
						<div className='value'>hr@ibaiqiu.com</div> */}
					</dd>
					<dd>
						<div className="label">{t('公司电话')}</div>
						<div className="value">+86 21 6013 6999</div>
					</dd>

					<dd>
						
					<div className="label">{t('公司地址')}</div>
						<div className="value zh">{t('公司详细地址分行')}</div>
					</dd>
				</dl>

				<dl className="contact size-large">
					<dt></dt>
					<dd>
						{/* <div className='label'>{t('商务项目合作')}</div>
						<div className='value'>bd@ibaiqiu.com</div> */}
					</dd>
					<dd>
						
					<div className="label">{t('联系邮箱')}</div>
						<div className="value">{t('品牌合作')}{is_Zh?' ':': '}bd@ibaiqiu.com</div>
						
						{/* {is_Zh && <div className="label">{'\u00A0'}</div>} */}
						<div className="value">{t('媒体合作')}{is_Zh?' ':': '}pr@ibaiqiu.com</div>
						<div className="value">{t('直播合作')}{is_Zh?' ':': '}commercial@romomo.com</div>
					</dd>
					<dd>
						{/* <div className='label'>{t('营销业务合作')}</div>
						<div className='value'>mkt@ibaiqiu.com</div> */}
					</dd>
					<dd>
					</dd>
				</dl>

				<div className="lastShare">
					<dl className="share">
						<dt>Wechat</dt>
						<dd>
							{/* <div >
								<a href="#">
									<img src={Weibo} alt="" className='top'/>
								</a>
								<a href="#">
									<img src={Weixin} alt="" className='top'/>
								</a>
								<a href="#">
									<img src={IN} alt="" className='top'/>
								</a>
							</div> */}
							<div>
								<img src={BQ} alt="" className="bottom" />
							</div>
						</dd>
					</dl>
				</div>
			</div>
			<div className="centerForm">
				<div className="form-title">{t('联系我们')}</div>
				<Form
					className="form"
					name="basic"
					autoComplete="off"
					layout="inline"
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					form={form}>
					<Form.Item
						name="username"
						className="formItem"
						rules={[
							{
								required: true,
								message: t('必填')
							}
						]}>
						<BqInput label={t('姓名')} />
					</Form.Item>
					<Form.Item
						name="phone"
						className="formItem"
						rules={[
							{
								pattern: getPhoneReg(),
								message: `${
									is_Zh ? '请输入正确电话' : 'Please enter right phone.'
								}`
							}
						]}>
						<BqInput label={t('电话')} />
					</Form.Item>
					<Form.Item
						name="email"
						className="formItem"
						rules={[
							{
								required: true,
								message: t('必填')
							},
							{
								type: 'email',
								message: `${
									is_Zh ? '请输入正确邮箱地址' : 'Please enter right email.'
								}`
							}
						]}>
						<BqInput label={t('邮箱')} />
					</Form.Item>
					<Form.Item
						name="company"
						className="formItem"
						rules={[
							{
								required: true,
								message: t('必填')
							}
						]}>
						<BqInput label={t('公司名称')} />
					</Form.Item>
					<Form.Item
						name="demand"
						className="formItem_te"
						rules={[
							{
								required: true,
								message: t('必填')
							}
						]}>
						<BqInput label={t('您的需求')} newname="input_te" />
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType="submit" className="submit">
							{t('提交')}
						</Button>
					</Form.Item>
				</Form>
			</div>
			<div className="anbei zh">
				<a href="https://www.beian.gov.cn/portal/registerSystemInfo?spm=5176.28055625.J_9220772140.52.5f7e154aoW8A78">
					<div className="security">{t('沪公网安备')}</div>
				</a>
				<div className="security">{t('版权')}</div>
				<a href="https://beian.miit.gov.cn/?spm=5176.28055625.J_9220772140.53.5f7e154aoW8A78">
					<div className="security">{t('备案')}</div>
				</a>
				<a href="/Privacy_Policy_and_Legal_Statement">
					<div className="security">{t('隐私政策')}</div>
				</a>
				{/* <a href="/disclaimer">
					<div className="security">{t('免责声明')}</div>
				</a>
				<a href="/Intellectual_Property_Rights">
					<div className="security">{t('知识版权')}</div>
				</a> */}
			</div>
			{success && <ClosePage success={success} />}
		</footer>
	)
}

export default PcFooter
