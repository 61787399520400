import React, { useEffect,useState } from 'react';
import { throttle, isInViewPort } from '@/utils/index';
import { useSpring, animated } from 'react-spring';
import { useTranslation } from 'react-i18next';
import { TitlesType } from '@/ts/api'
import objectFitImages from 'object-fit-images';
import style from './LeaderTalk.module.scss';
interface BannerData{
  module_data:NewsData[]
  module_param:TitlesType
}
interface NewsData{
  title_zh:string
  sub_title:string
  pc_source_url:string
  content:string
}
// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'

const LeaderTalk: React.FC<BannerData>= (props) => {
	const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)//是否进入可视区域
  const [title, setTitle] = useSpring(() => ({ opacity: '0', config: { duration: 400 } }))
  const [border, setBorder] = useSpring(() => ({ width: '0', config: { duration: 400 } }))
  useEffect(() => {
    const aboutbqEl = document.getElementsByClassName('introduce')
    const listener = throttle(() => {
      const isinview = isInViewPort(aboutbqEl) // 是否进入了可视区域
      setOpen(isinview)
      setBorder.start({ width: isinview ? '100%' : '0' })
      setTitle.start({ opacity: isinview ? '1' : '0' })
    }, 50)
    document.addEventListener('scroll', listener)
    return () => {
      document.removeEventListener('scroll', listener)
    }
  }, [setBorder, setTitle])
  useEffect(() => {
    // 解决ie不兼容css: objec-fit属性
    const img = document.querySelectorAll('.contLeftMedia')
    objectFitImages(img)
  })
  return (
    <section className={style.leaderTalkPc}>
      <h2 className="h2">
        {
          is_Zh &&
          <animated.span className="aboutTitle" style={title}>{props.module_param.title_zh}</animated.span>
        }
        <animated.span className="aboutbq" style={title}>{props.module_param.sub_title}  </animated.span>
      </h2>
      <animated.div className="border" style={border}></animated.div>
      <div className='content'>
        <div className='leaderPic'>
          {
            props.module_data[0].pc_source_url&&
            <img src={props.module_data[0].pc_source_url} alt="" />
          }
        </div>
        <div className='introduce'>
          <div className={`name ${open?'open_active':''}`}>{props.module_data[0].title_zh}</div>
          <div className={`position ${open?'open_active':''}`}>{props.module_data[0].sub_title}</div>
          <div className={`bqTarget ${open?'open_active':''}`} dangerouslySetInnerHTML={{__html: props.module_data[0].content}}></div>
        </div>
      </div>
    </section>
  );
};

export default LeaderTalk