/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState, ReactNode, useEffect, CSSProperties } from 'react';
import style from './CardFilp.module.scss'

// 翻转卡片

interface CardProps {
  frontChildren: ReactNode // 默认显示的内容
  backChildren: ReactNode // hover时显示的内容
  style?: CSSProperties // 盒子样式
  frontStyle?: CSSProperties // 默认显示的内容-的样式
  backStyle?: CSSProperties // hover时显示的内容-的样式
}

const Card: React.FC<CardProps> = (props) => {
  const backRef = useRef<any>() // 获取hover-标签
  const [cardHeight, setCardHeight] = useState(0) // 卡片高度
  useEffect(() => {
    let timer = setTimeout(() => { // 立刻执行可能会获取不到卡片的高度，需要延迟一段时间
      if (backRef.current) {
        setCardHeight(backRef.current.clientHeight) // 获取反面内容的高度，设置给正面
      }
      clearTimeout(timer)
    }, 300)
  }, [backRef.current?.clientHeight])
  return (
    <div className={style.bq_card_slip} style={{ ...props.style, height: cardHeight + 'px' }}>
      {/* 正面 */}
      <div className='slip_front' style={{ ...props.frontStyle }}>
        {
          props.frontChildren
        }
      </div>
      {/* 反面-hover */}
      {
      <div className='slip_back' ref={backRef} style={{ ...props.backStyle }}>
        {
          props.backChildren
        }
      </div>
      }
    </div>
  )
}

export default Card