import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { throttle, isInViewPort } from '@/utils/index';
import pictureOne from '@/assets/img/pictureOne.jpg'
import pictureTwo from '@/assets/img/pictureTwo.jpg'
import style from './Work.module.scss';
// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
const FC: React.FC = () => {
	const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)
  const textList: string[] = ['Our', 'Work', 'Environment']
  
  useEffect(() => {
    const aboutbqEl = document.getElementsByClassName(`${style.work}`)

    const listener = throttle(() => {
      const isinview = isInViewPort(aboutbqEl, 200) // 是否进入了可视区域
      setOpen(isinview)
    }, 50)
    document.addEventListener('scroll', listener)
    return () => {
      document.removeEventListener('scroll', listener)
    }
  }, [])
  return (
    <section className={style.work}>
      <header className={`title ${!is_Zh?'cn':'zh'}`} style={{animationName: open ? style.title_open : style.title_close}}>
        {t('工作环境')}
      </header>
      <div className='content'>
        {
          textList.map((item, index) => {
            return (
              <span
                className='text'
                key={index}
              >
                {
                  item.split('').map((letter, i) => {
                    return (
                      <span
                        key={i}
                        className='letter'
                        style={{
                          animationName: open ? style.landIn : '',
                          animationDelay: `${i * .05}s`
                        }}
                      >{letter}</span>
                    )
                  })
                }
              </span>
            )
          })
        }
        <img
          className={`${open ? 'img1_active' : ''} img1`}
          src={pictureOne}
          alt=""
        />
        <img
          className={`${open ? 'img2_active' : ''} img2`}
          src={pictureTwo}
          alt=""
        />
      </div>
    </section>
  )
}

export default FC

