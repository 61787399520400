import React from 'react';
import style from './index.module.scss';
interface  letter{
  letter:string,
  letter2:string
}
// 长方形
const FC:React.FC<letter> = (props) => {
  return (
    <div className={style.SquareBoth}>
      <div className='oneLetter'>
        <div className={`${style.text} first_text`}>{props.letter}</div>
      </div>
      <div className={`${style.text} last_text`}>
      {props.letter2}
      </div>
    </div>
  )
}

export default FC
