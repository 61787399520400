import React, { useState,useEffect} from 'react';
import comp from '../index'
import { setpPgeViewCount } from '@/api/apis'
import './OtherCase.scss'

interface blockInform{
  id:string,
  pc_image_url:string,
  img:string,
  title:string,
  service_type:any,
  creation_time:string,
  ws_url:string
}
interface otherData{
  otherList:blockInform[]
}
//标题内容
const titleInform={
  title_zh:"其他案例",
  sub_title:'Other Case'
}
//品牌块组件
const BlockItem:React.FC<blockInform>=(prop)=>{
  const [show,setShow]=useState<boolean>(false)
  // 品牌标签参数
  const [label, setLabel] = useState([])
  // 处理时间
  const [time,setTime]=useState<string>('')
  useEffect(() => {
    // 筛选出label渲染的标签 
    setLabel(Object.values(prop.service_type))
    setTime(prop.creation_time.split(' ')[0])
  }, [])
  //案例详情跳转
  const detaile=(id:string,ws_url:string)=>{
    if(id){
      setpPgeViewCount({
        data: { id:Number(id)}
      }).then(() => {
        window.location.href=ws_url+'?id='+id
      })
    }
  }
  return(
    <div className='items'>
      <div className='topContent' onClick={()=>{detaile(prop.id,prop.ws_url)}}>
        <div
          className='picture'
          onMouseEnter={()=>{setShow(true)}}
          onMouseLeave={()=>{setShow(false)}}
        >
          <div 
            className='pictureBox'
            style={{animationName:show?'openPicture':'closePicture'}}
          >
          </div>
          {
            prop.pc_image_url&&
            <img
                src={prop.pc_image_url}
                alt="" 
                className='pictureItem'
            />
          }
        </div>
        {
          prop.img&&
          <img src={prop.img} alt="" className='brandName'/>
        }
        <div className='brandLogo'>{prop.title}</div>
      </div>
      <div className='bottoms'>
        <div className='labels'>
          {
            label.map((item,index)=>{
              return(
                <div className='labelItem' key={item+index}>{item}</div>
              )
            })
          }
        </div>
        <div className='data'>{time}</div>
      </div>
    </div>
  )
}
//其他案例
const OtherCase: React.FC<otherData> = (props) => {
  return (
    <section className='bottomCase'>
      <comp.CaseLight {...titleInform} />
      <div className='OtherCasePC'>
        {
          props.otherList.map((item,index)=>{
            return(
              <React.Fragment key={item.id}>
                {
                  index<3&&
                  <BlockItem {...item} />
                }
              </React.Fragment>
            )
          })
        }
      </div>
    </section>
  )
}

export default OtherCase