import React, { useEffect,useState } from 'react';
// import { throttle, isInViewPort } from '@/utils/index';
// import objectFitImages from 'object-fit-images';
import { throttle, isInViewPort } from '@/utils/index';
import { useSpring, animated } from 'react-spring';
import './Brands.scss'
interface BannerData{
  module_data:{
    children:{
      sid:number
    }[]
  }[]
  module_param:TitlesType
}
interface TitlesType{
  sub_title:string
  title_zh:string
  all_logo:string
  hover_logo:string
  mkt_title:string
}
// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
// 合作品牌
const Fc: React.FC <BannerData>= (prop:BannerData) => {
  
  const[title,setTitle]= useSpring(() => ({
    opacity : '0', config : {duration:400}
  }))
  // 点击分类标题字体是否高亮
  // const [light,setLight]=useState<Number>()
  // const [classifica,setClassifica]=useState<number>(0)
  const [allBrand,setAllBrand]=useState<boolean>(false)
  const [randomNum,setRandomNum]=useState<number[]>([])//产生的随机数的下标
  // 分类标题点击事件
  // const showBrand=(index:number)=>{
  //   setLight(index)
  //   setClassifica(index-1)
  //   obj.module_data.forEach((item)=>{
  //     if(item.id===index){
  //       setAllBrand(true)
  //     }
  //   })
  // }
  const [border, setBorder] = useSpring(() => ({width: '0', config: { duration: 400 }}))
  // 随机生成二维码
  const checkCodeofRandom = (value:number) => {
    let arr=[];
    const length = value ? value : 4;
    for(let i = 0; i < length; i++) {
      //每次生成一个0 - 61 之间的 number 作为随机获取验证码的下标
      let p = Math.floor(Math.random()*prop.module_data[0].children.length);
      arr.push(p)
    }
    return arr;
  }
  useEffect(()=>{
    setRandomNum(checkCodeofRandom(Math.ceil(prop.module_data[0].children.length/2)))
    let root = document.body
    root.style.setProperty('--background-image', `url(${prop.module_param.all_logo})`)
    root.style.setProperty('--background-hover', `url(${prop.module_param.hover_logo})`)
    const brand=document.getElementsByClassName('BrandsMob')
    const listener = throttle(() => {
      const isinview = isInViewPort(brand) // 是否进入了可视区域
      setTitle.start({opacity: isinview ? '1':'0'})
      setBorder.start({ width: isinview ? '100%':'0'})
    }, 50)
    document.addEventListener('scroll', listener)
    return ()=>{
      document.removeEventListener('scroll', listener)
    }
  },[])
  return (
    <section className='BrandsMob'>
      {/* 标题 */}
      <h2 className='h2'>
        {
          is_Zh&&
          <animated.span className="brandTitle" style={title}>
            {prop.module_param.title_zh}
          </animated.span>
        }
        <animated.span className="brand" style={title}>{prop.module_param.sub_title}</animated.span>
        <animated.span className="hint" style={title}>{prop.module_param.mkt_title}</animated.span>
      </h2>
      <animated.div className="border" style={border}></animated.div>
      {/* 精灵图 */}
      <ul className='picture'>
        {
          prop.module_data[0].children.map((item, index)=>{
            return(
              <li key={item.sid}>
                <div
                  className={`photos ${(allBrand&&'showNo')||''} ${(randomNum.includes(index)&&((allBrand&&'showLight')||'active'))||'unactive'}`}
                  style={{
                    animationDelay: `${Math.random()*5}s`
                  }}
                >
                  <div
                    className={`img`}
                    style={{
                      backgroundPosition: `-${index*200}px`,
                      width: '200px',
                      height: '100px'
                    }}></div>
                </div>
              </li>
            )
          })
        }
      </ul>
      {/* 分类标题 */}
      {/* <ul className='titles'>
        <div className='bottom'>
          {
            obj.module_data.map((item,index)=>{
              return(
                <li key={item.id} className='brand'>
                  <div className={`name ${light===item.id?'changeWhite':''}`} onClick={()=>{showBrand(item.id)}} >{item.title}</div>
                </li>
              )
            })
          }
        </div>
      </ul> */}
    </section>
  )
}

export default Fc