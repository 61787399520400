import React,{useState,useEffect} from 'react';
import Title from '../TitleHor/TitleHor'
import style from './DevelopHistory.module.scss'

interface DevelopHistoryData{
  module_data:{
    Display_time:string
    describe:string
    mob_image_url:string
  }[]
  module_param:{
    title_zh:string
    sub_title:string
  }
}
interface DevelopData{
  Display_time:string
  describe:string
  mob_image_url:string
}
//发展历程
const DevelopHistory: React.FC<DevelopHistoryData> = (prop) => {
  const title ={
    TitleHor_ch:prop.module_param.title_zh,
    TitleHor_eg:prop.module_param.sub_title
  }
  // 点击哪个时间
  const [select, setSelect]=useState<number>(0)
  const [pageData,setPageData]=useState<DevelopData[]>(prop.module_data)
  useEffect(()=>{
    const arr:DevelopData[]=prop.module_data.map((item)=>{
      if(item.describe){
        item.describe=item.describe.replace(/\n/g,'<br/>')
      }
      return item
    })
    setPageData(arr)
  },[prop.module_data])
  return (
    <section className={style.DevelopHistoryMob}>
      <Title {...title} other_title={prop.module_param.sub_title}/>
      <div className='developBox'>
        <div className='topItem'>
          <div className='left'>
            <div className='time'>
              {/* 时间轴 */}
              {
                prop.module_data.map((item,index)=>{
                  return(
                    <div key={index} className='timeItem'>
                      <div className='rod'></div>
                      {/* 具体时间 */}
                      <div 
                        className='timeDetaile' 
                        onClick={()=>{setSelect(index)}}
                        style={{color:select===index?'#F38400':'#FFFFFF'}}
                      >{item.Display_time}</div>
                    </div>
                  )
                })
              }
            </div>
          </div>
          {/* 右侧图片 */}
          <div className='right'>
            {
              prop.module_data[select].mob_image_url&&
              <img src={prop.module_data[select].mob_image_url} alt="" className='photo'/>
            }
            </div>
        </div>
        {/* 详细信息 */}
        <div className='bigTime'>{isNaN(Number(pageData[select].Display_time)) ? pageData[select].Display_time : `${pageData[select].Display_time}`}
        </div>
        <div className='word' dangerouslySetInnerHTML={{__html: pageData[select].describe}}></div>
      </div>
    </section>
  )
}

export default DevelopHistory