
import './Graphical_mob.scss'

//图形

// 圆形
export const Circular= (prop:any) => {
  return(
    <div className='circular1'>
      <div className='topLine' style={{animationName:prop.open?'openLines':''}}></div>
      <div className='round'>
        <div className='text1 fontSize'>{prop.letter}</div>
        <div className='boxCircle'>
          <div className='text fontSize'>{prop.letter2}</div>
        </div>
      </div>
      <div className='bottomLine' style={{animationName:prop.open?'openLines':'',animationDelay:prop.open?'1.5s':''}}></div>
    </div>
  )
}
// 正方形
export const Square = (prop:any) => {
  return(
    <div className='squareBox1'>
      <div className='topLine' style={{animationName:prop.open?'openLine':''}}></div>
      <div 
        className='cube'
        style={{
          height:prop.height,
          width:prop.width,
          transform:prop.rotate
        }}
      >
        <div className='text fontSize'>
          <div className='egOne'>{prop.letter}</div>
        </div>
        <div className='text1 fontSize'>{prop.letter2}</div>
      </div>
    </div>
  )
}
// 菱形
export const Diamond = (prop:any) => {
  return(
    <div className='rhomb'>
      <div className='topLine' style={{animationName:prop.open?'openLines':''}}></div>
      <div 
        className='lozenge'
        style={{
          height:prop.height,
          width:prop.width,
          transform:prop.rotate
        }}
      >
        <div className='text fontSize'>{prop.letter}</div>
        <div className='text1 fontSize'>{prop.letter2}</div>
      </div>
      <div className='bottomLine' style={{animationName:prop.open?'openLines':'',animationDelay:prop.open?'1.5s':''}}></div>
    </div>
  )
}
//三角形
export const Triangle = (prop:any) => {
  return(
    <div className='square1'>
      <div 
        className='topLine' 
        style={{
          animationName:prop.open?'openLine':'',
        }}
      ></div>
      <div className='leftGrap'>
        <div className='line'></div>
        <div className='text fontSize'>{prop.letter}</div>
        <div className='text1 fontSize'>
          <div className='egOne'>{prop.letter2}</div>
        </div>
      </div>
    </div>
  )
}
//翻转三角形
export const TriangleFlip = (prop:any) => {
  return(
    <div className='squareFlip'>
      <div 
        className='topLine' 
        style={{
          animationName:prop.open?'openLineThree':'',
        }}
      >
      </div>
      <div className='leftGrapFlip'>
        <div className='line'></div>
        <div className='oneLetter'>
          <div className='text fontSize'>{prop.letter}</div>
        </div>
        <div className='text1 fontSize'>
          <div className='egOne'>{prop.letter2}</div>
        </div>
      </div>
    </div>
  )
}
//正六边形
export const Hexagon = (prop:any) => {
  return(
    <div className='shape1'>
      <div className='text'>
        <div className='egOne'>S</div>
      </div>
      <div className='text1'>
        <div className='egTwo'>C</div>
      </div>
      <div className='topGrap'>
        <div className='one'></div>
        <div className='two'></div>
        <div className='three'></div>
      </div>
      <div 
        className='bottomLine' 
        style={{animationName:prop.open?'openLine':''}}
      ></div>
      <div className='bottomLine1'></div>
    </div>
  )
}
//长方形
export const Rectangle = (prop:any) => {
  return(
    <div className='oblong1'>
      <div className='topLine' style={{animationName:prop.open?'openLineOne':""}}></div>
      <div className='rectangle1'>
        <div className='text fontSize'>C</div>
        <div className='text1 fontSize'>S</div>
      </div>
      <div className='bottomLine' style={{animationName:prop.open?'openLineTwo':"",animationDelay:prop.open?'1.5s':''}}></div>
    </div>
  )
}

