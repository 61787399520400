import React from 'react';
import Title from '../TitleHor/TitleHor'
import style from './OverseasUnder.module.scss'
import Over from '@/assets/img/over.jpg'
import Paris from '@/assets/img/Paris1.png'
import NewYork from '@/assets/img/New York1.png'
interface OverseasData{
  OverseasUnder_ch:string,
  OverseasUnder_eg:string,
  OverseasUnder_img:string
}
//标题
const OverseasUnder: React.FC<OverseasData> = (prop) => {
  const title ={
    TitleHor_ch:'海外事业',
    TitleHor_eg:'Overseas undertaking'
  }
  return (
    <section className={style.OverseasUnderMob}>
      <Title {...title} other_title={'Overseas Business'}/>
      <div className={style.moduleBlock}>
        <img src={Paris} alt=""  className='paris'/>
        <img src={NewYork} alt="" className='newYork'/>
        {
          Over&&
          <img src={Over} alt="" className='imgs'/>
        }
        <div className='line'></div>
        <div className='eng'>{prop.OverseasUnder_eg}</div>
        <div className='names'>{prop.OverseasUnder_ch}</div>
      </div>
    </section>
  )
}
OverseasUnder.defaultProps={
  OverseasUnder_ch:'在纽约、巴黎和米兰成立在分公司，开拓当地业务、孵化当地新锐设计师品牌，全面布局和参与未来世界奢侈品时尚行业的发展。',
  OverseasUnder_eg:'Milan',
  OverseasUnder_img:'https://www.buyquickly.com/sites/default/files/capability/2018-10/about%20us-warehouse.jpg'
}
export default OverseasUnder