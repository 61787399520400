const devBaseUrl = '';
const BaseUrl = ''
const node_env = process.env.NODE_ENV;

function getBaseUrl() {
  if(node_env === 'development') {
    return {
      BASE_URL: devBaseUrl,
      PREFIX: '/api'
    }
  } else {
    return {
      BASE_URL: BaseUrl,
      PREFIX: ''
    }
  }
}
const baseConfig = getBaseUrl();
export const BASE_URL = baseConfig.BASE_URL;
export const PREFIX = baseConfig.PREFIX;
export const TIMEOUT = 60000;