import React, { CSSProperties, useEffect } from 'react';
import CardOpen from '@/components/CommonComponents/pc/CardOpen/CardOpen';
import style from './TwoImage.module.scss'
import objectFitImages from 'object-fit-images';

interface ItemProps {
  src: string
  link?: string
  width?: string
}

interface TwoImageProps {
  list: ItemProps[]
}

const list:ItemProps[]  = [
  {
    src: 'https://moose-ows-res.ibaiqiu.com/moose/cms/20211210/162059.jpg',
    width: '60%',
  },
  {
    src: 'https://moose-ows-res.ibaiqiu.com/moose/cms/20211210/162059.jpg',
    width: '30%',
  },
]

const FC: React.FC<TwoImageProps> = (props) => {
  useEffect(() => {
    // 解决ie不兼容css: objec-fit属性
    const img: HTMLElement|null = document.querySelector(`.${style.img}`)
    objectFitImages(img)
  })
  // 随机classm名
  const cardClassName = 'cardopen_' + Math.random().toString().slice(2)
  const clickEvent = (link: string|undefined) => {
    if(link) {
      window.location.href = link
    }
  }
  return (
    <div className={style.twoImage}>
      {
        list.map((item, index) => {
          return (
            <div style={{width: item.width}} className='imageItem'>
              <CardOpen key={index} cardClassName={cardClassName} width='100%' style={{marginTop: '0'}} bodyStyle={{padding: 0}} inviewTime={300}>
                <img
                  alt=''
                  onClick={()=>clickEvent(item.link)}
                  src='https://twks.ficelle.app/v1/?src=https%3A%2F%2Ftwks.ch%2Fworkspace%2Fuploads%2Fmodules%2F01_cda_home_v0-fr-1591806747.jpg&width=2000'
                  className='img'
                  style={{
                    cursor: item.link ? 'pointer' : ''
                  }}
                ></img>
              </CardOpen>

            </div>
          )
        })
      }
    </div>
  )
}

FC.defaultProps = {
  list: []
}

export default FC