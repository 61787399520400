import 'core-js/es'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'

// 国际化
import './i18n';
import App from './App'
import reportWebVitals from './reportWebVitals'
import getWindowPage from './utils/pages'
import '@/assets/css/global.scss'

if (process.env.NODE_ENV === 'development') {
	// @ts-ignore
  React.Component.prototype.page = window.page = getWindowPage()
} else {
	// @ts-ignore
  React.Component.prototype.page = window.page
}


ReactDOM.render(
	// <React.StrictMode>
	// 	<App />
	// </React.StrictMode>
		<App />
	,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
