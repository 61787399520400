import News from './TopBanner/TopBanner'
import Banner from './TopPicture/TopPicture'
import CaseLight from './CaseLight/CaseLight'
import Introduce from './Introduce/Introduce'
import OtherCase from './OtherCase/OtherCase'
import TopBanner from './TopBanner/TopBanner'
import TimeIntroduce from './TimeIntroduce/TimeIntroduce'
const comp={
  News,
  Banner,
  CaseLight,
  Introduce,
  OtherCase,
  Casehead: TopBanner,
  TimeIntroduce
}
export default comp