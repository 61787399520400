import React from 'react';
import style from './index.module.scss';
interface  letter{
  letter:string,
  letter2:string
}
// 正方形Square
const FC:React.FC <letter>= (props) => {
  return (
    <div className={style.Square}>
    {/* 第一个字母 */}
    <div className={`${style.text} first_text`}>
      <div className='letter_box'>
        <span className='letter'>{props.letter}</span>
      </div>
    </div>
    {/* 第二个字母 */}
    <div className={`${style.text} last_text`}>
      <div className='letter_box'>
        <span className='letter'>{props.letter2}</span>
      </div>
    </div>
    </div>
  )
}

export default FC
