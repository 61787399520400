import React from 'react';
import New from '@/components/PcComponents/homeMods/News/News'

// 新闻
const Fc: React.FC = () => {
  
  return (
    // <New title_ch={'推荐新闻'} title_eg={'Recommended news'}></New>
    <div></div>
  )
}

export default Fc