import React from 'react'
import { useTranslation } from 'react-i18next'
import recruit from '@/assets/img/recruit.jpg'
import style from './JoinMethods.module.scss'
// 加入方式
// @ts-ignore
const is_Zh: boolean = localStorage.getItem('store_view') !== 'En'
const JoinMethods: React.FC = () => {
	const { t } = useTranslation()

	return (
		<section className={style.join}>
			<header className="header">
				{is_Zh && <span className="zh">{t('加入方式')}</span>}
				<span className="cn">Joining Path</span>
			</header>
			<ul className="list">
				<li>
					<a href="https://jobs.51job.com/all/co3062282.html">
						<img
							src="https://res.ibaiqiu.com/BuyQuickly/cms/20230703/165000.png"
							alt=""
						/>
						<p className="fw600">{t('前程无忧')}</p>
						<p>{t('在线投递简历')}</p>
					</a>
				</li>
				<li>
					<a href="https://company.zhaopin.com/CZ574895020.htm">
						<img
							src="https://res.ibaiqiu.com/BuyQuickly/cms/20230703/164934.png"
							alt=""
						/>
						<p className="fw600">{t('智联招聘')} </p>
						<p>{t('在线投递简历')}</p>
					</a>
				</li>
				<li>
					<a href="https://www.liepin.com/company/6593930/">
						<img
							src="https://res.ibaiqiu.com/BuyQuickly/cms/20230703/164700.png"
							alt=""
						/>
						<p className="fw600"> {t('猎聘')}</p>
						<p>{t('在线投递简历')}</p>
					</a>
				</li>
				<li>
					<img className="joinImg" src={recruit} alt="" />
					<p className="fw600">{t('关注百秋招聘官网微信公众号')}</p>
					<p>{t('了解最新招聘信息')}</p>
				</li>
			</ul>
		</section>
	)
}

export default JoinMethods
