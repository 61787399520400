import React from 'react';
import style from './index.module.scss';
interface  letter{
  letter:string,
  letter2:string
}
// 三角形
const FC:React.FC<letter> = (props) => {
  return (
    // 盒子通过边框做的白色三角形
    <div className={style.TriangleLeft}>
      {/* 黑色三角形 */}
      <div className='triangle_bg'></div>
      {/* 第一个字母 */}
      <div className={`${style.text} first_text`}>
        <div className='letter_box'>
          <span className='letter'>{props.letter}</span>
        </div>
      </div>
      {/* 第二个字母 */}
      <div className={`${style.text} last_text`}>
        <div className='letter_box'>
          <span className='letter'>{props.letter2}</span>
        </div>
      </div>
    </div>
  )
}

export default FC
