import React from 'react'
import { useTranslation } from 'react-i18next'

import style from './index.module.scss'

const Seconded: React.FC = props => {
	const { t } = useTranslation()

	return <div className={style.seconded}>{t('附')} </div>
}

export default Seconded
