import React,{useEffect,useState} from 'react'
import Components from '@/components/MobComponents/serverMods/ServiceDetaile';
import { getCms } from '@/api/apis';

// 店铺运营
const AllChannelsOperating: React.FC = () => {
  interface comTtem {
    module_id: string,
    module_data: []
    module_param: []
    module_remark:string
    module_title:string
    parent_id:string
    sort:number
  }
  const [data,setData]=useState<Array<comTtem>>([])
  useEffect(() => {
    getCms({
      params: { terminal: 'Ws_Pc', content_id: 'store_operation', param_id: 'store_operation' }
    }).then((res: any) => {
    if(res){
      setData(res.data.page_data)
    }
  })
    return () => {
      setData([])
    }
  },[])
  return (
    <>
      {
        data.map((item, index) => {
          const ServiceDetaile: any = Components[item['module_id']]
          return ServiceDetaile && <ServiceDetaile key={index} {...item} />
        })
      }
    </>
  )
}

export default AllChannelsOperating
