import React, { useEffect,useState }  from 'react';
import { useSpring, animated } from 'react-spring';
import { throttle, isInViewPort } from '@/utils/index';
import './BottomPicture.scss'

interface timeItem {
  id: string
  mob_image_url:string
  pc_image_url:string
}
interface PictureData{
  picture1?:string
  picture2?:string
}
//底部图片组
const BottomPicture: React.FC<timeItem[]> = (prop) => {
  const [open1,setOpen1]=useSpring(()=>({width:'0',height:'0',config:{duration: 500,transitionProperty: 'all'}}))
  const [open2,setOpen2]=useSpring(()=>({width:'0',height:'0',config:{duration: 500,transitionProperty: 'all'}}))
  const [open3,setOpen3]=useSpring(()=>({width:'0',height:'0',config:{duration: 500,transitionProperty: 'all'}}))
  const [pictures,showPicture]=useState<PictureData>({})
  useEffect(() => {
    showPicture(Object.assign({},{picture1:prop[3].pc_image_url},{picture2:prop[4].pc_image_url}))
  }, [])
  //图片组
  const Photos = (props:PictureData)=>{
    useEffect(() => {
      const topPhoto=document.getElementsByClassName('bottomPhoto')
      const listener = throttle(() => {
        const isinview = isInViewPort(topPhoto) // 是否进入了可视区域
        if(isinview){
          setOpen1.start({
            width: isinview ? '100%':'0',
            height: isinview ? '100%':'0',
          })
          setOpen2.start({
            width: isinview ? '100%':'0',
            height: isinview ? '100%':'0',
          })
        }
      }, 50)
      document.addEventListener('scroll', listener)
        return () => {
        document.removeEventListener('scroll', listener)
      }
    }, [setOpen1,setOpen2])
    return(
      <div className='bottomPhoto'>
        <div className='outsideLeft'>
          {
            props.picture1&&
            <animated.img 
              src={props.picture1}
              alt="" 
              className='rightPhoto'
              style={open2}
            >
            </animated.img>
          }
        </div>
        <div className='outside'>
          {
            props.picture2&&
            <animated.img 
              src={props.picture2} 
              alt="" 
              className='leftPhoto'
              style={open1}
            >
            </animated.img>
          }
        </div>
      </div>
    )
  }
  //大图片
  const BigPhoto=(prop:timeItem)=>{
    useEffect(() => {
      const bottomPhoto=document.getElementsByClassName('bigBottom')
      const listener = throttle(() => {
        const isinview2 = isInViewPort(bottomPhoto) // 是否进入了可视区域
        if(isinview2){
          setOpen3.start({
            width: isinview2 ? '100%':'0',
            height: isinview2 ? '100%':'0',
          })
        }
      }, 50)
      document.addEventListener('scroll', listener)
        return () => {
        document.removeEventListener('scroll', listener)
      }
    }, [])
    return(
      <div className='bigBottom'>
        {
          prop.pc_image_url&&
          <animated.img 
            src={prop.pc_image_url} 
            alt="" 
            style={open3}
            className='photoItem'
          >
          </animated.img>
        }
      </div>
    )
  }
  return (
    <section className='BottomPictureMob'>
      <div className='pictureInform'>
        <Photos {...pictures}></Photos>
        <BigPhoto {...prop[5]}></BigPhoto>
      </div>
    </section>
  )
}
export default BottomPicture