/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState,useEffect} from 'react';
import style from './SubNavHeader.module.scss';

interface NavItem {
  name: string
  href: string
  actives: string[]
}

interface SubProps {
  title_zh: string
  title_cn: string
  tabs?: NavItem[]
}



// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'

const FC: React.FC<SubProps> = props => {
  const [active,  setActive] = useState(-1)
  useEffect(()=>{
    const path = window.location.pathname
    if(props.tabs){
      let index = props.tabs.findIndex(item => item.actives.includes(path))
      setActive(index)
    }
  },[])
  return (
    <section className={style.section}>
      <header className='title'>
        {
          is_Zh && <span className='zh'>{ props.title_zh }</span>
        }
        <span className='cn'>{ props.title_cn }</span>
      </header>
      <div className='nav'>
        {
          props.tabs&&props.tabs.map((item, index) => (
            <a
              key={item.href}
              href={item.href}
              className={`navItem ${index===active ? 'active' : ''}`}
            >
              {item.name}
            </a>
          ))
        }
      </div>
    </section>
  )
}

export default FC