import React,{ useState, CSSProperties, useEffect } from 'react';
import { throttle, isInViewPort } from '@/utils/index';
import style from './CardOpen.module.scss'

// 伸缩卡片

interface CardProps  {
  cardClassName: string // 盒子类名
  width: string // 盒子宽度
  defalutOpen?: boolean // 是否默认打开。
  inviewTime?: number // 超出底部多少px时开启动画，默认为200
  style?: CSSProperties // 盒子的样式
  bodyStyle?: CSSProperties
}

const Card: React.FC<CardProps> = (props) => {
  const [open, setopen] = useState(props.defalutOpen||false)
  useEffect(()=>{
    const El = document.getElementsByClassName(`${props.cardClassName}`)

    // 监听滚动事件，throttle节流函数
    const listener = throttle(() => {
      const isinview = isInViewPort(El, props.inviewTime||200) // 是否进入了可视区域
      if(isinview!==open)setopen(isinview)
    }, 50)
    document.addEventListener('scroll', listener)
    return () => {
      document.removeEventListener('scroll', listener)
    }
  },[props.inviewTime, props.cardClassName])

  return (
    <div className={`${style.bq_card_open} ${props.cardClassName}`} style={{...props.style, width:props.width}}>
      <div className="open_body" style={{...props.bodyStyle}}>
        <div
          className="open_transition"
          style={{
            animationName: open ? 'open_key' : 'colse_key'
          }}
        >
          {
            props.children
          }
        </div>
      </div>
    </div>
  )
}

export default Card