import React, { useEffect, useState } from 'react';
import { throttle, isInViewPort } from '@/utils/index';
import { useSpring, animated } from 'react-spring';
import JiaPng from '@/assets/img/jia.png';
import { useTranslation } from 'react-i18next';
import JianPng from '@/assets/img/jian.png';
import objectFitImages from 'object-fit-images';

import { Collapse } from 'antd';

import './Growth.scss'
// @ts-ignore
const is_Zh: boolean = localStorage.getItem('store_view') !== 'En'
// 我们的优势组件

const { Panel } = Collapse;
interface GrowthData {
  data: {
    module_data: {
      title_zh: string
      sub_title: string
      pc_image_url: string
      content: string
    }[]
  }
}
interface PanelHead {
  title_zh: string
  sub_title: string
  isopen: boolean
  index: number
}
interface PanelCont {
  pc_image_url: string
  content: string
  isopen: boolean
}

// 自定义折叠面板头部
const PanelHeader: React.FC<PanelHead> = (props) => {

  return (
    <div className="panel_header" >
      <div className="panel_zh" style={{ opacity: props.isopen ? 1 : 0.5 }} >
        {props.title_zh}
      </div>
      <div className="panel_en" style={{ opacity: props.isopen ? 1 : 0.5 }}>
        {props.sub_title}
      </div>
      <img src={props.isopen ? JianPng : JiaPng} className="icon" alt="" />
    </div>
  )
}

// 自定义折叠面板内容
const PanelContent: React.FC<PanelCont> = (props) => {
  useEffect(() => {
    // 解决ie不兼容css: objec-fit属性
    const img = document.querySelectorAll('.cont_img')
    objectFitImages(img)

  })
  return (
    <div className='panel_cont' style={{ animationName: props.isopen ? 'panel_cont_open' : 'panel_cont_close' }} id='grow_pc_panel'>
      {
        props.pc_image_url &&
        <img src={props.pc_image_url} className="cont_img" alt="" />
      }
      <div className="cont" dangerouslySetInnerHTML={{ __html: props.content }}></div>
    </div>
  )
}

// 折叠面板
const Fc: React.FC<GrowthData> = (prop) => {
  const { t } = useTranslation()
  // 进入可视区域后，默认只加载一次动画
  const [inView, setInView] = useState<boolean>(false)
  // 打开的折叠面板
  const [openlist, setopenlist] = useState<string[]>([])
  // 头部title的spring动画
  const [title, setTitle] = useSpring(() => ({ opacity: '1', width: '0' }))

  useEffect(() => {
    const el = document.getElementsByClassName('GrowthPc')
    // 监听滚动条
    const listener = throttle(() => {
      if (!inView) {
        // 是否进入了可视区域
        const isinview = isInViewPort(el)
        // 记录已经进入可视区域
        setInView(isinview)
        // 开启动画
        setTitle.start({
          opacity: isinview ? '1' : '0',
          width: isinview ? '100%' : '0',
          config: {
            duration: isinview ? 1000 : 0 // 动画持续时间ms
          }
        })
      }
    }, 50)
    document.addEventListener('scroll', listener)
    return () => {
      document.removeEventListener('scroll', listener)
    }
  }, [setTitle, inView])
  // 切换面板的回调
  function callback(key: any) {
    if (key) {
      setopenlist([key])
      const el = document.getElementById(`panel_item${key}`)
      const top=el?.offsetTop
      const el2:any = document.querySelectorAll(`#grow_pc_panel`)
      let height
      let initHeight
      if(top){
        if(el2.length!==0){
          height=window.getComputedStyle(el2[openlist[0]]).height
          height=height.slice(0,-2)
        }
        if(openlist[0]&& key > Number(openlist[0])){
          initHeight=top-(Number(height)||0)
        }else{
          initHeight=top-122
        }
      }
      if (el) {
        window.scrollTo({
          top: initHeight,
          behavior: "smooth"
        });
      }
    } else {
      setopenlist([])
    }
  }

  return (
    <section className='GrowthPc' id='GrowthPc'>
      <animated.h2 className='h2' style={title}>
        <span className={`title_zh ${is_Zh ? "zh" : "cn"}`} >{t('成长晋升')}</span>
      </animated.h2>
      <Collapse
        defaultActiveKey={[]}
        onChange={callback}
        bordered={false}
        accordion
      >
        {
          prop.data.module_data.map((item, index) => {
            return (
              <Panel
                header={
                  <PanelHeader
                    title_zh={item.title_zh}
                    sub_title={item.sub_title}
                    isopen={openlist.includes(`${index}`)}
                    index={index}
                  />
                }
                className='panel_item'
                id={`panel_item${index}`}
                key={index}
                showArrow={false}
                style={{
                  animationName: inView ? 'panelWidth' : '',
                  animationDelay: `${(index + 1) * 0.1}s`
                }}
              >
                <PanelContent pc_image_url={item.pc_image_url} content={item.content} isopen={openlist.includes(`${index}`)} />
              </Panel>
            )
          })
        }
      </Collapse>

    </section>
  )
}

export default Fc