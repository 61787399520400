import React, { useEffect,useState }  from 'react'
import Components from '@/components/PcComponents/newsMods';
import { getCms } from '@/api/apis';
interface comTtem {
  title_zh: string
  sub_title: string
  icon: string
  content: string,
}
const data = [
  {
    zh_title: '奢品',
    cn_title: 'Luxury',
    icon: '',
    content: `
      <p>数据银行认证服务商 </p>
    `,
  },
  {
    zh_title: '奢品',
    cn_title: 'Luxury',
    icon: '',
    content: `
      <p>数据银行认证服务商 </p>
      <p>阿里巴巴“十佳数据先锋”奖 </p>
      <p>阿里巴巴数据生态服务大会“2020年度优秀内容数据服务商” </p>
      <p>数据银行认证服务商 </p>
      <p>阿里巴巴“十佳数据先锋”奖 </p>
      <p>阿里巴巴数据生态服务大会“2020年度优秀内容数据服务商” </p>
      <p>天猫&《哈佛商业评论》“2021年数字化先锋案例合作伙伴奖” </p>
      <p>服务BALENCIAGA，获天猫&《哈佛商业评论》“2021年数字化先锋案例奖” </p>
    `,
  },
  {
    zh_title: '奢品',
    cn_title: 'Luxury',
    icon: '',
    content: `
      <p>数据银行认证服务商 </p>
      <p>服务BALENCIAGA，获天猫&《哈佛商业评论》“2021年数字化先锋案例奖” </p>
      <p>服务6IXTY8IGHT，获品牌数据银行先锋大赛“实践先锋”奖 </p>
      <p>数据银行认证服务商 </p>
      <p>阿里巴巴“十佳数据先锋”奖 </p>
      <p>阿里巴巴数据生态服务大会“2020年度优秀内容数据服务商” </p>
      <p>天猫&《哈佛商业评论》“2021年数字化先锋案例合作伙伴奖” </p>
      <p>服务BALENCIAGA，获天猫&《哈佛商业评论》“2021年数字化先锋案例奖” </p>
      <p>数据银行认证服务商 </p>
      <p>阿里巴巴“十佳数据先锋”奖 </p>
      <p>阿里巴巴数据生态服务大会“2020年度优秀内容数据服务商” </p>
      <p>天猫&《哈佛商业评论》“2021年数字化先锋案例合作伙伴奖” </p>
      <p>服务BALENCIAGA，获天猫&《哈佛商业评论》“2021年数字化先锋案例奖” </p>
    `,
  },
  {
    zh_title: '奢品',
    cn_title: 'Luxury',
    icon: '',
    content: `
      <p>数据银行认证服务商 </p>
      <p>阿里巴巴“十佳数据先锋”奖 </p>
      <p>阿里巴巴数据生态服务大会“2020年度优秀内容数据服务商” </p>
      <p>天猫&《哈佛商业评论》“2021年数字化先锋案例合作伙伴奖” </p>
      <p>服务BALENCIAGA，获天猫&《哈佛商业评论》“2021年数字化先锋案例奖” </p>
      <p>服务BALENCIAGA，获天猫&《哈佛商业评论》“2021年数字化先锋案例奖” </p>
      <p>数据银行认证服务商 </p>
      <p>阿里巴巴“十佳数据先锋”奖 </p>
      <p>阿里巴巴数据生态服务大会“2020年度优秀内容数据服务商” </p>
      <p>天猫&《哈佛商业评论》“2021年数字化先锋案例合作伙伴奖” </p>
      <p>服务BALENCIAGA，获天猫&《哈佛商业评论》“2021年数字化先锋案例奖” </p>
    `,
  },
  {
    zh_title: '奢品',
    cn_title: 'Luxury',
    icon: '',
    content: `
      <p>数据银行认证服务商 </p>
      <p>阿里巴巴“十佳数据先锋”奖 </p>
      <p>天猫&《哈佛商业评论》“2021年数字化先锋案例合作伙伴奖” </p>
      <p>服务BALENCIAGA，获天猫&《哈佛商业评论》“2021年数字化先锋案例奖” </p>
      <p>服务6IXTY8IGHT，获品牌数据银行先锋大赛“实践先锋”奖 </p>
      <p>服务BALENCIAGA，获天猫&《哈佛商业评论》“2021年数字化先锋案例奖” </p>
      <p>数据银行认证服务商 </p>
      <p>阿里巴巴“十佳数据先锋”奖 </p>
      <p>阿里巴巴数据生态服务大会“2020年度优秀内容数据服务商” </p>
      <p>天猫&《哈佛商业评论》“2021年数字化先锋案例合作伙伴奖” </p>
      <p>服务BALENCIAGA，获天猫&《哈佛商业评论》“2021年数字化先锋案例奖” </p>
    `,
  },
  {
    zh_title: '奢品',
    cn_title: 'Luxury',
    icon: '',
    content: `
      <p>数据银行认证服务商 </p>
      <p>阿里巴巴“十佳数据先锋”奖 </p>
      <p>阿里巴巴数据生态服务大会“2020年度优秀内容数据服务商” </p>
      <p>服务BALENCIAGA，获天猫&《哈佛商业评论》“2021年数字化先锋案例奖” </p>
      <p>服务6IXTY8IGHT，获品牌数据银行先锋大赛“实践先锋”奖 </p>
      <p>服务BALENCIAGA，获天猫&《哈佛商业评论》“2021年数字化先锋案例奖” </p>
      <p>数据银行认证服务商 </p>
      <p>阿里巴巴“十佳数据先锋”奖 </p>
      <p>阿里巴巴数据生态服务大会“2020年度优秀内容数据服务商” </p>
      <p>天猫&《哈佛商业评论》“2021年数字化先锋案例合作伙伴奖” </p>
      <p>服务BALENCIAGA，获天猫&《哈佛商业评论》“2021年数字化先锋案例奖” </p>
      <p>服务BALENCIAGA，获天猫&《哈佛商业评论》“2021年数字化先锋案例奖” </p>
      <p>数据银行认证服务商 </p>
      <p>阿里巴巴“十佳数据先锋”奖 </p>
      <p>阿里巴巴数据生态服务大会“2020年度优秀内容数据服务商” </p>
      <p>天猫&《哈佛商业评论》“2021年数字化先锋案例合作伙伴奖” </p>
      <p>服务BALENCIAGA，获天猫&《哈佛商业评论》“2021年数字化先锋案例奖” </p>
    `,
  },
  {
    zh_title: '奢品',
    cn_title: 'Luxury',
    icon: '',
    content: `
      <p>数据银行认证服务商 </p>
      <p>阿里巴巴“十佳数据先锋”奖 </p>
      <p>阿里巴巴数据生态服务大会“2020年度优秀内容数据服务商” </p>
      <p>服务BALENCIAGA，获天猫&《哈佛商业评论》“2021年数字化先锋案例奖” </p>
      <p>数据银行认证服务商 </p>
      <p>阿里巴巴“十佳数据先锋”奖 </p>
      <p>阿里巴巴数据生态服务大会“2020年度优秀内容数据服务商” </p>
      <p>天猫&《哈佛商业评论》“2021年数字化先锋案例合作伙伴奖” </p>
      <p>服务BALENCIAGA，获天猫&《哈佛商业评论》“2021年数字化先锋案例奖” </p>
      <p>服务BALENCIAGA，获天猫&《哈佛商业评论》“2021年数字化先锋案例奖” </p>
      <p>数据银行认证服务商 </p>
      <p>阿里巴巴“十佳数据先锋”奖 </p>
      <p>阿里巴巴数据生态服务大会“2020年度优秀内容数据服务商” </p>
      <p>天猫&《哈佛商业评论》“2021年数字化先锋案例合作伙伴奖” </p>
      <p>服务BALENCIAGA，获天猫&《哈佛商业评论》“2021年数字化先锋案例奖” </p>
      <p>天猫&《哈佛商业评论》“2021年数字化先锋案例合作伙伴奖” </p>
    `,
  },
  {
    zh_title: '奢品',
    cn_title: 'Luxury',
    icon: '',
    content: `
      <p>服务BALENCIAGA，获天猫&《哈佛商业评论》“2021年数字化先锋案例奖” </p>
      <p>服务6IXTY8IGHT，获品牌数据银行先锋大赛“实践先锋”奖 </p>
      <p>服务BALENCIAGA，获天猫&《哈佛商业评论》“2021年数字化先锋案例奖” </p>
      <p>数据银行认证服务商 </p>
      <p>阿里巴巴“十佳数据先锋”奖 </p>
      <p>阿里巴巴数据生态服务大会“2020年度优秀内容数据服务商” </p>
      <p>天猫&《哈佛商业评论》“2021年数字化先锋案例合作伙伴奖” </p>
      <p>服务BALENCIAGA，获天猫&《哈佛商业评论》“2021年数字化先锋案例奖” </p>
    `,
  },
]


const FC: React.FC = () => {
  const NewsHeader = Components.NewsHeader
  const NewsHonorList = Components.NewsHonorList
  const [data,setData]=useState<Array<comTtem>>([])
  function getNewsList() {
    getCms({
      params: { terminal: 'Ws_Pc', content_id: 'Honors', param_id: 'Honors' }
    }).then((res: any) => {
      if (res.data?.page_data) {
        setData(res.data?.page_data[2].module_data)
      }
    })
  }
  useEffect(()=>{
    getNewsList()
  },[])
  return (
    <div>
      <NewsHeader />
      <NewsHonorList data={data} />
    </div>
  )
}
export default FC
