import React, { useEffect, useState } from 'react'
import { throttle, isInViewPort } from '@/utils/index'
import './FourEcharts.scss'

// 圆环与动画思路参考[http://caibaojian.com/css3-round.html]
// @ts-ignore
const is_Zh: boolean = localStorage.getItem('store_view') !== 'En'
const Fc: React.FC = () => {
	const data = [
		{
			num: '1',
			title: '个赛道',
			title_eg: 'Track',
			cont_zh: '专注美的行业',
			cont_en: 'Fashion & Beauty',
			destribe_eg: 'Fashion & Beauty'
		},
		{
			num: '1',
			title: '体化',
			title_eg: 'Integration',
			cont_zh: '未来零售服务',
			cont_en: 'Future Retail Services',
			destribe_eg: 'Future Retail Services'
		},
		{
			num: '120+',
			title: '位',
			cont_zh: '国际品牌客户',
			cont_en: 'International Brands',
			destribe_eg: 'International Brands'
		},
		{
			num: '2800+',
			title: '位',
			cont_zh: '均龄27岁年轻活力团队',
			cont_en: 'Dynamic Team',
			destribe_eg: 'Dynamic Team'
		}
	]
	// 是否进入可视区域
	const [open, setopen] = useState(false)

	useEffect(() => {
		const aboutbqEl = document.getElementsByClassName('fourEcharts')
		// 监听滚动事件，throttle节流函数
		const listener = throttle(() => {
			const isinview = isInViewPort(aboutbqEl) // 是否进入了可视区域
			if (isinview) setopen(true)
		}, 50)
		document.addEventListener('scroll', listener)
		return () => {
			document.removeEventListener('scroll', listener)
		}
	}, [])

	return (
		<section className="fourEcharts">
			<ul className="list">
				{data.map((item, index) => {
					const animationDelay = `${150 * (index + 1)}ms`
					return (
						<li key={index}>
							{/* 外圈大圆环 */}
							<div className="big_circle">
								{/* 大圆环左侧 */}
								<div className="wrapper left">
									{/* 进入可视区域->设置关键帧动画名，开启动画 */}
									<div
										className="circleProgress leftcircle"
										style={{
											animationName: open ? 'circleProgressLoad_left' : '',
											animationDelay
										}}></div>
								</div>
								{/* 大圆环右侧 */}
								<div className="wrapper right">
									{/* 进入可视区域->设置关键帧动画名，开启动画 */}
									<div
										className="circleProgress rightcircle"
										style={{
											animationName: open ? 'circleProgressLoad_right' : '',
											animationDelay
										}}></div>
								</div>
							</div>

							{/* 内圈小圆环 */}
							<div className="small_circle">
								{/* 小圆环左侧 */}
								<div className="wrapper left">
									{/* 进入可视区域->设置关键帧动画名，开启动画 */}
									<div
										className="circleProgress leftcircle"
										style={{
											animationName: open ? 'circleProgressLoad_left' : '',
											animationDelay
										}}></div>
								</div>
								{/* 小圆环右侧 */}
								<div className="wrapper right">
									{/* 进入可视区域->设置关键帧动画名，开启动画 */}
									<div
										className="circleProgress rightcircle"
										style={{
											animationName: open ? 'circleProgressLoad_right' : '',
											animationDelay
										}}></div>
								</div>

								{/* 两个半圆环组合后会有间隙，下面这个div是动画加载结束后覆盖组合的小圆环 */}
								<div
									className="small_circle_success"
									style={{
										animationName: open ? 'circleProgressLoad_success' : '',
										animationDelay
									}}></div>
							</div>

							<div
								className="circle_content"
								style={{
									animationName: open ? 'open_cont' : '',
									animationDelay
								}}>
								<div className="cont">
									<div className="cont_top">
										<i className="num">{item.num}</i>
										{is_Zh && <span className="title">{item.title}</span>}
									</div>
									{is_Zh && <div className="cont_zh">{item.cont_zh}</div>}

									<div
										className={`cont_en ${is_Zh ? 'cont_en' : 'cont_eg'}`}
										style={{
											width: index === 0 || index === 3 ? '220px' : ''
										}}>
										{is_Zh ? item.cont_en : item.destribe_eg}
									</div>
								</div>
							</div>
						</li>
					)
				})}
			</ul>
		</section>
	)
}

export default Fc
