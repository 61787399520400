import React from 'react';
// import { useSpring } from 'react-spring';
// import { throttle, isInViewPort } from '@/utils/index';
import CenterPicture from '../CenterPicture/CenterPicture'
import WordContent from '../WordContent/WordContent'
import BottomPicture from '../BottomPicture/BottomPicture'
import './Introduce.scss'

interface caseData {
  module_data: caseItem[]
  module_param:{
    sub_title:string
    title_zh:string
  }
}
interface caseItem {
  layout: timeItem[]
  content: string
  HTML_subtitle:string
  HTML_title:string
}
interface timeItem {
  id: string
  mob_image_url:string
  pc_image_url:string
}
// interface titleData{
//   HTML_subtitle:string
//   HTML_title:string
// }
//品牌介绍
const Introduce: React.FC<caseData> = (props) => {
  // const [open,setOpen]=useSpring(()=>({
  //   height:'0',
  //   opacity:'0',
  //   config:{duration: 700,transitionProperty: 'all'}}))
  //   const [open1,setOpen1]=useSpring(()=>({
  //     height:'0',
  //     opacity:'0',
  //     config:{duration: 700,transitionProperty: 'all'}}))
  // useEffect(() => {
  //   const pictureInform=document.getElementsByClassName('leftInform')
  //   const pictureInform1=document.getElementsByClassName('rightInform')
  //   const listener = throttle(() => {
  //     const isinview = isInViewPort(pictureInform) // 是否进入了可视区域
  //     const isinview1 = isInViewPort(pictureInform1) // 是否进入了可视区域
  //     setOpen.start({
  //       height: isinview ? '72px':'0',
  //       opacity:isinview ? '1':'0'
  //     })
  //     setOpen1.start({
  //       height: isinview1 ? '44px':'0',
  //       opacity:isinview1 ? '1':'0'
  //     })
  //   }, 50)
  //   document.addEventListener('scroll', listener)
  //   return () => {
  //     document.removeEventListener('scroll', listener)
  //   }
  // }, [setOpen,setOpen1])
  //品牌介绍
  // const Title=(prop:titleData[])=>{
  //   return(
  //     <div className='introduce'>
  //       <animated.div 
  //         className='leftInform' 
  //         dangerouslySetInnerHTML={{__html: prop[0].HTML_title}}
  //         style={open}
  //       >
  //       </animated.div>
  //       <animated.div 
  //         className='rightInform' 
  //         dangerouslySetInnerHTML={{__html: prop[0].HTML_subtitle}}
  //         style={open1}
  //       >
  //       </animated.div>
  //     </div>
  //   )
  // }
  return (
    <section className='IntroduceMob'>
      {/* <div className='pictureInform' >
        <Title {...props.module_data}></Title>
      </div> */}
      <CenterPicture {...props.module_data[0].layout}></CenterPicture>
      <WordContent {...props.module_data[0]}></WordContent>
      <BottomPicture {...props.module_data[0].layout}></BottomPicture>
    </section>
  )
}

export default Introduce