import React, { useEffect, useState } from 'react'
import Components from '@/components/PcComponents/BQMods';
import { getCms } from '@/api/apis';


const BQ: React.FC = () => {
  interface comTtem {
    module_id: string,
    module_data: any
  }

  useEffect(() => {
    getBQData()
  }, [])
  const [BQData, setBQData] = useState<Array<comTtem>>([]);
  function getBQData() {
    getCms({
      params: { terminal: 'Ws_Pc', content_id: 'about', param_id: 'about' }
    }).then((res: any) => {
      if (res) {
        setBQData([...res.data.page_data])
      }
    })
  }
  return (
    <div>
      {
        BQData && BQData.map((item, index) => {
          const BQ: any = Components[item['module_id']]
          if(item.module_id === "DevelopHistory") {
            item.module_data.reverse()
          }
          return BQ && <BQ key={index} {...item}/>
        })
      }
    </div>
  )
}

export default BQ
