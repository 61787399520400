import React, { CSSProperties } from 'react';
import CardFadein from '@/components/CommonComponents/pc/CardFadeIn/CardFadeIn';
import style from './Text.module.scss'

interface TextProps {
  html: string
  style?: CSSProperties
  fadeinStyle?: CSSProperties
  fadeinBodyStyle?: CSSProperties
}

interface TextChildrenProps {
  html: string
  style?: CSSProperties
}

const html = `
<div style="width:100%;">
  <p style="text-align: center;margin-top: 54px;font-size: 13px;">2021/02/04</p>
  <p style="text-align: center;margin-top: 2px;font-size: 19px;line-height: 25px;">上海百秋携Christian Louboutin</p>
  <p style="text-align: center;font-size: 19px;line-height: 25px;">天猫官方旗舰店盛大开业！</p>
  <p style="text-align: center;margin-top: 35px;font-size: 12px;line-height: 18px;">2021年1月27日，Christian Louboutin官方旗舰店已正式登陆天猫，</p>
  <p style="text-align: center;font-size: 12px;line-height: 18px;">与百秋携手开启全新旅程。</p>
  <p style="text-align: center;margin-top: 1.5em;font-size: 12px;line-height: 18px;">Christian Louboutin于1991年在巴黎的让-雅克卢梭大街开设首家专门店，并创立同名品牌。</p>
  <p style="text-align: center;font-size: 12px;line-height: 18px;">设计师Christian Louboutin先生集艺术家与工匠于一身，打造夺目出众的鞋履设计，</p>
  <p style="text-align: center;font-size: 12px;line-height: 18px;">以其标识性“红底鞋履”享誉于世。</p>
</div>
`

const TextChildren: React.FC<TextChildrenProps> = (props) => {
  return (
    <div style={{...props.style}} dangerouslySetInnerHTML={{__html: html}} className='htmlContent'></div>
  )
}

const FC: React.FC<TextProps> = (props) => {
  // 随机classm名
  const textClassName = 'text_' + Math.random().toString().slice(2)
  return (
    <section className={`${style.text} ${textClassName}`} style={{...props.style}}>
      <div className={style.borders}></div>
      <CardFadein fadeinClassName={textClassName}>
        <TextChildren html={props.html} style={props.style} />
      </CardFadein>
    </section>
  )
}

export default FC