import React, { useEffect,useState } from 'react';
import { throttle, isInViewPort } from '@/utils/index';
import { useSpring, animated } from 'react-spring';
// import { useTranslation } from 'react-i18next';
import { TitlesType } from '@/ts/api'
import objectFitImages from 'object-fit-images';
import style from './LeaderTalk.module.scss';
import Title from '../TitleHor/TitleHor'
interface BannerData{
  module_data:NewsData[]
  module_param:TitlesType
}
interface NewsData{
  title_zh:string
  sub_title:string
  pc_source_url:string
  content:string
}
// @ts-ignore
// const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'

const LeaderTalk: React.FC<BannerData>= (props) => {
	// const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)//是否进入可视区域
  const [border, setBorder] = useSpring(() => ({ width: '0', config: { duration: 400 } }))
  useEffect(() => {
    const aboutbqEl = document.getElementsByClassName('introduce')
    const listener = throttle(() => {
      const isinview = isInViewPort(aboutbqEl) // 是否进入了可视区域
      setOpen(isinview)
      setBorder.start({ width: isinview ? '100%' : '0' })
    }, 50)
    document.addEventListener('scroll', listener)
    return () => {
      document.removeEventListener('scroll', listener)
    }
  }, [setBorder])
  useEffect(() => {
    // 解决ie不兼容css: objec-fit属性
    const img = document.querySelectorAll('.contLeftMedia')
    objectFitImages(img)
  })
  return (
    <section className={style.leaderTalkMob}>
      <Title TitleHor_ch={props.module_param.title_zh} TitleHor_eg={props.module_param.sub_title}></Title>
      <animated.div className="border" style={border}></animated.div>
      <div className='content'>
        <div className='leaderPic'>
          {
            props.module_data[0].pc_source_url&&
            <img src={props.module_data[0].pc_source_url} alt="" />
          }
        </div>
        <div className='introduce'>
          <div className={`position ${open?'open_active':''}`}>{props.module_data[0].sub_title}</div>
          <div className={`bqTarget ${open?'open_active':''}`} dangerouslySetInnerHTML={{__html: props.module_data[0].content}}></div>
        </div>
      </div>
    </section>
  );
};

export default LeaderTalk