import React from 'react';
import './TopPicture.scss'


//图片
const TopPicture: React.FC = () => {
  
  return (
    <section className='TopPictureMob'>
      <img src="https://cdn.media.amplience.net/i/mcmworldwide/newdecaw21hphero?w=640&fmt=jpg" alt="" />
    </section>
  )
}

export default TopPicture