import React from 'react';
import './Title.scss'
// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
interface title{
  title_ch:string,
  title_eg:string,
  other_title?:string
}
//标题
const Title: React.FC<title> = (prop) => {
  
  return (
    <section className='TitlePC'>
      <div className='title'>
        {
          is_Zh&&
          <div className='chinese'>{prop.title_ch}</div>
        }
        <div className='english'>{is_Zh?prop.title_eg:prop.other_title}</div>
      </div>
    </section>
  )
}
Title.defaultProps={
  title_ch:'百秋简介',
  title_eg:'Introduction',
  other_title:'Brand Profile'
}
export default Title