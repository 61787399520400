import React, { useEffect } from 'react';
import { throttle, isInViewPort } from '@/utils/index';
import { ArrowRightOutlined } from '@ant-design/icons';
// import objectFitImages from 'object-fit-images';
import { useSpring, animated } from 'react-spring';
import { TitlesType } from '@/ts/api'
import { useTranslation } from 'react-i18next';
import objectFitImages from 'object-fit-images';
import { NewsModuleData } from '@/ts/api';
import './News.scss'
interface BannerData {
  module_data: NewsData[]
  module_param: TitlesType
  newData:NewsModuleData[]
}

interface NewsData {
  Display_time: string
  HTML_title: string
  mob_image_url: string
  pc_image_url: string
  id: string
  sub_title: string
}
// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
// 新闻
const Fc: React.FC<BannerData> = (prop: BannerData) => {
	const { t } = useTranslation()
  const [title, setTitle] = useSpring(() => ({ opacity: '0', config: { duration: 400 } }))
  const [border, setBorder] = useSpring(() => ({ width: '0', config: { duration: 400 } }))
  useEffect(() => {
    const newbqEl = document.getElementsByClassName('newbq')
    const listener = throttle(() => {
      const isinview = isInViewPort(newbqEl) // 是否进入了可视区域
      setBorder.start({ width: isinview ? '100%' : '0' })
      setTitle.start({ opacity: isinview ? '1' : '0' })
    }, 50)
    document.addEventListener('scroll', listener)
    return () => {
      document.removeEventListener('scroll', listener)
    }
  }, [setBorder, setTitle])

  useEffect(() => {
    // 解决ie不兼容css: objec-fit属性
    const img = document.querySelectorAll('.contLeftMedia')
    objectFitImages(img)
  })
  return (
    <section className='NewsMob'>
      {/* 头部新闻 */}
      <h2 className="h2">
        {
          is_Zh &&
          <animated.span className="newTitle" style={title}>新闻</animated.span>
        }
        <animated.span className="newbq" style={title}>News</animated.span>
      </h2>
      <animated.div className="border" style={border}></animated.div>
      {/* 图片 */}
      <div className="content">
        {
          prop.newData&&prop.newData.map((item, index) => {
            return (
              <a href={item.ws_url} key={index}>
                <div className={`${index === 1 ? 'blockTwo' : 'blockOne'}`} >
                  {
                    item.pc_image_url&&
                    <img className={`${index === 1 ? 'pictureTwo' : 'photoOne'}`} src={item.pc_image_url} alt="" />
                  }
                  <div className='bottom'>
                    <div className={`words ${index === 1 ? 'opa' : 'opaHalf'}`} dangerouslySetInnerHTML={{__html:item.HTML_title}}></div>
                    <div className={`time ${index === 1 ? 'opa' : 'opaHalf'}`}>{item.Display_time}</div>
                  </div>
                </div>
              </a>
            )
          })
        }
      </div>
      {/* 按钮 */} 
      <a href="/news">
        <div className="look" >
          <div className={`btn ${is_Zh?'zh':'cn'}`}>{t("查看全部新闻")}</div>
          <ArrowRightOutlined  twoToneColor="#eb2f96"/>
        </div>
      </a>
    </section>
  )
}

export default Fc