import React, { useEffect, useState } from 'react'
import Components from '@/components/MobComponents/CaseMods';
import { getCms } from '@/api/apis';


const Case: React.FC = () => {
  interface comTtem {
    module_id: string
    module_data: []
    module_param: []
    module_remark: string
    module_title: string
    parent_id: string
    sort: number
  }
  // const caseData : comTtem[]=[
  //   {
  //     module_id:'CaseList'
  //   },
  //   {
  //     module_id:'Pages'
  //   },
  // ]
  const [data, setData] = useState<Array<comTtem>>([])
  useEffect(() => {
    getCms({
      params: { terminal: 'Ws_Mob', content_id: 'cases', param_id: 'cases' }
    }).then((res: any) => {
      if (res) {
        setData(res.data.page_data)
      }
    })
    return () => {
      setData([])
    }
  }, [])
  return (
    <>
      {
        data.map((item, index) => {
          const Case: any = Components[item['module_id']]
          return Case && <Case key={index} {...item} />
        })
      }
    </>
  )
}

export default Case
