import React from 'react';
import './TopPhoto.scss'

interface title {
  mob_url: string,
  HTML_title: string
}
interface module {
  module_data: title[]
}
// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'

//大图片
const TopPhoto: React.FC<module> = (prop) => {
  
  return (
    <section className='TopPhotoMob'>
      <div className='topContent'>
        {
          prop.module_data[0].mob_url&&
          <img src={prop.module_data[0].mob_url} alt="" className='backPhoto'/>
        }
        <div className={`target ${is_Zh?'zh':'cn'}`} dangerouslySetInnerHTML={{__html:prop.module_data[0].HTML_title}}></div>
      </div>
    </section>
  )
}

export default TopPhoto