import React from 'react'
import style from './MobFooter.module.scss'
import BQ from '@/assets/img/bq.png'
// import Weibo from "@/assets/img/weibo1.png"
import { useTranslation } from 'react-i18next'
// import Weixin from "@/assets/img/weixin2.png"
// import Linkedin from "@/assets/img/linkedin1.png"

const is_Zh: boolean = localStorage.getItem('store_view') !== 'En'
const MobFooter: React.FC = () => {
	const { t } = useTranslation()
	return (
		<footer className={style.footer_mob}>
			<div className={style.topNav}>
				{/* contact部分 */}
				<div className={style.headContant}>
					<dl className={style.contact}>
						<dt>Contact</dt>
						{/* <dd>
							<div className={style.label}>{t('媒体合作邮箱')}</div>
							<div className={style.value}>pr@ibaiqiu.com</div>
						</dd> */}
						<dd>
							<div className={style.label}>{t('公司电话')}</div>
							<div className={style.value}>+86 21 6013 6999</div>
						</dd>
						<dd>
							<div className={style.label}>{t('公司地址')}</div>
							<div className={style.value}>{t('公司详细地址分行')}</div>
							{/* <div className={style.label}>{t('求职招聘邮箱')}</div>
							<div className={style.value}>hr@ibaiqiu.com</div> */}
						</dd>
						<dd>
							<div className={style.label}>{t('联系邮箱')}</div>
							<div className={style.value}>{t('品牌合作')}{is_Zh?' ':': '}bd@ibaiqiu.com</div>
							<div className={style.value}>{t('媒体合作')}{is_Zh?' ':': '}pr@ibaiqiu.com</div>
							<div className={style.value}>{t('直播合作')}{is_Zh?' ':': '}commercial@romomo.com</div>
						</dd>
					</dl>

					{/* <dl className={style.contact}>
						<dt></dt>
						<dd>
							<div className={style.label}>{t('商务项目合作')}</div>
							<div className={style.value}>bd@ibaiqiu.com</div>
						</dd>
						<dd>
							<div className={style.label}>{t('公司地址')}</div>
							<div className={style.value}>{t('公司详细地址')}-2号楼</div>
						</dd>
						<dd>
							<div className={style.label}>{t('营销业务合作')}</div>
							<div className={style.value}>mkt@ibaiqiu.com</div>
						</dd>
					</dl> */}
				</div>
				{/* 扫码部分 */}
				<div className={style.code}>
					<img src={BQ} alt="" className={style.BQcode} />
					{/* <a href="/#">
						<img src={Weibo} alt="" className={style.platform}/>
					</a>
					<a href="/#">
						<img src={Weixin} alt="" className={style.platform}/>
					</a>
					<a href="/#">
						<img src={Linkedin} alt="" className={style.platform}/>
					</a> */}
				</div>
				{/* 安全部分 */}
				<div className={style.anbei}>
					<a href="https://www.beian.gov.cn/portal/registerSystemInfo?spm=5176.28055625.J_9220772140.52.5f7e154aoW8A78">
						<span>{t('沪公网安备')}</span>
					</a>

					<div className={style.copyright}>
						<span>{t('版权')}</span>
						<a href="https://beian.miit.gov.cn/?spm=5176.28055625.J_9220772140.53.5f7e154aoW8A78">
							<span>{t('备案')} </span>
						</a>
					</div>
					<div className={style.statement}>
						<a href="/Privacy_Policy_and_Legal_Statement">
							<span>{t('隐私政策')}</span>
						</a>
						{/* <a href="/disclaimer">
							<span>{t('免责声明')} </span>
						</a>
						<a href="/Intellectual_Property_Rights">
							<span>{t('知识版权')}</span>
						</a> */}
					</div>
				</div>
			</div>
		</footer>
	)
}

export default MobFooter
