import React, { useState, useEffect, useRef } from 'react'
import { throttle, isInViewPort } from '@/utils/index'
import style from './Map.module.scss'
import back from '@/assets/img/map.png'
const FC: React.FC = () => {
	const [height, setHeight] = useState<number>(0)
	const [open, setOpen] = useState<boolean>(false)
	const imgRef = useRef<any>()
	useEffect(() => {
		const el = document.getElementsByClassName(`${style.Map}`)
		let timer = setTimeout(() => {
			// 立刻执行可能会获取不到卡片的高度，需要延迟一段时间
			if (imgRef.current) {
				setHeight(imgRef.current.clientHeight) // 获取反面内容的高度，设置给正面
			}
			clearTimeout(timer)
		}, 300)
		// 监听滚动条
		const listener = throttle(() => {
			const isinview = isInViewPort(el)
			if (isinview) {
				setOpen(true)
			}
		}, 50)
		document.addEventListener('scroll', listener)
		return () => {
			document.removeEventListener('scroll', listener)
		}
	}, [])
	return (
		<div className={style.Map} style={{ height: height }}>
			<div
				className="trans_box"
				style={{ height: open ? height : '0', opacity: open ? '1' : '0' }}>
				<img
					src="https://res.ibaiqiu.com/BuyQuickly/cms/20230720/165516.jpg"
					alt=""
					ref={imgRef}
					className="img"
				/>
			</div>
		</div>
	)
}

export default FC
