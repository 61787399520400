import React, { useEffect, useState } from 'react';
import SmallBanner from '../SmallBanner/SmallBanner'
import { throttle, isInViewPort } from '@/utils/index';
import './ServiceScope.scss'

interface ServiceScopeList {
  module_data: ServiceScopeData[]
}
interface ServiceScopeData {
  id: string,
  title_zh: string,
  content: string
}
//服务范围
const ServiceScope: React.FC<ServiceScopeList> = (prop) => {
  // 标题数据
  const title={
    title_ch:'服务范围',
    title_eg:'Service scope',
  }
  const [show1,setShow1]=useState<boolean>(false)
  useEffect(() => {
    const  ServiceBlock1=document.getElementsByClassName('ServiceScopeMob')
    const listener =throttle(()=>{
      const isinview1 = isInViewPort(ServiceBlock1) // 是否进入了可视区域了可视区域
      if(isinview1){
        setShow1(true)
      } 
    },50)
    document.addEventListener('scroll', listener)
    return () => {
      document.removeEventListener('scroll', listener)
    }
  })
  return (
    <section className='ServiceScopeMob'>
      {/* Service scope */}
      <div className='topBanner'>
        <SmallBanner {...title}></SmallBanner>
      </div>
      {/* 具体内容 */}
      <div className='rightContent'>
        {
          prop.module_data.map((item,index)=>{
            return(
              <div className='outBox' key={index}>
                <div 
                  className={`detaileContent detaileContent${item.id}`}
                  key={item.id}
                  style={{
                    animationName: show1 ? 'wordTop' : '',
                    animationDelay: `${(index+1)*0.5}s`
                  }}
                >
                  <div className='topTitle'>{item.title_zh}</div>
                  {
                    item.content&&item.content!=='<p><br/></p>'&&
                    <div 
                      className='bottomTitle'
                      dangerouslySetInnerHTML={{__html:item.content}}
                    ></div>
                  }
                </div>
                {/* 边框线 */}
                <div 
                  className='borders'
                  style={{
                    animationName: show1 ? 'wordWidth' : '',
                    animationDelay: `${(index+1)*0.3}s`
                  }}
                ></div>
              </div>
            )
          })
        }
      </div>
    </section>
  )
}
export default ServiceScope