/*
 * @Author: your name
 * @Date: 2021-12-14 14:08:26
 * @LastEditTime: 2022-06-23 14:21:26
 * @LastEditors: ten ten.yan@ibaiqiu.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \一起购买e:\bq\bq-official-web\src\views\PcViews\News\News.tsx
 */
import React, { useEffect, useState, useRef } from 'react'
import Components from '@/components/PcComponents/newsMods';
import { getCms } from '@/api/apis';
import { NewsModuleData } from '@/ts/api';
const News: React.FC = () => {
  const newsList = useRef<Array<NewsModuleData>>([]);// 新闻列表页列表总数据
  const [newsShowList, setnewsShowList] = useState<Array<NewsModuleData>>([]); // 展示数据
  const [total, settotal] = useState(100); // 新闻总数
  const size = useRef<number>(6);// 每页展示数量
  useEffect(() => {
    getNewsList()
    return () => {
      setnewsShowList([])
    }
  }, [])
  /**
   * @description: 从某个下标开始截取指定长度的数组
   * @param {T} list 数组泛型，传入时要约束数组类型
   * @param {number} index 数组截取的位置
   * @param {number} size 数组截取的长度
   * @return {T} 截取后的数组，为list的子集
   */  
  function sliceList<T>(list: T, index: number, size: number) {
    if (Array.isArray(list)) {
      return list.slice(index, size)
    }
    return list
  }
  /**
   * @description: 获取新闻列表
   */  
  function getNewsList() {
    getCms({
      params: { terminal: 'Ws_Pc', content_id: 'news', param_id: 'news' }
    }).then((res: any) => {
      if (res.data?.page_data) {
        newsList.current = res.data.page_data[0].module_data.reverse()
        settotal(newsList.current.length)
        let list = sliceList<Array<NewsModuleData>>(newsList.current, 0, size.current)
        if (Array.isArray(list)) {
          setnewsShowList(list)
        }
      }
    })
  }
  /**
   * @description: 分页器分页切换事件
   * @param {number} index 当前选择的页数，从1开始 
   */
  function changePage(index: number) {
    let list = sliceList<Array<NewsModuleData>>(newsList.current, (index - 1) * size.current, size.current*index)
    if(Array.isArray(list)) {
      setnewsShowList(list)
    }
  }
  const NewsHeader = Components.NewsHeader
  const NewsList = Components.NewsList
  const Pages = Components.Pages
  return (
    <div>
      <NewsHeader />
      <NewsList data={newsShowList} />
      <Pages totle={total} defaultPageSize={size.current} pages={changePage}/>
    </div>
  )
}

export default News
