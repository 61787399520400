import React, { useEffect, useState } from 'react';
import { throttle, isInViewPort } from '@/utils/index';
import './WordContent.scss'


interface wordData{
  content:string
  title?:string
}
//中间介绍栏
const WordContent: React.FC<wordData> = (prop) => {
  const [open,setOpen]=useState<boolean>(false)
  useEffect(() => {
    const pictureInform=document.getElementsByClassName('WordContentMob')
    const listener = throttle(() => {
      const isinview = isInViewPort(pictureInform) // 是否进入了可视区域
      if(isinview) setOpen(isinview)
    }, 50)
    document.addEventListener('scroll', listener)
    return () => {
      document.removeEventListener('scroll', listener)
    }
  }, [])
  return (
    <section className='WordContentMob'>
      <div className={`leftTitle ${open?"transTop":""}`}>
        {prop.title}
      </div>
      <div 
        className={`words ${open?"transTop":""}`}
        dangerouslySetInnerHTML={{__html: prop.content}}
      ></div>
    </section>
  )
}
export default WordContent