import React, { useState, useEffect } from 'react'
import style from './ClosePage.module.scss'
import { Modal } from 'antd'
import commit from '@/assets/img/commit.png'
import { useTranslation } from 'react-i18next'

interface closeItem {
	success: boolean
}
const FC: React.FC<closeItem> = prop => {
	const { t } = useTranslation()

	const [second, setSecond] = useState<number>(3)
	const [isModalVisible, setIsModalVisible] = useState(false)
	const handleCancel = () => {
		setIsModalVisible(false)
	}
	useEffect(() => {
		let temp = 3
		setIsModalVisible(prop.success)
		let timer = setInterval(() => {
			setSecond(temp--)
			if (temp < 0) {
				handleCancel()
				clearInterval(timer)
			}
		}, 1000)
	}, [prop])
	return (
		<Modal
			title={`${second}${t('秒后自动返回')}`}
			visible={isModalVisible}
			onCancel={handleCancel}
			footer={null}
			className={style.closePage}>
			<img src={commit} alt="" className="img" />
			<div className="word">{t('提交成功')}</div>
			<div className="contant">{t('您已提交成功')}</div>
			<div className="button" onClick={handleCancel}>
				{t('返回')}
			</div>
		</Modal>
	)
}

export default FC
