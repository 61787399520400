import JoinHeader from '../joinMods/JoinHeader/JoinHeader';
import Er from './Er/Er';
import Sense from './Sense/Sense';
import Why from './Why/Why';
import Growth from './Growth/Growth';
import Work from './Work/Work';
import Club from './Club/Club';
import Activity from './Activity/Activity';
import Border from '@/components/CommonComponents/mob/Border/Border'
const comp = {
  // 头部
  JoinHeader,
  // 二文化
  Er,
  // 价值观
  Sense,
  // 为什么选择百秋
  Why,
  // 成长晋升
  Growth,
  // 工作环境
  Work,
  // 社团活动
  Club,
  // 员工活动
  Activity,
  Border
}
export default comp