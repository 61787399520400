import JoinHeader from './JoinHeader/JoinHeader';
import JoinForm from './JoinForm/JoinForm';
import JobList from './JobList/JobList';
import Reward from './Reward/Reward';
import Recruit from './Recruit'
import Pages from '@/components/CommonComponents/Pages/Pages'
import Seconded from './Seconded'
import Sense from '../cultureMods/Sense/Sense'
import JoinMethods from './JoinMethods/JoinMethods'
const comp = {
  // 头部
  JoinHeader,
  // 加入百秋-搜索
  JoinForm,
  // 加入百秋-招聘列表
  JobList,
  // 分页
  Pages,
  // 人才推荐奖励机制
  Reward,
  Recruit,
  Seconded,
  //价值观
  Sense,
  //加入方式
  JoinMethods
}

export default comp