import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Collapse } from 'antd'
import './Leader.scss'
// @ts-ignore
const is_Zh: boolean = localStorage.getItem('store_view') !== 'En'
// 领导展示组件
const { Panel } = Collapse
interface PanelHead {
	title_zh: string
	sub_title: string
	isopen: boolean
	imgKey: number
}
interface PanelCont {
	describe?: string
}
// 自定义折叠面板头部
const PanelHeader: React.FC<PanelHead> = props => {
	return (
		<div className="panel_header">
			<div className="time">{props.title_zh}</div>
			<div
				className="word"
				style={{ color: props.isopen ? '#F38400' : '#999999' }}>
				{props.sub_title}
			</div>
		</div>
	)
}

// 自定义折叠面板内容
const PanelContent: React.FC<PanelCont> = props => {
	return (
		<div className="panel_cont">
			<div className="cont_img">{props.describe}</div>
		</div>
	)
}
interface LeaderData {
	title_zh: string
	sub_title: string
	describe: string
	pc_image_url: string
}
interface LeaderList {
	module_data: LeaderData[]
}
//语录
const Leader: React.FC<LeaderList> = prop => {
	const { t } = useTranslation()
	const [picKeys, setKeys] = useState<number>(0)
	// 打开的折叠面板
	const [openlist, setopenlist] = useState<string[]>([])
	let preserve: number
	function keepImg(key: any) {
		if (key) {
			preserve = Number(key)
			setKeys(preserve)
		}
	}
	// 切换面板的回调
	function callback(key: any) {
		if (key === undefined) {
			key = ''
		}
		setopenlist(key)
		keepImg(key)
	}
	return (
		<section className="LeaderPC">
			<div className="block">
				<div className="left">
					<div className="introduce">
						<div className={`${is_Zh ? 'one' : 'one_eg'} `}>
							{t('集团化发展')}
						</div>
						{/* <div className={`${is_Zh ? 'two' : 'two_eg'}`}>{t("语录")}
            </div> */}
					</div>
					{/* 左边折叠框 */}
					<Collapse
						defaultActiveKey={[0]}
						onChange={callback}
						bordered={false}
						accordion={true}
						className="panel_box">
						{prop.module_data.map((item, index) => {
							return (
								<Panel
									header={
										<PanelHeader
											imgKey={index}
											title_zh={item.title_zh}
											sub_title={item.sub_title}
											isopen={openlist.includes(`${index}`)}
										/>
									}
									className="panel_item"
									key={index}
									showArrow={false}>
									<PanelContent describe={item.describe} />
								</Panel>
							)
						})}
					</Collapse>
				</div>
				{/* 右边图片 */}
				<div className="right">
					{/* <div className='triangle'>
          </div> */}
					{prop.module_data[picKeys].pc_image_url && (
						<img
							src={prop.module_data[picKeys].pc_image_url}
							alt=""
							className="leaderImg"
						/>
					)}
				</div>
			</div>
		</section>
	)
}
export default Leader
