import React from 'react';

import style from './Border.module.scss'




const FC: React.FC= () => {
  return (
    <section className={style.border}>
      
    </section>
  )
}

export default FC