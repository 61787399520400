import React,{ useState, useEffect, CSSProperties } from 'react';
import { throttle, isInViewPort } from '@/utils/index';
import style from './CardFadeIn.module.scss'

// 上下 淡入淡出

interface CardProps  {
  fadeinClassName: string // 盒子类名
  defalutFadein?: boolean // 是否默认打开。
  inviewTime?: number // 超出底部多少px时开启动画，默认为200
  style?: CSSProperties // 盒子的样式
  bodyStyle?: CSSProperties // 内容的样式
  transitionStyle?: CSSProperties // 动画的样式
}

const Card: React.FC<CardProps> = (props) => {
  const [fadein, setfadein] = useState(props.defalutFadein||false)
  useEffect(()=>{
    const El = document.getElementsByClassName(`${props.fadeinClassName}`)
    const initisinview = isInViewPort(El, props.inviewTime||200)
    if(initisinview) setfadein(true) // 是否在首屏显示

    // 监听滚动事件，throttle节流函数
    const listener = throttle(() => {
      const isinview = isInViewPort(El, props.inviewTime||200) // 是否进入了可视区域
      setfadein(isinview)
    }, 50)
    document.addEventListener('scroll', listener)
    return () => {
      document.removeEventListener('scroll', listener)
    }
  },[props.inviewTime, props.fadeinClassName])
  return (
    <div className={`${style.bq_card_fadein} ${props.fadeinClassName}`} style={{...props.style}}>
      <div className="fadein_body" style={{...props.bodyStyle}}>
        <div
          className="fadein_transition"
          style={{
            ...props.transitionStyle,
            animationName: fadein ? 'fadein_key' : 'fadeout_key'
          }}
        >
          {
            props.children
          }
        </div>
      </div>
    </div>
  )
}

export default Card