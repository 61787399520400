import { useState, useEffect } from 'react'
import PubSub from 'pubsub-js'
import { subResize } from '@/utils/index'

// device返回值为pc或mobile，用来区分当前是pc端还是移动端
const useDevice = () => {
	const [device, setDevice] = useState('pc')
	useEffect(() => {
		const subResizeToken = subResize(device => setDevice(device))
		return () => {
			PubSub.unsubscribe(subResizeToken)
		}
	}, [])
	return device
}

export default useDevice
