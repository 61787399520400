import React, { useState, useEffect } from 'react'
import { throttle, isInViewPort } from '@/utils/index'
import { Collapse } from 'antd'
import objectFitImages from 'object-fit-images'
import SmallBanner from '../SmallBanner/SmallBanner'
import './Goodness.scss'
const { Panel } = Collapse

interface CollapseItem {
	content: string
	title_zh: string
	sub_title: string
	mob_image_url: string
	pc_image_url: string
	id: string
}
interface CollapseData {
	module_data: CollapseItem[]
	module_param?: {
		sub_title: string
		title_zh: string
	}
}
interface PanelHead {
	id: string
	title_zh: string
	isopen: boolean
	inView: boolean
	index: number
	sub_title: string
	content: string
}
interface PanelCont {
	pc_image_url: string
	content: string
}
// 自定义折叠面板头部
const PanelHeader: React.FC<PanelHead> = props => {
	return (
		<div
			className="panel_header"
			style={{
				animationName: props.inView ? 'panelTop' : '',
				animationDelay: `${(props.index + 1) * 0.2}s`,
				cursor: 'auto'
			}}>
			<div className="panel_title">
				<div className="num">{'0' + props.id}</div>
				<div className="panel_zh">{props.title_zh}</div>
			</div>
			{props.sub_title && <div className="panel_en">{props.sub_title}</div>}
		</div>
	)
}
// 自定义折叠面板内容
const PanelContent: React.FC<PanelCont> = props => {
	useEffect(() => {
		// 解决ie不兼容css: objec-fit属性
		const img = document.querySelectorAll('.cont_img')
		objectFitImages(img)
	})
	return (
		<div className="panel_cont">
			{props.pc_image_url && (
				<img src={props.pc_image_url} className="cont_img" alt="" />
			)}
			<div
				className="cont"
				dangerouslySetInnerHTML={{ __html: props.content }}></div>
		</div>
	)
}
//行业案例
const Goodness: React.FC<CollapseData> = prop => {
	const [inView, setInView] = useState<boolean>(false)
	//标题数据
	const title = {
		title_ch: prop?.module_param?.title_zh || '服务优势',
		title_eg: prop?.module_param?.sub_title || 'Service Advantages'
	}

	useEffect(() => {
		const el = document.getElementsByClassName('GoodnessPC')
		// 监听滚动条
		const listener = throttle(() => {
			// 如果没进入过可视区域不加载动画
			if (!inView) {
				// 是否进入了可视区域
				const isinview = isInViewPort(el)
				// 记录已经进入可视区域
				setInView(isinview)
			}
		}, 50)
		document.addEventListener('scroll', listener)
		return () => {
			document.removeEventListener('scroll', listener)
		}
	}, [inView])

	// 打开的折叠面板
	const [openlist, setopenlist] = useState<string[] | string>('')
	// 切换面板的回调
	function callback(key: any) {
		if (key === undefined) {
			key = ''
		}
		setopenlist(key)
	}
	return (
		<section className="GoodnessPC">
			<div className="title">
				<SmallBanner {...title}></SmallBanner>
			</div>
			<div className="borders"></div>
			<Collapse
				defaultActiveKey={[]}
				onChange={callback}
				bordered={false}
				accordion={true}>
				{prop.module_data.map((item, index) => {
					return (
						<Panel
							showArrow={false}
							header={
								<PanelHeader
									title_zh={item.title_zh}
									content={item.content}
									id={item.id}
									isopen={openlist.includes(`${index}`)}
									inView={inView}
									index={index}
									sub_title={item.sub_title}
								/>
							}
							className="panel_item"
							key={index}
							style={{
								cursor: 'auto'
							}}
							collapsible="disabled">
							<PanelContent
								content={item.content}
								pc_image_url={item.pc_image_url}
							/>
						</Panel>
					)
				})}
			</Collapse>
		</section>
	)
}
export default Goodness
