import React, { useEffect, useState } from 'react'
import Components from '@/components/MobComponents/homeMods'
import { NewsModuleData } from '@/ts/api'
import { getCms } from '@/api/apis'

interface comItem {
	module_id: string
	module_data: []
	module_param: []
	module_remark: string
	module_title: string
	parent_id: string
	sort: number
}
const Home: React.FC = () => {
	const [data, setData] = useState<Array<comItem>>([])
	//新闻的数据
	const [newData, setNewData] = useState<NewsModuleData[]>()
	useEffect(() => {
		// getlangList({}).then((res: any) => {
		//   console.log(res);
		// })
		getCms({
			params: { terminal: 'Ws_Mob', content_id: 'index', param_id: 'index' }
		}).then((res: any) => {
			if (res.data?.page_data) {
				setData(res.data.page_data)
			}
		})
		getCms({
			params: { terminal: 'Ws_Pc', content_id: 'news', param_id: 'news' }
		}).then((res: any) => {
			if (res.data?.page_data) {
				const newsList = res.data.page_data[0].module_data.reverse()
				const arr = newsList.slice(0, 3)
				setNewData(arr)
			}
		})
		return () => {
			setData([])
			setNewData([])
		}
	}, [])
	return (
		<>
			{data.map((item, index) => {
				// @ts-ignore
				const Com: any = Components[item['module_id']]
				return Com && item['module_id'] === 'NewsModule' ? (
					<Com key={index} {...item} newData={newData} />
				) : (
					<Com key={index} {...item} />
				)
			})}
			{/* <ul>
        <li style={{fontFamily: 'FZCQJW',fontSize: '30px',wordSpacing: '10px'}}>FZCQJW中国智慧</li>
        <li style={{fontFamily: 'HanSansHWK',fontSize: '30px'}}>HanSansHWK中国</li>
        <li style={{fontFamily: 'HanSerifSC',fontSize: '30px'}}>HanSerifSC中国</li>
        <li style={{fontFamily: 'sans-serif',fontSize: '30px'}}>sans-serif</li>
      </ul> */}
		</>
	)
}
export default Home
