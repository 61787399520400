import React, { useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { throttle, isInViewPort } from '@/utils/index';
import CenterPicture from '../CenterPicture/CenterPicture'
import WordContent from '../WordContent/WordContent'
import BottomPicture from '../BottomPicture/BottomPicture'
import comp from '../index'
import './Introduce.scss'
interface caseData {
  module_data: caseItem[]
  module_param:{
    sub_title:string
    title_zh:string
  }
}
interface caseItem {
  layout: timeItem[]
  content: string
  HTML_subtitle:string
  HTML_title:string
  title?:string
}
interface timeItem {
  id: string
  mob_image_url:string
  pc_image_url:string
}
interface titleData{
  HTML_subtitle:string
  HTML_title:string
}
//品牌介绍
const Introduce: React.FC<caseData> = (props) => {
  const [open, setOpen] = useSpring(() => ({
    height: '0',
    opacity: '0',
    config: { duration: 700, transitionProperty: 'all' }
  }))
  useEffect(() => {
    const pictureInform = document.getElementsByClassName('introduce')
    const listener = throttle(() => {
      const isinview = isInViewPort(pictureInform) // 是否进入了可视区域
      setOpen.start({
        height: isinview ? '170px' : '0',
        opacity: isinview ? '1' : '0'
      })
    }, 50)
    document.addEventListener('scroll', listener)
    return () => {
      document.removeEventListener('scroll', listener)
    }
  }, [setOpen])
  //品牌介绍
  const Title  = (prop:titleData[]) => {
    return (
      <animated.div className='introduce' style={open}>
        <div
          className='leftInform'
          dangerouslySetInnerHTML={{ __html: prop[0].HTML_title}}>
        </div>
        <div
          className='rightInform'
          dangerouslySetInnerHTML={{ __html: prop[0].HTML_subtitle }}>
        </div>
      </animated.div>
    )
  }
  return (
    <div>
      <comp.CaseLight {...props.module_param} />
      {/* <section className='IntroducePC'>
        <div className='pictureInform' >
          <Title {...props.module_data}></Title>
        </div>
      </section> */}
      <CenterPicture {...props.module_data[0].layout}></CenterPicture>
      <WordContent {...props.module_data[0]} ></WordContent>
      <BottomPicture {...props.module_data[0].layout}></BottomPicture>
    </div>
  )
}

export default Introduce