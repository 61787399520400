import React,{useState,useEffect} from 'react';
import { RightOutlined,LeftOutlined } from '@ant-design/icons';
import './PageBq.scss'

interface PageBqData{
  timePage:number[]
  changeHander: (num:number) => void
  active:number
}
//分页
const PageBq: React.FC<PageBqData> = (prop) => {
  const [clickTime,setClickTime]=useState<number>(0)
  // 左边按钮
  const [showLeft, setshowLeft] = useState<boolean>(true)
  // 右边按钮
  const [showRight, setshowRight] = useState<boolean>(true)
  useEffect(()=>{
    let active=Number(prop.active)
    setClickTime(active)
  },[prop.active])
  // 后退页码
  const back = () => {
    if(prop.active!==prop.timePage[0]){
      setshowRight(true)
      let index = prop.timePage.findIndex(item => item === prop.active)
      if(index === 0) {
        prop.changeHander(prop.timePage[prop.timePage.length-1])
      } else {
        prop.changeHander(prop.timePage[index-1])
      }
    }else{
      setshowLeft(false)
    }
  }
  // 前进页码
  const go = () => {
    if(prop.active!==prop.timePage[prop.timePage.length-1]){
      setshowLeft(true)
      let index = prop.timePage.findIndex(item => item === prop.active)
      if(index === prop.timePage.length-1) {
        prop.changeHander(prop.timePage[0])
      } else {
        prop.changeHander(prop.timePage[index+1])
      }
    }else{
      setshowRight(false)
    }
  }
  const changePage=(item:number)=>{
    //左边和右边到尽头消失
    if(item===prop.timePage[prop.timePage.length-1]){
      setshowRight(false)
      setshowLeft(true)
    }else if(item===prop.timePage[0]){
      setshowLeft(false)
      setshowRight(true)
    }else{
      setshowRight(true)
      setshowLeft(true)
    }
    prop.changeHander(item)
  }
  return (
    <section className='PageBqPC'>
      <div className='page'>
        {/* 向左 */}
        {
          showLeft&&
          <span className='left' onClick={() => back()}>
            <LeftOutlined 
              style={{fontSize: '17px',color:'#FFFFFF'}}
            />
          </span>
        }
        {/* 详细页码 */}
        <ul className='pageItem'>
          {
            prop.timePage.map((item,index)=>{
              return(
                <li 
                  className='pageOne'
                  key={index}
                  style={{color:clickTime===item?'#F38400':''}}
                  onClick={()=>changePage(item)}
                >{item}</li>
              )
            })
          }
        </ul>
        {/* 向右 */}
        {
          showRight&&
          <span className='right' onClick={() => go()}>
            <RightOutlined 
              style={{fontSize: '17px',color:'#FFFFFF'}}
            />
          </span>
        }
      </div>
    </section>
  )
}
export default PageBq