import React from 'react'
import Components from '@/components/MobComponents/newsDetail';

interface comItem {
  module_id: string
}

const data = [
  {
    url: 'https://moose-ows-res.ibaiqiu.com/moose/cms/20211210/162059.jpg',
    width: '60%',
  },
  {
    url: 'https://moose-ows-res.ibaiqiu.com/moose/cms/20210927/164606.jpg',
    width: '40%',
  },
  {
    url: 'https://moose-ows-res.ibaiqiu.com/moose/cms/20211118/120922.jpg',
    width: '40%',
  },
]
const cmsData: comItem[] = [
  {
    module_id: 'Border',
  },
  {
    module_id: 'Text',
  },
  {
    module_id: 'OneImage',
  },
  {
    module_id: 'Border',
  },
  {
    module_id: 'Text',
  },
  {
    module_id: 'Border',
  },
  {
    module_id: 'TwoImage',
  },
  {
    module_id: 'OneImage',
  },
  {
    module_id: 'Text',
  },
  {
    module_id: 'RecommendNews'
  }
]

const News: React.FC = () => {
  const NewsHeader = Components.NewsHeader
  return (
    <>
      <NewsHeader />
      {
        cmsData.map((item, index) => {
          const Com: any = Components[item['module_id']]
          return Com && <Com key={index} data={data} />
        })
      }
    </>
  )
}

export default News
