import React, { useEffect, useState } from 'react'
import Components from '@/components/PcComponents/newsDetail'
import { getQuery } from '@/utils/index'
import { getCms } from '@/api/apis'
interface comItem {
	module_id: string
}

const data = [
	{
		url: 'https://moose-ows-res.ibaiqiu.com/moose/cms/20211210/162059.jpg',
		width: '60%'
	},
	{
		url: 'https://moose-ows-res.ibaiqiu.com/moose/cms/20210927/164606.jpg',
		width: '40%'
	},
	{
		url: 'https://moose-ows-res.ibaiqiu.com/moose/cms/20211118/120922.jpg',
		width: '40%'
	}
]
const cmsData: comItem[] = [
	{
		module_id: 'Text'
	},
	{
		module_id: 'OneImage'
	},
	{
		module_id: 'Text'
	},
	{
		module_id: 'TwoImage'
	},
	{
		module_id: 'OneImage'
	},
	{
		module_id: 'Text'
	},
	{
		module_id: 'RecommendNews'
	}
]
interface Query {
	id?: string
}

const News: React.FC = () => {
	const [newsData, setNewsData] = useState<Array<comItem>>([])
	useEffect(() => {
		getNewsDetailData()
	}, [])
	function getNewsDetailData() {
		// 获取页面参数
		let query: Query = getQuery(window.location.search)
		// 请求接口获取数据
		getCms({
			params: { terminal: 'Ws_Pc', content_id: 'news', param_id: query.id }
		}).then((res: any) => {
			if (res.data?.page_data) {
				// eslint-disable-next-line no-console
				console.log(res)
			}
		})
	}
	const NewsHeader = Components.NewsHeader
	return (
		<div style={{ height: '100%' }}>
			<NewsHeader />
			{cmsData.map((item, index) => {
				const Com: any = Components[item['module_id']]
				return Com && <Com key={index} data={data} />
			})}
		</div>
	)
}

export default News
