import React, { useEffect, useState } from 'react'
import Components from '@/components/PcComponents/joinMods'
import { useTranslation } from 'react-i18next'

import { getCms } from '@/api/apis'
interface JobData {
	title_zh: string // 岗位名称
	Job_requirements: string // 岗位要求
	Job_responsibilities: string // 岗位职责
}

interface RecruitData {
	title_zh: string // 招聘类型中文名
	children: JobData[]
}

interface RewardData {
	title_zh: string
	content: string
	layout?: [
		{
			sub_title: string
			title_zh: string
		}
	]
}
const Join: React.FC = () => {
	const JoinHeader = Components.JoinHeader
	const Recruit = Components.Recruit
	const Reward = Components.Reward
	const Seconded = Components.Seconded
	const Sense = Components.Sense
	const JoinMethods = Components.JoinMethods
	// const Sense=Components.Sense
	const [recruitData, setRecruitData] = useState<Array<RecruitData>>([])
	const [rewardMechanism, setRewardMechanism] = useState<Array<RewardData>>([])
	const { t } = useTranslation()
	const list = [
		{
			zh: '激情勤奋',
			cn: 'Passion & Diligence'
		},
		{
			zh: '诚信务实',
			cn: 'Honesty & Pragmatism'
		},
		{
			zh: '简单专注',
			cn: 'Simplicity & Concentration'
		},
		{
			zh: '共创共赢',
			cn: 'Win-Win Cooperation'
		},
		{
			zh: '客户第一',
			cn: 'Customer First'
		}
	]
	const idea = [
		{
			zh: '有激情',
			cn: 'Passion'
		},
		{
			zh: '能担当',
			cn: 'Responsibility'
		},
		{
			zh: '简单务实',
			cn: 'Simplicity'
		},
		{
			zh: '多元化',
			cn: 'Diversification'
		}
	]
	useEffect(() => {
		getPageData()
		return () => {
			setRecruitData([])
			setRewardMechanism([])
		}
	}, [])
	function getPageData() {
		getCms({
			params: { terminal: 'Ws_Pc', content_id: 'job', param_id: 'job' }
		}).then(res => {
			if (res.data.page_data) {
				// @ts-ignore
				res.data.page_data.forEach((item, index) => {
					if (item.module_id === 'Recruit') {
						setRecruitData(item.module_data)
					} else if (item.module_id === 'RewardMechanism') {
						setRewardMechanism(item.module_data)
					}
				})
			}
		})
	}
	return (
		<div>
			<JoinHeader />
			{/* <JoinForm /> */}
			{/* <JobList /> */}
			{/* <Pages totle={100} defaultPageSize={10} style={{ marginBottom: '0' }} /> */}
			<Sense title={t('用人理念')} content={idea}></Sense>
			{/* <Sense title='价值观' content={list}></Sense> */}
			{/* <Recruit module_data={recruitData} /> */}
			<JoinMethods />
			<Reward module_data={rewardMechanism} />
			<Seconded />
		</div>
	)
}

export default Join
