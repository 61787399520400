import React, { useEffect, useState } from 'react'
import { throttle, isInViewPort } from '@/utils/index'
import style from './Why.module.scss'
// @ts-ignore
const is_Zh: boolean = localStorage.getItem('store_view') !== 'En'
const FC: React.FC = () => {
	const [open, setOpen] = useState<boolean>(false)

	useEffect(() => {
		const aboutbqEl = document.getElementsByClassName(`${style.why}`)

		const listener = throttle(() => {
			const isinview = isInViewPort(aboutbqEl, 300) // 是否进入了可视区域
			setOpen(isinview)
		}, 50)
		document.addEventListener('scroll', listener)
		return () => {
			document.removeEventListener('scroll', listener)
		}
	}, [])

	return (
		<section className={style.mobMhy}>
			<div className="title">
				{is_Zh && (
					<p className={`${open ? 'active' : ''} zh`}>为什么选择百秋</p>
				)}
				<p className={`cn ${open ? 'active' : ''}  ${is_Zh ? '' : 'en'}`}>
					Why Should You Choose Buy Quickly?
				</p>
			</div>
			{!is_Zh && (
				<div className="wrap">
					<div className={`en ${open ? 'active_left' : ''} left`}>
						<div className="left_title">Our hiring philosophy</div>
						<div className="left_content" style={{ fontSize: '14px' }}>
							<p>Passionate</p>
							<p>Responsible</p>
							<p>Simple and Pragmatic</p>
							<p>Diversified</p>
						</div>
					</div>
					<div className={`en ${open ? 'active_right' : ''} right`}>
						<div className="right_title" style={{ width: '84%' }}>
							Our basic management principles
						</div>
						<div className="right_content" style={{ fontSize: '14px' }}>
							<p>Our culture and system are highly unified</p>
							<p>
								We put the interests of strivers first and refuse egalitarianism
							</p>
							<p>
								Attitude depends on selection and ability relies on training
							</p>
							<p>We recruit various talents and give them full play</p>
						</div>
					</div>
				</div>
			)}
			{is_Zh && (
				<div className="wrap">
					<div className={`${open ? 'active_left' : ''} left`}>
						<span className="left_title">我们的用人理念</span>
						<div className="left_content">
							<p>有激情</p>
							<p>能担当</p>
							<p>简单务实</p>
							<p>多元化</p>
						</div>
					</div>
					<div className={`${open ? 'active_right' : ''} right`}>
						<span className="right_title">我们的基本管理原则</span>
						<div className="right_content">
							<p>文化与制度高度统一</p>
							<p>以奋斗者为本，不搞平均主义</p>
							<p>态度重选择，能力重培养</p>
							<p>广纳英才，充分融合</p>
						</div>
					</div>
				</div>
			)}
		</section>
	)
}

export default FC
