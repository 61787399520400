import React, { useState, useRef,useEffect } from 'react';
import style from './Input.module.scss';
import { Input } from 'antd';

interface InputProps {
  label_zh?: string
  label_cn?: string
  anmi?:string
}
// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
const FC:React.FC<InputProps> = (props) => {
  const [show, setShow] = useState<boolean>(true)
  const ref = useRef<any>()
  
  const FocusEvent = () => {
    setShow(false)
  }
  const blurEvent = (e:any) => {
    if(e.target.value==='') {
      setShow(true)
    }
  }
  const autoFocus = () => {
    if(show && ref.current) {
      ref.current.focus()
    }
  }
  useEffect(()=>{
    if(props.anmi==='true'){
      setShow(false)
    }

  },[props.anmi])
  return (
    <div className={style.bq_input}>
      <label
        className={`${show ? '' : 'bq_label_active'} bq_label`}
        onClick={() => autoFocus()}
      >
        {
          is_Zh && <span className={`${show ? '' : 'zh_active'} zh`}>{props.label_zh}</span>
        }
        <span 
          className={`${show ? '' : 'cn_active'} cn`}
          style={{color: !is_Zh ? '#fff':'#666'}}>{props.label_cn}</span>
      </label>
      <Input {...props} ref={ref} onFocus={() => FocusEvent()} onBlur={(e) => blurEvent(e)}  />
    </div>
  )
}

export default FC
