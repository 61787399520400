/* eslint-disable no-useless-escape */
import PubSub from 'pubsub-js'
import md5 from 'js-md5'

// 发布resize事件
export const publishResize = () => {
  const terminals = { // 每个接口中有个参数terminal
    pc: 'Ws_Pc', // pc端
    mobile: 'Ws_Mob' // 移动端
  }

  const mobMaxWidth = 1080
  let device = window.innerWidth > mobMaxWidth ? 'pc' : 'mobile'
  let terminal = localStorage.getItem('terminal')
  const hasTem = terminal && terminal===terminals[device]
  if(!hasTem) localStorage.setItem('terminal', terminals[device])
  
  const resizeListener = event => {
    if (window.innerWidth > mobMaxWidth && device === 'mobile') {
      device = 'pc'
      PubSub.publish('resize', device)
    }
    if (window.innerWidth < mobMaxWidth && device === 'pc') {
      device = 'mobile'
      PubSub.publish('resize', device)
    }
    localStorage.setItem('terminal', terminals[device])
  }
  PubSub.publish('resize', device)
  window.addEventListener('resize', resizeListener)
  return resizeListener
}

// 订阅resize事件
export const subResize = (cb) => {
  const subResizeToken = PubSub.subscribe('resize', (_, device) => {
    cb(device)
  })

  return subResizeToken
}


// 格式化url中查询字符串
export const formatQs = params => {
  if (!typeof(params) === 'object') {
    throw new Error('params is expected an object');
  }
  let qs = '?';
  Object.keys(params).forEach(key => {
    if (params[key]) {
      if (Object.prototype.toString.call(params[key]) === '[object, object]') {
        Object.keys(params[key]).forEach(paramKey => {
          qs += `${key}[][${paramKey}=${params[key][paramKey]}]&`;
        });
      } else {
        qs += `${key}=${params[key]}&`;
      }
    }
  });
  qs = qs.substring(0, qs.length - 1);
  return qs;
};

// 获取邮箱验证规则
export const getEmailReg = () => {
  return /^[A-Za-z0-9]+@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/
}

// 获取手机号验证规则
export const getPhoneReg = () => {
  return /^[1][3,4,5,6,7,8,9][0-9]{9}$/
}

// 手机所在区号对应的验证规则
export const allPhoneReg = [
  {
    tip: '86',
    reg: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
  },
  {
    tip: '852',
    reg: /^(\+?852\-?)?[569]\d{3}\-?\d{4}$/,
  },
]
// 转换日期
export const changeData=(data)=>{
  const month=[
    {
      num:'01',
      en:'January'
    },
    {
      num:'02',
      en:'February'
    },
    {
      num:'03',
      en:'March'
    },
    {
      num:'04',
      en:'April'
    },
    {
      num:'05',
      en:'May'
    },
    {
      num:'06',
      en:'June'
    },
    {
      num:'07',
      en:'July'
    },
    {
      num:'08',
      en:'August'
    },
    {
      num:'09',
      en:'September'
    },
    {
      num:'10',
      en:'October'
    },
    {
      num:'11',
      en:'November'
    },
    {
      num:'12',
      en:'December'
    }
  ]
  if(data){
    const arrData=data.split('-')
    const english = month.filter((item)=>{
      return item.num===arrData[1]
    })
    let result=arrData[2]+','+english[0].en+' '+arrData[0]
    return  result
  }
}

// 参数签名
export const sign = (obj) => {
  const SIGN_KEY = 'ows';
  if (!Object.keys(obj).length) return md5(md5('') + SIGN_KEY).toLocaleUpperCase();
  const str = Object.keys(sortObjKey(obj)).reduce((s, key) => s += `${key}${obj[key]}`, '');
  const requestSign = md5(md5(str) + SIGN_KEY).toLocaleUpperCase();
  return requestSign;
}

// 对象key按字典升序排序
export const sortObjKey = (obj) => {
  if (Object.prototype.toString.call(obj) !== '[object Object]') {
  // if (typeof obj !== 'object') {
    throw new Error('params is expected an object');
  }
  const sortedKeys = Object.keys(obj).sort();
  const sortedObj = {};
  sortedKeys.forEach(key => sortedObj[key] = obj[key]);
  return sortedObj;
}

// 获取query
export const getQuery = str => {
  const query = {};
  const queryStr = str.split('?')[1]
  if (!queryStr) return query
  const queryArr = str.split('?')[1].split('&').filter(item => item);
  queryArr.forEach(q => query[q.split('=')[0]] = q.split('=')[1]);
  return query;
}

//获取url中参数
export const getId = str => {
  const path = str.split('/')
  return path[2];
}
// 禁止body滑动，解决移动端滑动穿透问题。
export const lockBody = () => {
  const { body } = document;
  const scrollTop =
    document.body.scrollTop || document.documentElement.scrollTop;
  body.style.position = 'fixed';
  body.style.width = '100%';
  body.style.top = `-${scrollTop}px`;
  body.style.overflow = 'scroll';
};

// 解除 禁止滑动。
export const resetBody = () => {
  const { body } = document;
  const { top } = body.style;
  body.style.position = '';
  body.style.width = '';
  body.style.top = '';
  document.body.scrollTop = -parseInt(top, 10);
  document.documentElement.scrollTop = -parseInt(top, 10);
};

// 防抖
export function debounce (fn, wait, immediate = false) {
  let timer; let startTimeStamp = 0;
  let context, args;

  const run = (timerInterval) => {
    timer = setTimeout(() => {
      const now = (new Date()).getTime();
      const interval = now - startTimeStamp;
      if (interval < timerInterval) { // the timer start time has been reset，so the interval is less than timerInterval
        // console.log('debounce reset',timerInterval-interval);
        startTimeStamp = now;
        run(wait - interval); // reset timer for left time
      } else {
        if (!immediate) {
          fn.apply(context, args);
        }
        clearTimeout(timer);
        timer = null;
      }
    }, timerInterval);
  };

  return function () {
    context = this;
    args = arguments;
    const now = (new Date()).getTime();
    startTimeStamp = now; // set timer start time

    if (!timer) {
      // console.log('debounce set', wait);
      if (immediate) {
        fn.apply(context, args);
      }
      run(wait); // last timer alreay executed, set a new timer
    }
  };
}


// 节流
export function throttle (fn,  gapTime = 1000, immediate = false) {
  let _lastTime = null
  let timer = null
  return function() {
    let _nowTime = +new Date()
    if(timer) clearTimeout(timer)
    if (_nowTime - _lastTime > gapTime || !_lastTime) {
      fn.apply(this, arguments)
      _lastTime = _nowTime
    } else {
      if(immediate) {
        timer = setTimeout(() => {
          fn.apply(this, arguments)
        }, gapTime);
      }
    }
  }
}

// 获取节点是否在可视区域内
export function isInViewPort (elArr, h=100) {
  const el = elArr[0]
  // viewPortHeight 兼容所有浏览器写法
  // 可见区域高度
  const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight 
  // 相对于父元素高度
  const offsetTop = el?.offsetTop || 0
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop ||document.body.scrollTop || 0;
  const top = offsetTop - scrollTop
  // console.log(el)
  // console.log(viewPortHeight, 'viewPortHeight');
  // console.log(el?.offsetTop, 'offsetTop');
  // console.log(scrollTop, 'scrollTop');
  // console.log(top, 'top');
  // console.log(top <= viewPortHeight - h, 'top');

   // 这里有个+100是为了提前加载+ 100
  return top <= viewPortHeight - h
}
export function getViewStore (){
  const view=localStorage.getItem('store_view')|| 'Zh'
  return view
}