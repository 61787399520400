import React from 'react';
import SubNavHeader from '@/components/CommonComponents/pc/SubNavHeader/SubNavHeader'
import { useTranslation } from 'react-i18next';


const NewsHeader: React.FC = () => {
	const { t } = useTranslation()
  const tabs = [
    {
      name: t('百秋文化'),
      href: '/club',
      actives: ['/club']
    },
    {
      name: t('加入百秋'),
      href: '/job',
      actives: ['/job']
    },
  ]
  return (
    <SubNavHeader tabs={tabs} title_zh='加入' title_cn='Join Us' />
  )
}

export default NewsHeader