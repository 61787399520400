import React,{useState,useEffect} from 'react';
import { throttle, isInViewPort } from '@/utils/index';
import { Collapse } from 'antd';
import objectFitImages from 'object-fit-images';
import JiaPng from '@/assets/img/jia.png';
import JianPng from '@/assets/img/jian.png';
import SmallBanner from '../SmallBanner/SmallBanner';
import './BusinessCase.scss'
const { Panel } = Collapse;

interface CollapseData{
  module_data:{
    mob_image_url:string,
    sub_title:string
    title_zh:string
    content:string
  }[]
  module_param:{
    sub_title:string
    title_zh:string
  }
}
interface PanelHead {
  title_zh: string
  sub_title: string
  isopen: boolean
}
interface PanelCont {
  content: string
  mob_image_url?:any
}
// 自定义折叠面板头部
const PanelHeader:React.FC<PanelHead> = (props) => {
  return (
    <div className="panel_header">
      <div className='boxItem'>
        <div className="panel_zh" style={{color: props.isopen ? '#FFFFFF' : '#999999'}}>
          { props.title_zh }
        </div>
        <div className="panel_en" style={{color: props.isopen ? '#FFFFFF' : '#999999'}}>
          { props.sub_title }
        </div>
      </div>
      <img src={props.isopen ? JianPng : JiaPng} className="icon" alt="" />
    </div>
  )
}
// 自定义折叠面板内容
const PanelContent:React.FC<PanelCont> = (props) => {
  useEffect(() => {
    // 解决ie不兼容css: objec-fit属性
    const img = document.querySelectorAll('.cont_img')
    objectFitImages(img)
  })
  return (
    <div className="panel_cont" id='business_mob'>
      {
        props.mob_image_url&&
        <img src={props.mob_image_url} className="cont_img" alt="" />
      }
      <div className="cont" dangerouslySetInnerHTML={{__html:props.content}}></div>
    </div>
  )
}
//行业案例
const BusinessCase: React.FC<CollapseData> = (prop) => {
  const [inView, setInView] = useState<boolean>(false)
  useEffect(() => {
    const el = document.getElementsByClassName('BusinessCaseMob')
    // 监听滚动条
    const listener = throttle(() => {
      // 如果没进入过可视区域不加载动画
      if(!inView) {
        // 是否进入了可视区域
        const isinview = isInViewPort(el)
        // 记录已经进入可视区域
        setInView(isinview)
      }
    }, 50)
    document.addEventListener('scroll', listener)
    return () => {
      document.removeEventListener('scroll', listener)
    }
  }, [inView])

  // 打开的折叠面板
  const [openlist, setopenlist] = useState<string[] | string>('')
  // 切换面板的回调
  function callback(key:any) {
    if(key===undefined){
      key=''
    }
    if(key) {
      setopenlist(key)
      const el = document.getElementById(`businessMob${key}`)
      const top=el?.offsetTop
      const el2:any = document.querySelectorAll(`#business_mob`)
      let initHeight
      let height
      if(top){
        if(el2.length!==0){
          height=window.getComputedStyle(el2[el2.length-1]).height
          height=height.slice(0,-2)
        }
        if(openlist[0]&& key > Number(openlist[0])){
          initHeight=top-(Number(height)||0)-30
        }else{
          initHeight=top-54
        }
      }
      if (el) {
        window.scrollTo({
          top: initHeight,
          behavior: "smooth"
        });
      }
    } else {
      setopenlist(key)
    }
  }
  return (
    <section className='BusinessCaseMob'>
      <div className='title'>
        <SmallBanner title_ch={prop.module_param.title_zh} title_eg={prop.module_param.sub_title}></SmallBanner>
      </div>
      <div className='borders'></div>
      <Collapse
        defaultActiveKey={[]}
        onChange={callback}
        bordered={false}
        accordion={true}
      >
        {
          prop.module_data.map((item, index) => {
            return (
              <Panel
                showArrow={false}
                header={
                  <PanelHeader
                    title_zh={item.title_zh}
                    sub_title={item.sub_title}
                    isopen={openlist.includes(`${index}`)}
                  />
                }
                className='panel_item'
                key={index}
                style={{
                  animationName: inView ? 'panelWidth' : '',
                  animationDelay: `${(index+1)*0.1}s`
                }}
                id={`businessMob${index}`}
              >
                <PanelContent content={item.content} mob_image_url={item.mob_image_url} />
              </Panel>
            )
          })
        }
      </Collapse>
    </section>
  )
}
export default BusinessCase