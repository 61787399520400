import React,{useEffect,useState} from 'react'
import Components from '@/components/PcComponents/serverMods/ServiceDetaile';
import { getCms } from '@/api/apis';

// 数智解决方案
const DigitalIntelligenceSolutions: React.FC = () => {
  interface comTtem {
    module_id: string,
    module_data: []
    module_param: []
    module_remark:string
    module_title:string
    parent_id:string
    sort:number
  }
  const [data,setData]=useState<Array<comTtem>>([])
  useEffect(() => {
    getCms({
      params: { terminal: 'Ws_Pc', content_id: 'digital_intelligence', param_id: 'digital_intelligence' }
    }).then((res: any) => {
    if(res){
      setData(res.data.page_data)
    }
  })
    return () => {
      setData([])
    }
  },[])
  return (
    <div>
      {
        data.map((item, index) => {
          const ServiceDetaile: any = Components[item['module_id']]
          return ServiceDetaile && <ServiceDetaile key={index} {...item} />
        })
      }
    </div>
  )
}

export default DigitalIntelligenceSolutions
