import React, { useEffect, useState }  from 'react'
import { getCms } from '@/api/apis';
import Components from '@/components/MobComponents/cultureMods';
import style from './Culture.module.scss';
interface PageData {
  module_id: string
  module_data?: any
  sort:number
}
const Join: React.FC = () => {
  const JoinHeader = Components.JoinHeader
  const [caseList, setCaseList] = useState<PageData[]>([]);
  const list = [
    {
      zh: '激情勤奋',
      cn: 'PASSION & DILIGENCE'
    },
    {
      zh: '诚信务实',
      cn: 'HONESTY & PRAGMATISM'
    },
    {
      zh: '简单专注',
      cn: 'SIMPLICITY & CONCENTRATION'
    },
    {
      zh: '共创共赢',
      cn: 'WIN-WIN COOPERATION'
    },
    {
      zh: '客户第一',
      cn: 'CUSTOMER FIRST'
    },
  ]
  useEffect(() => {
    getCms({
      params: { terminal: 'Ws_Mob', content_id: 'club', param_id: 'club' }
    }).then((res) => {
      if (res) {
        res.data.page_data.map((item:PageData)=>{
          if(item.sort===3){
            item.module_id='Growth'
          }else if(item.sort===6){
            item.module_id='Club'
          }else if(item.sort===7){
            item.module_id='Activity'
          }
          return(
            item.module_id
          )
        })
        setCaseList(res.data.page_data)
      }
    })
  }, []);
  return (
    <div className={style.culture}>
      <JoinHeader />
      {
        caseList.map((item, index) => {
          const Com: any = Components[item['module_id']]
          return Com && item['module_id']==='Sense'?
          <Com key={index} data={item} title={'价值观'} list={list}/>:
          <Com key={index} data={item}/>
        })
      }
    </div>
  )
}

export default Join
