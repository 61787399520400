import React, { useEffect,useState,useRef } from 'react';
// import objectFitImages from 'object-fit-images';
import { throttle, isInViewPort } from '@/utils/index';
import { useSpring, animated } from 'react-spring';
import './Brands.scss'
import { url } from 'inspector';
interface BannerData{
  module_data:{
    children:{
      sid:number
    }[]
  }[]
  module_param:TitlesType
}
interface TitlesType{
  sub_title:string
  title_zh:string
  all_logo:string
  hover_logo:string
  mkt_title:string
}
// @ts-ignore
const is_Zh:boolean = localStorage.getItem('store_view') !== 'En'
// 合作品牌
const Fc: React.FC <BannerData>= (prop:BannerData) => {
  const myRef=useRef<any>()
  // 数据
  const obj = {
    module_id: 'Brands',
    module_param: {
      title_zh: '主标题（中文）',
      sub_title: '副标题（英文）',
      img: 'src',
      hover_img: 'src',
      mkt_title: '排名不分先后，按首字母顺序排列',
    },
    module_data: [
      {
        id: 1,
        title: '珠宝',
        brands:[
          {
            number:1,
            active:false
          },{
            number:2,
            active:false
          },{
            number:3,
            active:true
          },{
            number:4,
            active:false
          },{
            number:5,
            active:true
          },{
            number:6,
            active:false
          },{
            number:7,
            active:true
          },{
            number:8,
            active:true
          },{
            number:9,
            active:false
          },{
            number:10,
            active:true
          },{
            number:11,
            active:true
          },{
            number:12,
            active:false
          },{
            number:13,
            active:true
          },{
            number:14,
            active:false
          },{
            number:15,
            active:false
          },{
            number:16,
            active:true
          },{
            number:17,
            active:true
          },{
            number:18,
            active:false
          },{
            number:19,
            active:true
          },{
            number:20,
            active:true
          },{
            number:21,
            active:true
          },{
            number:22,
            active:false
          },{
            number:23,
            active:true
          },{
            number:24,
            active:true
          },{
            number:25,
            active:true
          },{
            number:26,
            active:false
          },{
            number:27,
            active:true
          },{
            number:28,
            active:false
          },{
            number:29,
            active:true
          },{
            number:30,
            active:true
          },{
            number:31,
            active:false
          },{
            number:32,
            active:true
          },{
            number:33,
            active:true
          },{
            number:34,
            active:false
          },{
            number:35,
            active:false
          },{
            number:36,
            active:true
          },{
            number:37,
            active:true
          },{
            number:38,
            active:false
          },{
            number:39,
            active:true
          },{
            number:40,
            active:false
          },{
            number:41,
            active:true
          },{
            number:42,
            active:false
          },{
            number:43,
            active:true
          },{
            number:44,
            active:false
          },{
            number:45,
            active:false
          },{
            number:46,
            active:true
          },{
            number:47,
            active:false
          },{
            number:48,
            active:true
          },{
            number:49,
            active:true
          },{
            number:50,
            active:false
          },{
            number:51,
            active:true
          },{
            number:52,
            active:true
          },{
            number:53,
            active:false
          },{
            number:54,
            active:false
          },{
            number:55,
            active:true
          },{
            number:56,
            active:false
          },{
            number:57,
            active:true
          },{
            number:58,
            active:false
          },{
            number:59,
            active:true
          },{
            number:60,
            active:true
          },{
            number:61,
            active:false
          },{
            number:62,
            active:true
          },{
            number:63,
            active:false
          },{
            number:64,
            active:true
          },{
            number:65,
            active:true
          },{
            number:66,
            active:false
          },{
            number:67,
            active:true
          },{
            number:68,
            active:true
          },{
            number:69,
            active:true
          },{
            number:70,
            active:false
          },{
            number:71,
            active:true
          },{
            number:72,
            active:false
          }
        ]
      },
      {
        id: 2,
        title: '男装',
        brands: [
          {
            number: 1,
            title: 'byfar',
            active: false
          },
          {
            number: 2,
            title: 'theory',
            active: true
          },
        ]
      },
      {
        id: 3,
        title: '女装',
        brands: [
          {
            number: 1,
            title: 'byfar',
            active: false
          },
          {
            number: 2,
            title: 'theory',
            active: true
          },
        ]
      },
      {
        id: 4,
        title: '箱包',
        brands: [
          {
            number: 1,
            title: 'byfar',
            active: false
          },
          {
            number: 2,
            title: 'theory',
            active: true
          },
        ]
      },
      {
        id: 5,
        title: '鞋履',
        brands: [
          {
            number: 1,
            title: 'byfar',
            active: false
          },
          {
            number: 2,
            title: 'theory',
            active: true
          },
        ]
      },
      {
        id: 6,
        title: '腕表眼镜',
        brands: [
          {
            number: 1,
            title: 'byfar',
            active: false
          },
          {
            number: 2,
            title: 'theory',
            active: true
          },
        ]
      },
      {
        id: 7,
        title: '运动户外',
        brands: [
          {
            number: 1,
            title: 'byfar',
            active: false
          },
          {
            number: 2,
            title: 'theory',
            active: true
          },
        ]
      },
      {
        id: 8,
        title: '内衣',
        brands: [
          {
            number: 1,
            title: 'byfar',
            active: false
          },
          {
            number: 2,
            title: 'theory',
            active: true
          },
        ]
      },
      {
        id: 9,
        title: '美妆',
        brands: [
          {
            number: 1,
            title: 'byfar',
            active: false
          },
          {
            number: 2,
            title: 'theory',
            active: true
          },
        ]
      },
    ]
  }
  // 随机生成二维码
  const checkCodeofRandom = (value:number) => {
    let arr=[];
    const length = value ? value : 4;
    for(let i = 0; i < length; i++) {
      //每次生成一个0 - 61 之间的 number 作为随机获取验证码的下标
      let p = Math.floor(Math.random()*prop.module_data[0].children.length);
      arr.push(p)
    }
    return arr;
  }
  // 点击分类标题字体是否高亮
  // const [light,setLight]=useState<number>()
  const [classifica,setClassifica]=useState<number>(0)
  const [allBrand,setAllBrand]=useState<boolean>(false)
  const [randomNum,setRandomNum]=useState<number[]>([])//产生的随机数的下标
  // 分类标题点击事件
  // const showBrand=(index:number)=>{
  //   setLight(index)
  //   setClassifica(index-1)
  //   obj.module_data.forEach((item)=>{
  //     if(item.id===index){
  //       setAllBrand(true)
  //     }
  //   })
  // }
  const[title,setTitle]= useSpring(() => ({
    opacity : '0', config : {duration:400}
  }))
  useEffect(()=>{
    setRandomNum(checkCodeofRandom(Math.ceil(prop.module_data[0].children.length/2)))
    let root = document.body
    root.style.setProperty('--background-image', `url(${prop.module_param.all_logo})`)
    root.style.setProperty('--background-hover', `url(${prop.module_param.hover_logo})`)
    const brand=document.getElementsByClassName('BrandsPc')
    const listener = throttle(() => {
      const isinview = isInViewPort(brand) // 是否进入了可视区域
      setTitle.start({opacity: isinview ? '1':'0'})
    }, 50)
    document.addEventListener('scroll', listener)
    return ()=>{
      document.removeEventListener('scroll', listener)
    }
  },[setTitle])
  return (
    <section className='BrandsPc'>
      {/* 标题 */}
      <h2 className='h2'>
        {
          is_Zh&&
          <animated.span className="brandTitle" style={title}>
            {prop.module_param.title_zh}
          </animated.span>
        }
        
        <animated.span className="brand" style={title}>{prop.module_param.sub_title}</animated.span>
        <animated.span className="hint" style={title}>{prop.module_param.mkt_title}</animated.span>
      </h2>
      {/* 精灵图 */}
      <ul className='picture'>
        {
          prop.module_data[0].children.map((item, index)=>{
            return(
              <li key={item.sid}>
                <div
                  className={`photos ${(allBrand&&'showNo')||''} ${(randomNum.includes(index)&&((allBrand&&'showLight')||'active'))||'unactive'}`}
                  style={{
                    animationDelay: `${Math.random()*5}s`
                  }}
                > 
                  <div
                    ref={myRef}
                    className='img'
                    style={{
                      backgroundPosition: `-${index*200}px`,
                      width: '200px',
                      height: '100px'
                    }}
                  >
                  </div>
                </div>
              </li>
            )
          })
        }
      </ul>
      {/* 分类标题 */}
      {/* <ul className='titles'>
        <div className='bottom'>
          {
            obj.module_data.map((item,index)=>{
              return(
                <li key={item.id} className='brand'>
                  <div className={`name ${light===item.id?'changeWhite':''}`} onClick={()=>{showBrand(item.id)}} >{item.title}</div>
                </li>
              )
            })
          }
        </div>
      </ul> */}
    </section>
  )
}

export default Fc