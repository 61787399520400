/*
 * @Author: your name
 * @Date: 2021-12-15 15:57:57
 * @LastEditTime: 2022-01-25 11:46:43
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \bq-official-web\src\components\PcComponents\CaseMods\CaseDetaile\TopPicture\TopPicture.tsx
 */
import React from 'react';
import './TopPicture.scss'

interface caseData{
  module_data:caseItem[]
}
interface caseItem{
  pc_url:string
}
//图片
const TopPicture: React.FC<caseData> = (prop) => {
  
  return (
    <section className='TopPicturePC'>
      {
        prop.module_data[0].pc_url&&
        <img src={prop.module_data[0].pc_url} alt="" />
      }
    </section>
  )
}

export default TopPicture