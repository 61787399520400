import { useTranslation } from 'react-i18next'
import React, { useState, useEffect, useRef } from 'react'
import Page from '@/components/CommonComponents/Pages/Pages'
import './CaseList.scss'
import { changeData } from '@/utils/index'
import { setpPgeViewCount, setpPgeViewList } from '@/api/apis'
// @ts-ignore
const is_Zh: boolean = localStorage.getItem('store_view') !== 'En'
// interface BrandInfor {
//   children: BrandItem[]
// }
interface BrandItem {
	id: string
	img: string
	title: string
	Sort_value: string
	pc_image_url: string
	creation_time: string
	Industry: {}
	service_type: any
	visits?: number
	ws_url: string
}
interface brandData {
	module_data: BrandItem[]
}
interface industryName {
	id: number
	Industry?: string
	service_type?: string
}
interface rules {
	Industry?: string
	service_type?: string
}
interface sortData {
	id: number
	slectName: string
}
//品牌卡片组件
const BrandCart = (prop: BrandItem) => {
	// 品牌标签参数
	const [label, setLabel] = useState([])
	// 处理时间
	const [time, setTime] = useState<string>('')
	useEffect(() => {
		// 筛选出label渲染的标签
		setLabel(Object.values(prop.service_type))
		setTime(prop.creation_time.split(' ')[0])
	}, [])
	//案例详情跳转
	const detaile = (id: string, ws_url: string) => {
		if (id) {
			setpPgeViewCount({
				data: { id: Number(id) }
			}).then(() => {
				window.location.href = ws_url + '?id=' + id
			})
		}
	}
	return (
		<div className="cardBox">
			<div className="brandTop">
				<div
					onClick={() => {
						detaile(prop.id, prop.ws_url)
					}}
					className="detaile">
					{prop.pc_image_url && (
						<img src={prop.pc_image_url} alt="" className="brandPhoto" />
					)}
					{prop.img && <img src={prop.img} alt="" className="brandName" />}
					<div className="brandDetaile">{prop.title}</div>
				</div>
			</div>
			<div className="cardBottom">
				<div className="labels">
					{label.map((item, index) => {
						return (
							<div key={item + index} className="labelItem">
								{item}
							</div>
						)
					})}
				</div>
				{/* <div className='data'>{changeData(time)}</div> */}
			</div>
		</div>
	)
}
//案例
const CaseList: React.FC<brandData> = prop => {
	const { t } = useTranslation()
	// 返回的数据
	const obj = {
		module_data1: [
			{
				id: 1,
				Industry: t('全部')
			},
			{
				id: 2,
				Industry: t('珠宝')
			},
			{
				id: 3,
				Industry: t('男装')
			},
			{
				id: 4,
				Industry: t('女装')
			},
			{
				id: 5,
				Industry: t('箱包')
			},
			{
				id: 6,
				Industry: t('腕表眼镜')
			},
			{
				id: 7,
				Industry: t('运动户外')
			},
			{
				id: 8,
				Industry: t('内衣')
			},
			{
				id: 9,
				Industry: t('美妆')
			},
			{
				id: 10,
				Industry: t('鞋履')
			}
		],
		module_data2: [
			{
				id: 1,
				service_type: t('全部')
			},
			{
				id: 2,
				service_type: t('战略咨询')
			},
			{
				id: 3,
				service_type: t('店铺运营')
			},
			{
				id: 4,
				service_type: t('全域数据')
			},
			{
				id: 5,
				service_type: t('全域营销')
			},
			{
				id: 6,
				service_type: t('创意视觉')
			},
			{
				id: 7,
				service_type: t('美妆运营')
			},
			{
				id: 8,
				service_type: t('内容直播')
			},
			// {
			//   id: 9,
			//   service_type: t('品牌孵化')
			// },
			{
				id: 10,
				service_type: t('IT解决方案')
			},
			{
				id: 11,
				service_type: t('仓储物流')
			}
		],
		module_data3: [
			{
				id: 1,
				slectName: t('发布时间')
			},
			{
				id: 2,
				slectName: t('访问量')
			},
			{
				id: 3,
				slectName: t('精选推荐')
			}
		]
	}
	// 页面渲染的数据
	const [pageData, setPageData] = useState<BrandItem[]>(prop.module_data)
	//选中所在行业
	const [selectNav, setSelectNav] = useState<Number>(1)
	//选中服务类型
	const [selectType, setSelectType] = useState<Number>(1)
	//选中排序规则
	const [selectSort, setSelectSort] = useState<Number>(0)
	const [selectArr, setSelectArr] = useState<BrandItem[]>([])
	const caseRef = useRef({ Industry: 'All', service_type: 'All' })
	useEffect(() => {
		// 添加访问量排序字段
		prop.module_data.map(item => {
			return (item = Object.assign(item, { visits: 0 }))
		})
		setPageData(prop.module_data.slice(0, 6))
	}, [prop.module_data])
	// 分页
	const pages = (num: number) => {
		if (selectArr.length !== 0 && selectArr.length >= 6) {
			setPageData(selectArr.slice((num - 1) * 6, num * 6))
		} else {
			if (prop.module_data.length >= 6) {
				setPageData(prop.module_data.slice((num - 1) * 6, num * 6))
			}
		}
	}
	// 所在行业,服务类型选择
	const selectIndustry = (item: industryName) => {
		if (item.Industry) {
			caseRef.current.Industry = item.Industry
			setSelectNav(item.id)
		} else if (item.service_type) {
			caseRef.current.service_type = item.service_type
			setSelectType(item.id)
		}
		const arr = filterCase(prop.module_data, caseRef.current)
		setSelectArr(arr)
		setPageData(arr.slice(0, 6))
	}
	// 排序选择
	const sortRank = async (item: sortData) => {
		setSelectSort(item.id)
		let sortName = ''
		if (item.id === 1) {
			sortName = 'creation_time'
		} else if (item.id === 2) {
			sortName = 'visits'
			await visits()
		} else {
			sortName = 'Sort_value'
		}
		setPageData(orderBy(sortName, 'desc', prop.module_data))
	}
	//访问量查询
	const visits = () => {
		return new Promise(resolve => {
			setpPgeViewList({}).then(res => {
				Object.keys(res.data).forEach(key => {
					prop.module_data.map(item => {
						if (item.id === String(key)) {
							return (item.visits = res.data[key])
						} else {
							return 0
						}
					})
				})
				resolve(true)
			})
		})
	}
	/**
	 * @description 组合查询筛选
	 * @param {BrandItem[]} origin 筛选的数据
	 * @param {rules} rules 查询规则
	 * @returns {BrandItem[]} 筛选后返回的数据
	 */
	function filterCase(origin: BrandItem[], rules: rules) {
		let res = []
		res = origin.filter(item => {
			let filterRes = true //是否符合筛选条件
			for (const key in rules) {
				// 判断筛选的规则是否在原数据存在
				if (Object.hasOwnProperty.call(item, key)) {
					const value = item[key] //数据的行业或者服务类型 array
					const rule = rules[key] //筛选的行业或者服务类型 string
					if (rule === 'All' || rule === '全部') {
						continue
					}
					filterRes = Object.values(value).includes(rule)
					if (!filterRes) {
						break
					}
				} else {
					filterRes = false
				}
			}
			if (filterRes) {
				return item
			} else {
				return null
			}
		})
		return res
	}
	/**
	 * @description 排序
	 * @param {string} field 需要排序的字段
	 * @param {string} rule 排序的规则
	 * @param {BrandItem[]} data 需要排序的数据
	 * @returns {BrandItem[]} 筛选后的数据
	 */
	function orderBy(field: string, rule: string, data: BrandItem[]) {
		let res = [...data]
		res = res.sort((a, b) => {
			if (rule === 'desc') {
				return b[field] - a[field]
			} else {
				return a[field] - b[field]
			}
		})
		return res
	}
	return (
		<section className="caseListPC">
			<div className="caseBox">
				<h2 className="h2">
					{is_Zh && <span className="caseTitle">案例</span>}
					<span className="casebq">Showcase</span>
				</h2>
				{/* 二级菜单 */}
				<div className="industry">{t('所在行业')}</div>
				<ul className="allIndustry">
					{obj.module_data1.map(item => {
						return (
							<li
								key={item.id}
								className={`Industry ${
									selectNav === item.id ? 'selectIndus' : ''
								}`}
								onClick={() => {
									selectIndustry(item)
								}}>
								{item.Industry}
							</li>
						)
					})}
				</ul>
				<div className="industry">{t('服务类型')}</div>
				<ul className="allIndustry">
					{obj.module_data2.map(item => {
						return (
							<li
								key={item.id}
								className={`Industry ${
									selectType === item.id ? 'selectIndus' : ''
								}`}
								onClick={() => {
									selectIndustry(item)
								}}>
								{item.service_type}
							</li>
						)
					})}
				</ul>
				{/* 排序 */}
				{/* <ul className='selectNum'>
          <li className='selectChoose'>{t("排序")}</li>
          {
            obj.module_data3.map((item, index) => {
              return (
                <li
                  key={item.id}
                  className={`select ${selectSort === index + 1 && 'selectSort'}`}
                  onClick={() => { sortRank(item) }}
                >{item.slectName}
                </li>
              )
            })
          }
        </ul> */}
			</div>
			<div className="border"></div>
			{/* 品牌 */}
			<div className="brand">
				{pageData.map((item, index) => {
					return <BrandCart key={item.id} {...item}></BrandCart>
				})}
			</div>
			{pageData.length !== 0 && (
				<Page
					totle={
						selectArr.length === 0 ? prop.module_data.length : selectArr.length
					}
					defaultPageSize={6}
					pages={pages}></Page>
			)}
		</section>
	)
}

export default CaseList
