import React from 'react';
import CardFadein from '@/components/CommonComponents/pc/CardFadeIn/CardFadeIn';
import style from './Text.module.scss'

interface TextProps {
  date: string,
  title: string,
  content: string
}

interface TextChildrenProps {
  date: string,
  title: string,
  content: string
}


const TextChildren: React.FC<TextChildrenProps> = (props) => {
  return (
    <div className='htmlContent'>
      <div className='date' dangerouslySetInnerHTML={{ __html: props.date }}></div>
      <div className='title' dangerouslySetInnerHTML={{ __html: props.title }}></div>
      <div className='content' dangerouslySetInnerHTML={{ __html: props.content }}></div>
    </div>
  )
}

const FC: React.FC<TextProps> = (props) => {
  // 随机classm名
  const textClassName = 'text_' + Math.random().toString().slice(2)
  return (
    <section className={`${style.text} ${textClassName}`} >
      <CardFadein fadeinClassName={textClassName}>
        <TextChildren {...props} />
      </CardFadein>
    </section>
  )
}
FC.defaultProps = {
  date: `<p>2021/02/04</p>`,
  title: `<p>上海百秋携Christian Louboutin</p>
  <p>天猫官方旗舰店盛大开业！</p>`,
  content: `<p>2021年1月27日，Christian Louboutin官方旗舰店已正式登陆天猫，</p>
  <p>与百秋携手开启全新旅程。</p>
  <p>Christian Louboutin于1991年在巴黎的让-雅克卢梭大街开设首家专门店，并创立同名品牌。</p>
  <p>设计师Christian Louboutin先生集艺术家与工匠于一身，打造夺目出众的鞋履设计，</p>
  <p>以其标识性“红底鞋履”享誉于世。</p>`
}

export default FC