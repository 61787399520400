import React,{ useState, useEffect, useRef } from 'react';
import { RightOutlined,LeftOutlined } from '@ant-design/icons';
import style from './PageBq.module.scss'

interface PageBqData{
  timePage:number[]
  changeHander:(num: number) => void
  openTime:number // 当前选中的年份
}
//分页
const PageBq: React.FC<PageBqData> = (prop) => {
  const ref = useRef<any>()
  const [translateX, settranslateX] = useState<number>(0) // 平移距离 
  // 左边按钮
  const [showLeft, setshowLeft] = useState<boolean>(true)
  // 右边按钮
  const [showRight, setshowRight] = useState<boolean>(true)
  
  useEffect(()=>{
    if(prop.timePage.length>0) {
      changePage(prop.openTime)
    }
  }, [prop.openTime,prop.timePage.length])
  const changePage=(time:number)=>{
    const index = prop.timePage.findIndex(item => item === time)
    // 左按钮显示|隐藏
    if(index === 0) {
      setshowLeft(false)
    } else {
      setshowLeft(true)
    }
    // 右按钮显示|隐藏
    if(index === prop.timePage.length-1) {
      setshowRight(false)
    } else {
      setshowRight(true)
    }
    let width = 60
    if(ref.current){
      width= ref.current.clientWidth
    }
    if(index<3) { //点击前三个，为0
      settranslateX(0)
    } else if(index>prop.timePage.length-3) { //点击最后三个
      settranslateX(-(prop.timePage.length-5)*width)
    } else { //点击中间
      settranslateX(-(index-2)*width)
    }
    prop.changeHander(time)
  }
  // 后退页码
  function back(){
    const index = prop.timePage.findIndex(item => item === prop.openTime)
    if(index === 0) return;
    changePage(prop.timePage[index-1])
  }
  // 前进页码
  function go(){
    const index = prop.timePage.findIndex(item => item === prop.openTime)
    if(index === prop.timePage.length-1) return;
    changePage(prop.timePage[index+1])
  }
  return (
    <section className={style.PageBqMob}>
      <div className='box'>
          {/* 详细页码 */}
        <div className='pageItem'>
          <div
            className='animationPage'
            style={{transform: `translateX(${translateX}px)`}}
          >
            {
              prop.timePage.map((item,index)=>{
                return(
                  <div 
                    className='pageOne'
                    key={index}
                    ref={ref}
                    style={{
                      color:prop.openTime===item?'#F38400':'',
                    }}
                    onClick={()=>{changePage(item)}}
                  >{item}</div>
                )
              })
            }
          </div>
        </div>
      </div>
      {/* 向左 */}
      {
        showLeft && (
          <div className='left' onClick={back}>
          <LeftOutlined 
            style={{fontSize: '15px',color:'#FFFFFF'}}
          />
        </div>
        )
      }
      {/* 向右 */}
      {
        showRight && (
          <div className='right' onClick={go}>
            <RightOutlined 
              style={{fontSize: '15px',color:'#FFFFFF'}}
            />
          </div>
        )
      }
      
    </section>
  )
}
export default PageBq